
export default function Component(props) {
  const { bgColor = 'bg-grayRedDark', textColor = 'text-gray-400' } = props; // accountInfo = false
  // const accountTxt = ' | 계좌이체정보: 우리은행 | 예금주: 뇌과학데이터연구소 | 계좌번호: 1005-804-034820';
  const companyTxt = `상호: 주식회사 데카르트 | 사업자 등록번호 : 142-88-01183 | 통신판매업신고증 제 2022-서울강남-02633호
  | 서비스명: 데카르트 | 주소 : 서울시 강남구 테헤란로 129 강남n타워 917호 | 대표자: 이제빈 | 고객센터/제휴문의(이메일): help@brain-dr.com
  | 고객센터(전화): 070-8065-7344`;

  return (
    <div className={`${bgColor} py-3`}>
      <p className={`${textColor} text-center`} style={{ fontSize: '10px' }}>
        {companyTxt}
        {/* accountInfo && accountTxt */}
      </p>
    </div>
  );
}
