import styled from 'styled-components';

import { SpaceFlex1 } from '.';

const aratio = 0.7;
const Award = styled.img`
  width: ${() => aratio * 300}px;
  height: ${() => aratio * 160}px;
`;
const tratio = 0.7;
const Trophy = styled.img`
  width: ${() => tratio * 228}px;
  height: ${() => tratio * 173}px;
  z-index: 10;
`;
const Pattern = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 180px;
`;

export default function Screen() {
  return (
    <div style={{ overflow: 'visible' }}>
      <div className={'flex flex-row relative bg-orange self-stretch px-4 rounded-xl py-8 my-4 bggr overflow-hidden '}>
        <Pattern src={'./images/awardPtrnRow.png'} />
        <SpaceFlex1 />
        <Award src={'./images/awardtext.png'} />
        <SpaceFlex1 flex={3} />
        <Trophy src={'./images/trophy.png'} />
        <SpaceFlex1 flex={2} />
      </div>
    </div>
  );
}
