import { useRef } from 'react';
import styled from 'styled-components';

import { MovieLinks } from '../models';
import { spacer1 } from '.';
import { AboluteGrayBG } from './basicStyle';

const R = require('ramda');

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #3af0;
  margin: 5px 0;

`;
const RowImages = styled.div`
  display: flex;
  padding: 3px 0;
  align-items: center;
  background: #f380;
`;
const ImgFlex4 = styled.div`
  display: flex;
  flex: 4;
`;
const ImgTitle = styled.img`
  aspect-ratio: 13 / 19;
  margin-top: 3px;
  object-fit: contain;
`;
const IconsDiv = styled.div`
  display: flex;
  flex: 19;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #a3a0;
`;
const IconButton = styled.img`
  position: relative;
  display: flex;
  flex: 1;
  align-self: stretch;
  margin: 0px 5px;
  border-radius: 20px;
  &:focus { outline: none; }
`;
const IconImg = styled.img`
  flex: 1;
  align-self: stretch;
  aspect-ratio: 1;
  margin: 0px 2px;
  min-width: 30px;
  min-height: 30px;
  object-fit: scale-down;
  `;
// No Link guide
const NoLinkBox = styled.div<{
  active: boolean;
}>`
  display: flex;
  align-items: center;
  height: ${(p) => p.active ? 100 : 0}px;
  width: auto;
  transition: 'all .5s ease';
  background: #CCC;
  overflow: hidden;
  border-radius: 5px;
`;

const container = 'flex flex-col pt-6';
const title = 'text-center font-bold text-2xl text-gray-800';

export function ProgramA({ card, setCard }) {
  const row = !!card && card.substring(0, 2);
  const cardLink = card ? MovieLinks[card] : '';
  const fullLink = `https://www.youtube.com/embed/${cardLink}`;
  const arrRef = R.range(1, 6).map(() => useRef());

  return (
    <div className={`${container} bg-gray-50`}>
      <p className={title} >전영역 맞춤 자극</p>
      {spacer1}
      <p className={'text-center font-bold text-lg text-gray-900'} >각 아이콘을 눌러 두뇌운동 영상을 확인하세요.</p>
      {spacer1}
      {R.range(1, 6).map((n) => (
        <ItemContainer key={n} ref={arrRef[n - 1]}>
          <RowImages>
            <ImgFlex4>
              <ImgTitle src={`/images/program/iconA/a${n}.png`} />
            </ImgFlex4>
            <IconsDiv>
              {R.range(1, 4).map((m) => {
                const name = `a${n}${m}`;
                const match = card === name;
                return (
                  <IconButton key={m} as="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setCard(match ? '' : name);
                      if (!match) {
                        setTimeout(() => {
                          // window.scrollTo({ behavior: 'smooth', top: arrRef[n - 1].current.offsetTop });
                          window.scrollTo(0, arrRef[n - 1].current.offsetTop);
                        }, 500);
                      }
                    }}
                  >
                    <IconImg src={`/images/program/iconA/a${n}${m}.png`}/>
                    <AboluteGrayBG match={match} />
                  </IconButton>
                );
              })}
            </IconsDiv>
          </RowImages>
          {cardLink ? (
            <iframe
              src={fullLink}
              width="auto" height={row === `a${n}` ? '400px' : '0px'} title="Descartes" frameBorder="0" allowFullScreen
              style={{ transition: 'all .5s ease' }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          ) : (
            <NoLinkBox active={row === `a${n}`} >
              <p className="m-auto text-gray-800 font-semibold text-1xl">미리보기 준비중입니다. :) </p>
            </NoLinkBox>
          )}
        </ItemContainer>
      ))}

      <p className="m-10 text-center text-base" >
        {/* {'게임처럼 쉽고 재미있는 '}<br /> */}
        {' 사고력, 언어력, 계산력, 집중력, 기억력운동이'}<br />
        {' 회원님의 성과에 따라 매일 맞춤 설계됩니다'}
      </p>
    </div>

  );
}
