import { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { QstnProvider } from './models';
import {
  AuthDone, Authenticate, Award,
  Faq, Final, Intro, KakaoCancel, KakaoConfirm, KakaoFail,
  ParentsFinal, Playground, Program, ProgramIntro, ProgramPay,
  ProgramRecommend, Question, Question4, Splash,
} from './pages';
// import { tagManagerArgs } from './util';

// TagManager.initialize(tagManagerArgs);

function App() {
  useEffect(() => { }, []);
  return (
    <QstnProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Intro} />
          <Route path="/award" exact component={Award} />
          <Route path="/splash" exact component={Splash} />
          <Route exact path="/question/:page" component={Question} />
          <Route path="/question4" exact component={Question4} />
          <Route exact path="/auth" component={Authenticate} />
          <Route exact path="/auth-done" component={AuthDone} />

          <Route exact path="/program" component={Program} />
          <Route exact path="/program-intro" component={ProgramIntro} />
          {/* <Route exact path="/program-ceo" component={ProgramCeo} /> */}

          <Route exact path="/faq" component={Faq} />

          <Route exact path="/refer/:user" component={ProgramRecommend} />
          <Route exact path="/program-pay" component={ProgramPay} />
          <Route exact path="/program-pay/:option" component={ProgramPay} />
          <Route exact path="/approval" component={KakaoConfirm} />
          <Route exact path="/fail" component={KakaoFail} />
          <Route exact path="/cancel" component={KakaoCancel} />
          <Route exact path="/final" component={Final} />
          <Route exact path="/parents/final" component={ParentsFinal} />

          <Route exact path="/playground" component={Playground} />
          <Route path="*" component={Intro} />

        </Switch>
      </BrowserRouter>
    </QstnProvider>
  );
}

export default App;
