import { CopyToClipboard } from 'react-copy-to-clipboard';

import { CompanyInfo, ContactBar, MainMenu, marginSide, screenContainerBase } from '../components';
import { arrFaq, QstnActionType, useQstnDispatch, useQstnState } from '../models';

const title = 'my-2 font-bold text-3xl';

export default function Screen({ history }) {
  const { faqIdx } = useQstnState();
  const dispatch = useQstnDispatch();

  return (
    <div className={`${screenContainerBase} ${marginSide} bg-gray-50`} >
      <MainMenu history={history} />
      <div className="flex flex-col px-6 py-6 items-center bg-gray-50">
        <p className={title}>자주 묻는 질문</p>
        {arrFaq.map((faq, idx) => {
          const isActive = idx === faqIdx;
          return (
            <button key={faq.question}
              className={`self-stretch items-start justify-start my-2 py-4 rounded-md text-gray-700
              ${isActive ? 'bg-grayD7 ' : 'bg-white'}
              focus:outline-none`}
              onClick={(e) => {
                e.preventDefault();
                console.log(' Cur FAQ ', faqIdx);
                dispatch({
                  type: QstnActionType.SetFaqIdx,
                  payload: idx,
                });
              }}
            >
              <p className={`mx-4 text-base text-left ${isActive ? 'font-bold mb-5' : ''}`} >{faq.question}</p>
              {isActive && (
                <p
                  className="mx-3 text-sm text-justify"
                  style={{ wordBreak: 'keep-all', textIndent: '8px' }}
                >
                  {faq.answer}
                </p>
              )}
            </button>
          );
        })}

        <CopyToClipboard text="https://descartesapp.co.kr/program">
          <button
            className="flex flex-row self-stretch my-4 py-2 bg-white focus:outline-none"
            onClick={(e) => {
              e.preventDefault();
              alert('클립보드에 링크가 복사되었습니다.');
            }}
          >
            <p className="flex flex-1 mx-4 text-left">나의 두뇌 관리 프로그램 복사하기</p>
            <img src="/images/icons/copy.png" alt="COPY" className="w-7 h-7 mx-4" />
          </button>
        </CopyToClipboard>
      </div>

      <ContactBar />

      <CompanyInfo bgColor="bg-white" textColor="text-grayHalf" accountInfo />
    </div>
  );
}
