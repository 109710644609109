import { IReferer, IRefererParam } from './../models';
import { apiAgentV1, showApiError } from './apiAgent';

export const getRecommender = async (params: IRefererParam): Promise<IReferer> => {
  try {
    const resp = await apiAgentV1.get('v1/recommend/user/', { params });
    console.log('api get :: recommend/user ', resp);
    return resp?.data?.data as IReferer;
  } catch (error) {
    if (error.response) {
      // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
      console.log(error.response);
    } else if (error.request) {
      // 요청이 이루어 졌으나 응답을 받지 못했습니다.
      // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
      // Node.js의 http.ClientRequest 인스턴스입니다.
      console.log(error.request);
    } else {
      // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
      console.log('Error', error.message);
    }
    showApiError(error);
    return null;
  }
};
