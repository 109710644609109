export const MovieLinks = {
  a11: 'aCTK6QPWrdY', // RPS 1
  a12: 'RaogBxcCunI', // Line 2
  a13: '2-2r_6rIc4g', // 순서맞추기 3
  // 언어 //
  a21: 'heUb8eRYn3A', // 그림찾기 5 https://youtu.be/heUb8eRYn3A
  a22: 'rwfqiWm9leM', // 단어찾기 6 https://youtu.be/rwfqiWm9leM
  a23: 'DjcGTEod3VA', // 초성게임 ? 단어 쓰기 4 https://youtu.be/DjcGTEod3VA
  // 기억력
  a31: 'uXRokHsycXU', // 같은그림찾기 7 https://youtu.be/uXRokHsycXU
  a32: 'XErLmbT2t1Y', // 위치찾기 9
  a33: '2K6P1vV-gRg', // 영어문장완성 8
  // 계산력
  a41: 'dIXuC0U01Uc', // 숫자만들기 https://youtu.be/dIXuC0U01Uc
  a42: 'TvysqUq7lNk', // 더하기마스터 https://youtu.be/TvysqUq7lNk
  a43: 'Mc8NaaGxEQM', // 사칙연산 https://youtu.be/Mc8NaaGxEQM
  // 집중력
  a51: '9tALEnymu7E', // 다른도형찾기 https://youtu.be/9tALEnymu7E
  a52: 'KtyCMqDAFp8', // Jump Jump https://youtu.be/KtyCMqDAFp8
  a53: 'ELVV_ENGQC0', // 이쪽저쪽 https://youtu.be/ELVV_ENGQC0
  // 바디 스튜디오
  b11: '7iRQNV86oQk', // 필수근력운동 https://youtu.be/7iRQNV86oQk
  b12: 'b-CJUyD75dc', // 5 min yoga https://youtu.be/b-CJUyD75dc
  b13: 'Yl1hT7G0nz8', // 10 min https://youtu.be/Yl1hT7G0nz8
  // Plus Academy
  b21: 'wHqrb21cVHs', // https://youtu.be/wHqrb21cVHs
  b22: '_SZCoz0_-zk', // https://youtu.be/_SZCoz0_-zk
  // Rest
  b31: 'MJZoojqYasc', // today thought https://youtu.be/MJZoojqYasc
  b32: 'N2i1b5MD2t4', // breath https://youtu.be/N2i1b5MD2t4
  b33: 'vjYWm73fB8c', // sleep https://youtu.be/vjYWm73fB8c
  // Brain lab
  b41: 'jvR7F14cl3c', // mind https://youtu.be/jvR7F14cl3c
  b42: 'RKKJ9bkstrk', // health info https://youtu.be/RKKJ9bkstrk
  b43: 'gPAgHKQ322w', // brain type test https://youtu.be/gPAgHKQ322w

};
