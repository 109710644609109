import { List } from 'immutable';
import { createContext, useContext, useReducer } from 'react';

import { postQuest, postQuestV2 } from '../api';
import { arrQstn } from './qstnItem';
import { KakaoPayment, LocalStorage, QstnAct, QstnActionType, QstnAnswerSet, QstnCnx } from './typesModel';
// import { giftIdx } from './qstnSelector';

// const { List } = require('immutable');
// const giftOptions = [Parents.No, Parents.Mom, Parents.Dad];
const initState: QstnCnx = {
  version: '0.0.1',
  name: 'Questionare',
  qstnAnswer: List(),
  totalPageNum: 24,
  userid: '',
  authPhone: '',
  arrQstn,
  faqIdx: -1,
  kakaoPay: { isMobile: true, phone: '' },
};

export const QstnContext = createContext(undefined);
export const QstnDispatchContext = createContext(undefined);

export function useQstnState() {
  const state = useContext(QstnContext);
  if (!state) throw new Error('Question Provider not found');
  return state;
}

async function uploadQuest(phone, data) {
  postQuest({ idPhone: phone, ...data });
}

export function qstnReducer(state: QstnCnx, action: QstnAct) {
  // console.log(' reducer ', action.type, action.payload, state);
  const { qstnAnswer, faqIdx, kakaoPay, authPhone, userid } = state;
  let qIdx = 0;

  switch (action.type) {
  case QstnActionType.Answer:
    qIdx = (action.payload as QstnAnswerSet).qstnIdx;
    if (qIdx === 1) {
      localStorage.removeItem(LocalStorage.QuestID);
    }
    if (qIdx === 10 || qIdx === 21) {
      postQuestV2({
        answers: qstnAnswer.filter((v) => !!v).toArray(),
      });
    }
    return {
      ...state,
      qstnAnswer: qstnAnswer.set(Math.floor(qIdx), action.payload as QstnAnswerSet),
    };
  case QstnActionType.SetAuthPhone:
    console.log(' Auth Phone ', action.payload);
    return { ...state, authPhone: action.payload };
  case QstnActionType.SetUserId:
    return { ...state, userid: action.payload };
  case QstnActionType.UploadFirQuest: // starting kakao pay. payload is phone number
    postQuestV2({
      answers: qstnAnswer.filter((v) => !!v).toArray(),
    });
    if (action.callback) {
      action.callback();
    }
    return state;
  case QstnActionType.SetFaqIdx:
    return { ...state, faqIdx: faqIdx === action.payload ? -1 : action.payload };
  case QstnActionType.SetKakao:
    return { ...state, kakaoPay: { ...kakaoPay, ...(action.payload as KakaoPayment) } };
  default:
    return state;
  }
}

export function useQstnDispatch() {
  const dispatch = useContext(QstnDispatchContext);
  if (!dispatch) throw new Error('Provider not found');
  return dispatch;
}

export function QstnProvider({ children }) {
  const [glbState, dispatch] = useReducer(qstnReducer, initState);

  return (
    <QstnDispatchContext.Provider value={dispatch}>
      <QstnContext.Provider value={glbState}>
        {children}
      </QstnContext.Provider>
    </QstnDispatchContext.Provider>
  );
}
