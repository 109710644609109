import moment from 'moment';
import numeral from 'numeral';

/**
 * Nick name validator
 */
export function checkNickName(nickName: string): string {
  const specialPattern = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9]/gi;
  const len = nickName.length;
  const lengthError = [
    { method: () => specialPattern.test(nickName), msg: '특수 문자를 빼주세요.' },
    { method: () => len < 2, msg: '닉네임은 두글자 이상을 입력해 주세요.' },
    { method: () => len > 8, msg: '닉네임은 8글자 이하로 입력해 주세요.' },
  ];
  const errorCase = lengthError.find((item) => item.method());
  return errorCase ? errorCase.msg : '';
}

/**
 * Password validator
 */
export function checkPasswordFormat(pw: string): string {
  // const allowable = /^(?=[a-zA-Z0-9])(?=[!@#$%^*+=-])/gi;
  const allowable = /^[a-zA-Z0-9!@#$%^*+=-]*$/gi;
  // const special = /[~`!@#$%^&*()-+=]/gi;
  // const incNum = /[0-9]/gi;
  const len = pw.length;
  const checkItems = [
    { method: () => !allowable.test(pw), msg: '한글, 이모티콘 문자는 빼주세요.' },
    // { method: () => !special.test(pw), msg: '특수문자를 포함해 주세요.' },
    // { method: () => !incNum.test(pw), msg: '숫자를 포함해 주세요.' },
    { method: () => len < 6, msg: '6자 이상 입력해 주세요.' },
    { method: () => len > 20, msg: '20자 이하로 입력해 주세요.' },
  ];
  const errorCase = checkItems.find((item) => item.method());
  return errorCase ? errorCase.msg : '';
}

/**
 *
 * @param txt string
 * @returns string with number
 */
export function getNumbers(txt) {
  return txt.replace(/[^0-9]/g, '');
}

/**
 *
 * @param txt phone number
 * @returns formatted phone number [010-1111-2222]
 */
export function formatPhoneNumber(txt, divider = '-') {
  const nmbr = getNumbers(txt);
  let nm = txt;
  const len = nmbr.length;
  nm = len > 3 ? `${nmbr.substring(0, 3)}${divider}${nmbr.substring(3)}` : nm;
  nm = len > 7 ? `${nmbr.substring(0, 3)}${divider}${nmbr.substring(3, 6)}${divider}${nmbr.substring(6)}` : nm;
  nm = len >= 11 ? `${nmbr.substring(0, 3)}${divider}${nmbr.substring(3, 7)}${divider}${nmbr.substring(7)}` : nm;
  return nm;
}

/**
 *
 * @param year in string ex) 03
 * @returns number
 */
export function getAge(year: string) : number {
  const yr = parseInt(year);
  const fullYear = (yr < 25 ? 2000 : 1900) + yr;
  return moment().year() - fullYear;
}

/**
 * @param num in number
 */
export const formatNumber = (num: number) => numeral(num).format('0,0');

/**
 * @param dateStr date string
 */
export const isToday = (dateStr: string): boolean => {
  return moment().format('YYYYMMDD') === moment(dateStr).format('YYYYMMDD');
};

/**
 * @param dateStr date string
 */
export const isYesterday = (dateStr: string): boolean => {
  return moment().subtract(1, 'days').format('YYYYMMDD') === moment(dateStr).format('YYYYMMDD');
};

/**
 * @param pBase string
 * @param pKey string
 */
export const indexLower = (pBase: string, pKey: string): number => {
  return pBase.toLowerCase().indexOf(pKey.toLowerCase());
};

export function getObjectWithKeys(obj, arrKy) {
  const filtered = { };
  arrKy.forEach((ky) => { filtered[ky] = obj[ky]; });
  return filtered;
}

export function getWeekdayString(dtStr: string) {
  return ['일', '월', '화', '수', '목', '금', '토', '일'][moment(dtStr).weekday()];
}

export function getDateTimeStr(dtStr: string) {
  const mobj = moment(dtStr);
  return `${mobj.format('YYYY.MM.DD')} (${getWeekdayString(dtStr)}) ${mobj.format('HH:mm')}`;
}

export function getFullDate(date6num: string, formatStr?: string) {
  const yearStr = date6num.substring(0, 2);
  const yearNum = Number.parseInt(yearStr);
  const fullYear = `${yearNum < 25 ? 20 : 19}${date6num}`;
  return moment(fullYear).format(formatStr || 'YYYY-MM-DD');
}

export const arrWeekDays = ['일', '월', '화', '수', '목', '금', '토'];

export function getDayOfWeek() {
  return arrWeekDays[moment().day()];
}
