import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  CircleStatus, CompanyInfo, ContactBar, MainMenu, marginSide,
  screenContainerBase, spacer1, spacer3, spacer8,
} from '../../components';
import { Parents } from '../../models';

export default function Screen({ history }) {
  // const [phone, setPhone] = useState('');
  const [parent, setParent] = useState(Parents.Dad);
  const subject = parent === Parents.Dad ? '아버지' : '어머니';

  const process = 'text-2xl my-1 text-gray-700';
  const bttnTitle = '설치 링크 복사하기';
  const readPhone = () => {
    setParent(localStorage.getItem(Parents.Key) as Parents || Parents.No);
  };

  useEffect(() => {
    readPhone();
  }, []);

  // const phoneStr = phone.length > 5 ? formatPhoneNumber('0' + phone.substring(3), ' ') : phone;
  const shareData = {
    title: '',
    text: '데카르트 멤버쉽 등록방법 \n1. 데카르트 설치 하기 \n https://braindr.page.link/ELQn \n2. 회원가입 / 로그인 (전화번호 인증)\n3. 두뇌운동 시작!',
    url: 'https://braindr.page.link/ELQn',
  };
  const canShare = false; // !!navigator.share;
  const shareButton = (
    <button
      className={`flex flex-col my-4 self-stretch justify-center items-center 
      rounded-lg py-2 bg-kakao focus:outline-none`}
      onClick={async (e) => {
        e.preventDefault();
        try {
          if (canShare) {
            await navigator.share(shareData);
          } else {
            alert('클립보드에 링크가 복사되었습니다.');
          }
        } catch (err) {
          console.log(' Share Error ', err);
        }
      }}
    >
      <p className="text-2xl my-4">{bttnTitle}</p>
    </button>
  );

  return (
    <div className={`${screenContainerBase} ${marginSide}`} style={{ wordBreak: 'keep-all' }}>
      {spacer3}
      <MainMenu history={history} disableBackButton />
      {spacer1}
      <CircleStatus idx={2} parent={Parents.Dad} />

      {spacer8}

      <div className="flex flex-col p-10">
        <p className={process}>데카르트 설치링크를 복사하고 </p>
        <p className={process}>{subject}께 보내주세요</p>
      </div>

      {spacer8}

      <div className={'flex flex-col'}>
        {canShare ? shareButton : (
          <CopyToClipboard text={shareData.text}>
            {shareButton}
          </CopyToClipboard>
        )}
      </div>

      <ContactBar />
      <CompanyInfo bgColor="" textColor="text-grayHalf" accountInfo />
    </div>
  );
}
