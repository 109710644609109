import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import firebase from '../app/firebase';

function Screen(props) {
  useEffect(() => {
    setTimeout(() => {
      props.history.push('/question/0.5');
      // props.history.push('/playground');
    }, 1500);
  });

  const ref = firebase.firestore().collection('SubsPhone');
  console.log(' Ref ', ref);

  return (
    <div className={'flex flex-col h-full items-center justify-center  bg-yellowBrain'}>
      <p className="text-gray-900 text-2xl animate-fade-in">{'뇌를 깨워 볼까요?'}</p>
    </div>
  );
}

export default withRouter(Screen);
