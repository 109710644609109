import { arrQstn, subjectKey } from './qstnItem';
import { MdlQstn } from './typesModel';

export const giftOptions = arrQstn[1].options.map((opt) => opt.qStr);

export const giftIdx = (qstn) => {
  const filtered = qstn.filter((itm) => itm?.qstnIdx === 0.7);
  if (filtered.size > 0) {
    const ans = filtered.get(0);
    return giftOptions.findIndex((itm) => itm === ans.answer);
  }
  return 0;
};

export const pickFromOpt = (qstn, pArr) => {
  return pArr[giftIdx(qstn)];
};

export const applyGift = (qstn, qstr: string, options: string[]) => {
  const hasKey = qstr.indexOf(subjectKey) >= 0;
  if (!hasKey) { return qstr; }
  return qstr.replace(subjectKey, options[giftIdx(qstn)]);
};

export const getNextPage = (qstn, curQstnObj: MdlQstn) => {
  const { nextPage } = curQstnObj;
  if (typeof nextPage === 'string') {
    return nextPage;
  }
  return nextPage[giftIdx(qstn)];
};
