export default function QuestionBox(props) {
  const { content, onClick, selected } = props;
  const bgSty = selected ? 'bg-yellowHalf' : 'bg-grayF8';
  return (
    <button
      className={`text-gray-800 text-xl focus:outline-none flex justify-center my-2  p-3 px-5 rounded-md ${bgSty}`}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {content}
    </button>
  );
}
