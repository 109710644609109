import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { MainMenu, marginSide, spacer1, spacer6, spacer15 } from '../components';
import background from '../images/introAppIcons.png'; // 716 / 403

const imgR = 0.6;
const subTitleSty = 'text-gray-900 text-xl text-center';

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
`;
const WrapInner = styled.div`
  display: flex;
  flex: 1;
  max-width: 500px;
  align-self: stretch;
`;

function Screen(props) {
  useEffect(() => {
    // window.scrollTo({ top: 410, behavior: 'smooth' });
    // window.scrollTo(0, 410);
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className={'flex flex-col w-full overflow-x-auto items-center py-1'}
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'repeat-x',
        backgroundSize: `${716 * imgR}px ${403 * imgR}px`,
        backgroundPosition: 'center top',
      }}
    >
      <Wrap className={marginSide}>
        <WrapInner>
          <MainMenu history={props.history} disableBackButton />
        </WrapInner>
      </Wrap>
      {spacer15}
      {spacer15}
      <img src={'/images/logo.png'}
        className="absolute "
        style={{ top: '202px', width: '40px', height: '40px' }}
      />
      <div className="top-72">

        {/* <div className="flex px-16 max-h-36 bg-red-200" style={{ height: '200px' }} /> */}

        <p className="m-2 text-gray-900 text-4xl text-center font-bold tracking-widest">{'데 카 르 트'}</p>
        {spacer6}
        <p className={subTitleSty}>{'나에게, 부모님에게'}</p>
        {spacer1}
        <p className={subTitleSty}>{'딱 맞는 두뇌 운동 찾기'}</p>

        {spacer6}
        <button
          className="flex flex-row items-center m-4 mx-auto p-2 px-8 rounded focus:outline-none"
          onClick={() => props.history.push('/award')}
        >
          <p className=" text-blue29F font-bold text-xl">{'설문 시작'}</p>
          <img src={'/images/icons/passBlue.png'} className="mx-1 w-4 h-4" />
        </button>

        <p className="my-12 text-white">Void</p>

      </div>
      <div className="fixed bottom-0 w-full bg-white">
        <p className="my-3 mx-auto max-w-lg text-grayHalf text-xs text-center">
          {`상호: 주식회사 데카르트 | 사업자 등록번호 : 142-88-01183 | 통신판매업신고증 제 2022-서울강남-02633호 | 
          서비스명: 데카르트 | 주소: 서울시 강남구 테헤란로 129 강남n타워 917호 | 대표자: 이제빈 | 
          고객센터/제휴문의(이메일): help@brain-dr.com | 고객센터(전화): 070-8065-7344`}
        </p>
      </div>

    </div>
  );
}

export default withRouter(Screen);
