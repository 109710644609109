import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { spacer6 } from '../components';

const Wrap = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 30px 10px 300px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  `;
const ratio = 0.7;
const Trophy = styled.img`
  width: ${() => ratio * 424}px;
  height: ${() => ratio * 768}px;
  z-index: 10;
`;
const pr = 0.63;
const Pattern = styled.img`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  aspect-ratio: 1;
  object-fit: cover;
  width: ${() => pr * 835}px;
  height: ${() => pr * 1173}px;
`;

function Screen(props) {
  return (
    <div className={'flex flex-col items-center justify-center bg-orange'}
      style={{ wordBreak: 'keep-all' }} >
      <Wrap
        className="focus:outline-none bg-orange"
        onClick={() => props.history.push('/splash')}
      >
        <Pattern src={'./images/awardPtrn.png'} />
        {spacer6}
        {spacer6}
        <Trophy src={'./images/award.png'} />
        {spacer6}
        {/* <TitleBox>
          <Logo src={'/images/logo.png'} />
          <p className="m-2 text-gray-900 text-2xl text-center font-bold tracking-widest">{'데카르트'}</p>
        </TitleBox>
        <p className={subTitleSty}>{'구글 선정 2021년을'}</p>
        <p className={subTitleSty}>{'빛낸 인기 앱'}</p>
        <p className={subTitleSty}>{'최우수상 수상'}</p>
        {spacer6}
        {spacer6}
        <GoogleLogo src={'/images/googleLogo.png'} /> */}
      </Wrap>
    </div>
  );
}

export default withRouter(Screen);
