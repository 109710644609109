import styled from 'styled-components';

import { ProdID } from '../models';
import { spacer2 } from './SharedCompos';

const ImgDiscount = styled.img`
  width: 6rem;
  height: 3.4rem;
  margin: -25px auto 0px;
  object-fit: contain;
  z-index: 15;
`;
const ImgStripe = styled.img`
  width: 72px;
  height: 72px;
  z-index: 5;
  position: absolute;
`;
const ImgStripeTL = styled(ImgStripe)`
  top: 0;
  left: 0;
`;
const ImgStripeBR = styled(ImgStripe)`
  bottom: 0;
  right: 0;
`;
// Title
const TitleStyle = styled.p`
  font-size: 1.8rem;
  font-weight: 600;
  z-index: 10;
`;
const YellowBar = styled.div`
  position: absolute;
  bottom: 3px;
  left: 0;
  width: 6rem;
  height: 15px;
  z-index: 1;
  background: #FFD52E;
`;

// Discount guide
const DiscountBox = styled.div`
  background-color: #F90;
  border-radius: 5px;
  width: 4.2rem;
  padding: 1px 0px;
  font-size: 0.7rem;
  font-weight: 500;
  text-align: center;
  color: #FFFA;
`;
const Striked = styled.p`
  display: flex;
  margin: 5px 0;
  position: relative;
  align-items: center;
`;
const StrikedText = styled.div`
  width: 12.4rem;
  font-size: 1.1rem;
  text-decoration-line: line-through;
  font-weight: 400;
  color: #555B;
`;
// zIndex: 100,

const NewYearBox = styled.button`
  position: relative;
`;
  // 365 148
const ImgRecommend = styled.img`
  width: ${365 * 0.8}px;
  height: ${148 * 0.8}px;
  bottom: 0px;
  right: 30px;
  z-index: 0;
  position: absolute;
`;

export default function Component(props) {
  const { selectedIdx, isPayState, recPhone, onClick } = props;
  const selected = selectedIdx >= 0;
  const opacity = selected ? 0.5 : 1.0;

  return (
    <div className="flex flex-col self-stretch">
      <div style={{ position: 'relative', display: 'flex' }}>
        <TitleStyle className="text-gray-800">{`${recPhone}님의 건강제안`}</TitleStyle>
        <YellowBar />
      </div>
      <TitleStyle className="text-gray-800">{'데카르트 정기구독'}</TitleStyle>
      {isPayState && (
        <TitleStyle className="text-gray-800">{'12개월 이용권 73,000원'}</TitleStyle>
      )}

      {spacer2}

      {/* 설날 이벤트 버튼 */}
      {!selected && (
        <NewYearBox
          className={`flex flex-col rounded-xl my-2 focus:outline-none 
            shadow-xl bg-yellowBrain border-gray-DF border`}
          onClick={(e) => {
            e.preventDefault();
            onClick(ProdID.Recommend);
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'row', background: '#f390', alignSelf: 'stretch' }}
            className="py-2"
          >
            <div
              className={'flex justify-center flex-col self-stretch'}
              style={{ flex: 5 }}
            >
              <ImgDiscount src={'/images/icons/discount60rec.png'} />
            </div>
            <div className="flex " style={{ flex: 10, opacity }}></div>
          </div>

          <div style={{ position: 'relative', alignSelf: 'stretch', flex: 3, padding: '0 20px', textAlign: 'left' }} >
            <ImgRecommend src="/images/recommend.png" />
            <p style={{
              fontSize: '1.8rem',
              lineHeight: '2.3rem',
              fontWeight: 600,
            }} className="text-gray-800">
              {`${recPhone}님이 1만원 할인권을`}<br/>{'선물했어요'}
            </p>
            <div style={{ padding: '11px', background: '#89a0' }}/>

            <Striked>
              <StrikedText>
                {'12개월 이용권 '}<a style={{ fontWeight: 400 }}>&nbsp;&nbsp;{'118,800원'}</a>
              </StrikedText>
              <DiscountBox>{'60%할인'}</DiscountBox>
            </Striked>
            <Striked>
              <StrikedText>
                {'12개월 이용권 '}<a style={{ fontWeight: 400 }}>&nbsp;&nbsp;{'83,000원'}</a>
              </StrikedText>
              <DiscountBox>{'1만원 할인'}</DiscountBox>
            </Striked>

            {spacer2}

            <p
              style={{
                fontSize: '1.7rem',
                lineHeight: '2rem',
                fontWeight: 600,
                position: 'relative',
                zIndex: 100,
              }}
              className="text-gray-800">
              {'12개월 이용권 '}<a style={{ fontWeight: 500 }}>&nbsp;{'73,000원'}</a>
            </p>
            <div style={{ padding: '10px', background: '#89a0' }}/>
            <p style={{
              position: 'relative',
              zIndex: 100,
              fontSize: '1.1rem',
              lineHeight: '1.3rem',
              fontWeight: 400,
            }} className="text-gray-600">
              {'한달 기준 6,080원'}
            </p>
            <div style={{ padding: '35px', background: '#89a0' }}></div>
          </div>
        </NewYearBox>
      )}

    </div>
  );
}
