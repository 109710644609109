import { MdlFaq } from './typesModel';

export const arrFaq: MdlFaq[] = [
  {
    question: '환불이나 취소 절차가 쉽나요?',
    answer:
    '예 매우 쉽습니다. 앱에 구매정보에서 버튼 터치만으로 환불 취소가 가능합니다. 이것도 어려우신 경우 데카르트 고객센터의 전화 혹은 카카오톡으로 환불원한다는 메시지를 남겨주시면 즉시 처리해드립니다.',
  },
  {
    question: '왜 두뇌 건강을 관리해야 하나요?',
    answer: `몸건강만큼 중요한것이 두뇌건강 입니다. 
      두뇌건강은 일상생활에서 불편함을 느낀뒤에 병원에갈 경우 치매나 인지장애가 
      이미 돌이킬 수 없을 만큼 진행된 경우가 대부분 입니다. 
      몸건강과는 다르게 두뇌건강은 작은 문제가 생겼을때 알아채기가 어렵기 때문입니다. 
      알아챈다고 하더라도 당사자는 “나이들어서 건망증이 심해지는거야” 정도로 쉽게 생각하거나
      치매라는 단어가 주는 두려움으로 문제를 외면하고 알리지 않는 경우가 많습니다. 
      우리는 고혈압, 당뇨 등의 몸건강을 예방하기 위해서 규칙적으로 걷기 등의 운동을 하고 
      식단관리를 하는것을 당연히 생각합니다. 하지만 두뇌건강관리에 대해서는 그렇지 못합니다. 
      두뇌건강에 문제가 생겼을때 발생하는 치매, 
      경도인지장애 등의 질병이 주는 고통을 우리모두 알고 있습니다. 
      어느날 소중한 분이 병원에서 경도인지장애, 
      초기치매 진단을 받는것은 상상하지 싫을 만큼 슬픈 순간일 것 입니다. 
      몸건강을 생각하는 만큼 두뇌건강도 당연하게 관리하세요.`,
  },
  {
    question: '치매가 예방되나요?',
    answer: `네 가능합니다. 치매는 일상생활에 불편함이 없을 만큼 아주 초기에 
    발견할 경우 10~15%는 완치되면 대부분의 경우 그 진행을 급격하게 지연할 수 있습니다. 
    하지만 조기 발견을 위해서는 평소에 두뇌건강에 관심을 가지고 꾸준히 관리하고 있어야 합니다. 
    데카르트는 두뇌의 인지능력, 학습능력을 자극하고 신체활동을 독려하여 매일매일 필요한 만큼의 
    두뇌자극을 줍니다. 마치 우리가 규칙적으로 산책을 하고 건강한 식단을 유지할 때 고혈압, 당뇨, 비만 등의 
    질병을 예방할 수 있는 것과 같은 원리입니다. 
    기억력, 사고력, 집중력 등을 복합적으로 사용할수록, 드라마나 유튜브를 보는 등 수동적인 두뇌활동의 비중이 줄어들수록
    우리의 두뇌는 건강해질 수 있습니다. 
    뿐만 아니라 더 중요한것은 매일 5~10분이라도 두뇌운동은 하면서 평소에 두뇌건강에 관심을 갖는 것만으로도 
    조기 발견의 가능성을 획기적으로 높일 수 있습니다.
    `,
  },
  {
    question: '저도 두뇌 운동 게임을 할 수 있을까요?',
    answer: `네 50대 이상 누구나 하실 수 있도록 직관적이고 심플하게 개발되었습니다. 
    모든 두뇌운동 게임은 기존에 모바일 게임을 접해본 적이 없는 50~60대 분들의 사용 테스트를 거쳐 
    개발되었습니다. 단순하지만 재미있는 게임을 기반으로 설계되었습니다. 
    시켜서 하는 것이 아니라 재미있어서 찾는 운동 프로그램들이 구성되어 있습니다. 
    두뇌운동 게임뿐만 아니라 데카르트의 모든 버튼과 글씨는 크고 직관적으로 디자인되어 있으며, 
    복잡한 기능을 최대한 단순화하여 누구나 사용하기 편합니다.`,
  },
  {
    question: '영어공부 프로그램 저한테는 어려울것같아요.',
    answer: ` ABC를 모르셔도 따라 할 수 있습니다. 최근 언어 교육학 학계에서 중요한 화두 중 하나는 학습의 게임화입니다.
      알파벳이나 영어 단어를 모르더라도 무조건 따라만 하면 문장을 습득할 수 있는 방식으로 영어 공부를 할 수 있습니다. 
      어린아이가 처음 사용하는 문장부터 여행에 필요한 기분 문장이 난이도 별로 수록되어 있습니다. 
      그리고 모든 과정은 영어를 공부하는 것이 아니라 퍼즐을 맞추는 것처럼 쉽게 보고 따라 할 수 있도록 개발되었습니다.`,
  },
  {
    question: '자주 사용하지 않으면 어떡하죠?',
    answer: `결제 후 14일 이내에 조건없이 100% 환불 가능합니다. 다만 데카르트는 해야 하는 것이 아니라 즐기는 것입니다. 
      모든 두뇌운동 프로그램은 단순하고 게임성이 검증된 프로그램을 기반으로 제작되었습니다. 
      매일매일 스스로 두뇌운동을 하게 되실 거예요. 뿐만 아니라 매일매일 내가 재미있어하는 두뇌운동과 필요한 두뇌운동을 
      맞춤으로 정해진 목표를 세워드립니다. 두뇌운동 데이터를 분석하여 동기부여를 잃지 않고 재미있게 할 수 있는 양의 목표와 
      새로운 운동 프로그램을 매일 만나시게 됩니다. 게임뿐만 아니라 집에서 따라 할 수 있는 가벼운 운동이나 
      여행 영어 공부, 건강 정보 등을 포함하고 있어 원하는 콘텐츠를 보기 위해서라도 자주 사용하시게 될 거예요. `,
  },
  {
    question: '두뇌 건강을 진단할 수 있는 건가요?',
    answer: `사고력, 언어력, 기억력, 계산력, 집중력 각 영역별로 어떤 영역이 강점이고 어떤 영역이 약점인지 상대적 분석 평가가 가능합니다. 
    그리고 각 영역 별로 전체 유저와 상대평가를 통해 부모님의 각 영역별 수준을 알 수 있습니다. 
    향후 나의 두뇌운동 기록이 충분히 축적하여 연령별 유저와의 비교 및 의학적 두뇌건강 진단과의 연계 방법은 연구가 진행 중입니다. 
    빠른 시일 내에 업데이트 소식을 전해 드리겠습니다.
    `,
  },
  {
    question: '저는 아직 건강한데 필요할까요?',
    answer: `치매 환자는 빠르게 늘고 있습니다. 2020년 현재 80만 명 이상이 치매를 앓고 있는 것으로 추정되고, 
    향후 10년마다 두 배씩 증가할 것으로 예상됩니다. 치매 유병률은 65세 이상에서 10%, 경도인지장애는 65세 이상에서 20%에 달합니다. 
    두되 질병은 발병 이후에는 그 치료 방법이 매우 제한적입니다. 
    돌이킬 수 없는 질병으로 미리 관리하고 조기에 발견하는 것이 거의 유일한 예방법이고 치료법입니다. 
    나이가 들면 우리 중 10명 중 1명은 치매, 5명 중 1명을 경도인지장애를 겪는 시대에 살고 있습니다. 
    치매를 예방하고 싶다면 하루에 5분, 10분을 두뇌건강에 관심을 갖는데 쓰는 것은 선택이 아니라 필수입니다.`,
  },
];
