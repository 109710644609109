import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withRouter } from 'react-router-dom';

function Compo() {
  const smlFont = 'text-base md:text-lg text-gray-800';

  return (
    <div className="flex flex-row self-stretch justify-between px-3 py-4">
      <p
        className={`text-center ${smlFont}`}
        style={{ flex: 4 }}>
        {'문의하기'}
      </p>
      {/* <CopyToClipboard text="http://pf.kakao.com/_cRwfK/chat"> */}
      <button
        className="flex flex-row justify-center focus:outline-none"
        style={{ flex: 6 }}
        onClick={(e) => {
          e.preventDefault();
          window.location.href = 'http://pf.kakao.com/_cRwfK/chat';
          // alert('클립보드에 링크가 복사되었습니다.');
        }}
      >
        <img src="/images/icons/finalKakao.png" alt="''" className="w-6 h-6 mr-2" />
        <p className={`${smlFont} underline`}>카톡으로</p>
      </button>
      {/* </CopyToClipboard> */}
      <CopyToClipboard text="07080657344">
        <button className="flex flex-row justify-center focus:outline-none sd:mx-3"
          // style={{ flex: 9 }}
          onClick={(e) => {
            e.preventDefault();
            alert('클립보드에 전화번호가 복사되었습니다.');
          }}
        >
          <img src="/images/icons/finalPhone.png" alt="''" className="w-6 h-6 mr-2" />
          <p className="font-extrabold text-base md:text-lg ">070-8065-7344</p>
        </button>
      </CopyToClipboard>
    </div>
  );
}

export default withRouter(Compo);
