export default {
  v: '5.6.6',
  fr: 60,
  ip: 0,
  op: 120,
  w: 190,
  h: 190,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [{
    ddd: 0,
    ind: 1,
    ty: 4,
    nm: 'Path 17564',
    sr: 1,
    ks: {
      o: {
        a: 0,
        k: 100,
        ix: 11,
      },
      r: {
        a: 0,
        k: 0,
        ix: 10,
      },
      p: {
        a: 0,
        k: [94.826, 95.443, 0],
        ix: 2,
      },
      a: {
        a: 0,
        k: [30.614, 20.595, 0],
        ix: 1,
      },
      s: {
        a: 0,
        k: [100, 100, 100],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [
              [0, 0],
              [0, 0],
              [0, 0],
            ],
            o: [
              [0, 0],
              [0, 0],
              [0, 0],
            ],
            v: [
              [0, 21.151],
              [20.038, 41.189],
              [61.228, 0],
            ],
            c: false,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st',
        c: {
          a: 0,
          k: [1, 1, 1, 1],
          ix: 3,
        },
        o: {
          a: 0,
          k: 100,
          ix: 4,
        },
        w: {
          a: 0,
          k: 12,
          ix: 5,
        },
        lc: 2,
        lj: 2,
        bm: 0,
        nm: 'Stroke 1',
        mn: 'ADBE Vector Graphic - Stroke',
        hd: false,
      }, {
        ty: 'tr',
        p: {
          a: 0,
          k: [0, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100],
          ix: 3,
        },
        r: {
          a: 0,
          k: 0,
          ix: 6,
        },
        o: {
          a: 0,
          k: 100,
          ix: 7,
        },
        sk: {
          a: 0,
          k: 0,
          ix: 4,
        },
        sa: {
          a: 0,
          k: 0,
          ix: 5,
        },
        nm: 'Transform',
      }],
      nm: 'Path 17564',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'tm',
      s: {
        a: 1,
        k: [{
          i: {
            x: [0.667],
            y: [1],
          },
          o: {
            x: [0.333],
            y: [0],
          },
          t: 20,
          s: [0],
        }, {
          t: 50,
          s: [0],
        }],
        ix: 1,
      },
      e: {
        a: 1,
        k: [{
          i: {
            x: [0.667],
            y: [1],
          },
          o: {
            x: [0.333],
            y: [0],
          },
          t: 20,
          s: [0],
        }, {
          t: 50,
          s: [100],
        }],
        ix: 2,
      },
      o: {
        a: 0,
        k: 0,
        ix: 3,
      },
      m: 1,
      ix: 2,
      nm: 'Trim Paths 1',
      mn: 'ADBE Vector Filter - Trim',
      hd: false,
    }],
    ip: 0,
    op: 120,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 2,
    ty: 4,
    nm: 'correct',
    sr: 1,
    ks: {
      o: {
        a: 0,
        k: 100,
        ix: 11,
      },
      r: {
        a: 0,
        k: 0,
        ix: 10,
      },
      p: {
        a: 0,
        k: [95, 95, 0],
        ix: 2,
      },
      a: {
        a: 0,
        k: [78, 78, 0],
        ix: 1,
      },
      s: {
        a: 1,
        k: [{
          i: {
            x: [0.667, 0.667, 0.667],
            y: [1, 1, 1],
          },
          o: {
            x: [0.333, 0.333, 0.333],
            y: [0, 0, 0],
          },
          t: 0,
          s: [0, 0, 100],
        }, {
          i: {
            x: [0.667, 0.667, 0.667],
            y: [1, 1, 1],
          },
          o: {
            x: [0.333, 0.333, 0.333],
            y: [0, 0, 0],
          },
          t: 25,
          s: [120, 120, 100],
        }, {
          t: 37,
          s: [100, 100, 100],
        }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [
              [0, -43.109],
              [43.109, 0],
              [0, 43.109],
              [-43.109, 0],
            ],
            o: [
              [0, 43.114],
              [-43.114, 0],
              [0, -43.114],
              [43.114, 0],
            ],
            v: [
              [156, 78],
              [78, 156],
              [0, 78],
              [78, 0],
            ],
            c: true,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'fl',
        c: {
          a: 0,
          k: [0.996078431372549, 0.8431372549019608, 0.23137254901960785, 1],
          ix: 4,
        },
        o: {
          a: 0,
          k: 100,
          ix: 5,
        },
        r: 1,
        bm: 0,
        nm: 'Fill 1',
        mn: 'ADBE Vector Graphic - Fill',
        hd: false,
      }, {
        ty: 'tr',
        p: {
          a: 0,
          k: [0, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100],
          ix: 3,
        },
        r: {
          a: 0,
          k: 0,
          ix: 6,
        },
        o: {
          a: 0,
          k: 100,
          ix: 7,
        },
        sk: {
          a: 0,
          k: 0,
          ix: 4,
        },
        sa: {
          a: 0,
          k: 0,
          ix: 5,
        },
        nm: 'Transform',
      }],
      nm: 'correct',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 0,
    op: 120,
    st: 0,
    bm: 0,
  }],
  markers: [],
};
