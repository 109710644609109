import { LocalStorage } from '../models';
import { apiAgentV1, apiAgentV2, showApiError } from './apiAgent';

export const postQuest = async (param): Promise<string> => {
  try {
    const resp = await apiAgentV1.post('v1/quest/', param);
    console.log('api POST :: quest ', resp);
    return 'OK';
  } catch (error) {
    if (error.response) {
      // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // 요청이 이루어 졌으나 응답을 받지 못했습니다.
      // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
      // Node.js의 http.ClientRequest 인스턴스입니다.
      console.log(error.request);
    } else {
      // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
      console.log('Error', error.message);
    }
    showApiError(error);
    return 'ERROR';
  }
};

export const postQuestV2 = async (param): Promise<string> => {
  try {
    const id = localStorage.getItem(LocalStorage.QuestID);
    const phone = localStorage.getItem('kakaophone');
    if (id) param.id = id;
    if (phone) param.phone = phone;
    const resp = await apiAgentV2.post('quest/', param);
    const mdbId = resp.data?.data?._id || '';
    // console.log('api POST :: quest v2 : MDB ID : ', id, mdbId);
    if (mdbId) localStorage.setItem(LocalStorage.QuestID, mdbId);
    return mdbId;
  } catch (error) {
    if (error.response) {
      // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
    showApiError(error);
    return 'ERROR';
  }
};
