import './styles/index.css';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { tagManagerArgs } from './util';

TagManager.initialize(tagManagerArgs);
ReactGA.initialize('UA-142700341-2', {
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});
ReactGA.pageview(window.location.pathname + window.location.search);

// console.log('env :: ', process.env.REACT_APP_ENV);

if (process.env.REACT_APP_SENTRY) {
  Sentry.init({
    dsn: 'https://7ce05f2720d64810b7210dff8cc85bf4@o961704.ingest.sentry.io/5910089',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
