import _ from 'lodash';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import {
  bottomSpacer, confirmButton, Header, marginSide, QuestionBox, questionTitle, screenContainerBase, spacer15,
} from '../components';
import {
  applyGift, getNextPage, giftIdx, LocalStorage, MdlQstn,
  Parents, QstnActionType, QstnType, useQstnDispatch, useQstnState,
} from '../models';
import { Question4, Question6year, Question20, QuestionEnd, QuestionHarvard, QuestionStore } from '.';

function Screen(props) {
  const dispatch = useQstnDispatch();
  const { arrQstn, qstnAnswer, totalPageNum } = useQstnState();
  const gIdx = giftIdx(qstnAnswer);
  const curQstnObj: MdlQstn = arrQstn.find((o: MdlQstn) => `${o.page}` === props.match.params.page);
  if (!curQstnObj) {
    props.history.push('/splash');
    return null;
  }
  const { page, question, options, isConfirmBttn, giftOptions } = curQstnObj;
  const arrAnswer = qstnAnswer.get(page)?.answer || [];

  // for debug
  // const [devNav, setDevNav] = useState('/question/');

  const setArrAnswer = (answer) => {
    dispatch({
      type: QstnActionType.Answer,
      payload: {
        qstnIdx: page,
        answer,
      },
    });
  };

  useEffect(() => {
    if (!arrAnswer) {
      setTimeout(() => {
        setArrAnswer([]);
      }, 0);
    }
  }, []);

  useEffect(() => {
    if (page > 1 && page < 4) {
      console.log(' Set Storage : ', page, Parents.Key, [Parents.No, Parents.Mom, Parents.Dad][gIdx]);
      localStorage.setItem(Parents.Key, [Parents.No, Parents.Mom, Parents.Dad][gIdx]);
    }
    localStorage.removeItem(LocalStorage.Gift);
  }, [page]);

  const exclusiveOptions = options.filter((opt) => !!opt.isOnlyAnswer);
  const isValid = options.length === 0 || (isConfirmBttn && arrAnswer.length > 0);
  // console.log(' Render : page : ', page, totalPageNum, arrAnswer, curQstnObj, ' exclusive : ', exclusiveOptions);

  const nextPage = getNextPage(qstnAnswer, curQstnObj);

  switch (page) {
  case 0.5: return (<QuestionHarvard johnsHopkins params={props.match.params} history={props.history} />);
  case 4:
  case 4.3:
  case 4.6:
    return (
      <Question4
        params={props.match.params} answer3={qstnAnswer.get(3)?.answer} nextPage={nextPage} history={props.history}
      />);
  case 6: return (<Question6year params={props.match.params} history={props.history} />);
  case 7: return (<QuestionStore params={props.match.params} history={props.history} />);
  case 12:
  case 15: return (<QuestionHarvard johnsHopkins={false} params={props.match.params} history={props.history} />);
  case 20: return (<Question20 params={props.match.params} history={props.history} />);
  case 18:
  case 22: return (<QuestionEnd params={props.match.params} history={props.history} />);
  }

  const answerAct = (qParam: QstnType) => {
    const { qStr, isOnlyAnswer } = qParam;
    const included = arrAnswer.includes(qStr);
    // Select isOnlyAnswer. remove others, select / deselect one
    if (isOnlyAnswer) {
      setArrAnswer(included ? [] : [qStr]);
      return;
    }
    // only option case
    if (!isConfirmBttn) {
      // setArrAnswer([qStr]);
      return;
    }
    const ans = [...arrAnswer];
    if (arrAnswer.includes(qStr)) {
      _.remove(ans, (s) => s === qStr);
      setArrAnswer(ans);
    } else {
      if (exclusiveOptions.length > 0) {
        exclusiveOptions.forEach((option) =>
          _.remove(ans, (s) => s.includes(option.qStr)),
        );
      }
      setArrAnswer([...ans, qStr]);
    }
  };

  return (
    <div className={`${screenContainerBase} ${marginSide}`} style={{ wordBreak: 'keep-all' }}>
      <Header progress={page / totalPageNum} history={props.history} />
      <p className={questionTitle} style={{ wordBreak: 'keep-all' }}>{applyGift(qstnAnswer, question, giftOptions)}</p>
      <div className="p-3 m-5" />
      {options.map((q: QstnType) => (
        !(!!q.showIdx && !q.showIdx.includes(gIdx)) && (
          <QuestionBox
            key={q.qStr}
            content={q.qStr}
            selected={arrAnswer.includes(q.qStr)}
            onClick={() => {
              if (isConfirmBttn) {
                answerAct(q);
              } else {
                answerAct(q);
                dispatch({
                  type: QstnActionType.Answer,
                  payload: {
                    qstnIdx: page,
                    answer: q.qStr,
                  },
                });
                setTimeout(() => {
                  props.history.push(getNextPage(qstnAnswer, curQstnObj));
                }, 200);
              }
            }}
          />
        )))}

      {/*
      <div className="m-5">
        <input
          className="m-2 p-2 rounded-lg bg-blue-200 text-xl focus:outline-none"
          style={{ flex: 3, caretColor: '#FFD52E' }}
          placeholder="Nav to"
          type="text"
          value={devNav}
          onChange={(e) => {
            setDevNav(e.target.value);
          }}
        />
        <button className="bg-green-300 p-2 rounded mx-3"
          onClick={(e) => props.history.push(devNav)}
        >Press here</button>
        <button className="bg-green-300 p-2 rounded mx-3"
          onClick={(e) => props.history.push('/question/0.7')}
        >Select Subj</button>
      </div>
      */}

      {spacer15}

      {isConfirmBttn && (
        <button
          className={`${confirmButton} ${isValid ? 'bg-yellowBrain' : 'bg-gray-200'}`}
          disabled={!isValid}
          onClick={(e) => {
            e.preventDefault();
            console.log('\n\nConfirm : ', qstnAnswer);
            props.history.push(getNextPage(qstnAnswer, curQstnObj));
          }}
        >
        확 인
        </button>
      )}

      <div className={bottomSpacer} />
    </div>
  );
}

export default withRouter(Screen);
