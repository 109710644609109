// import { withRouter } from 'react-router-dom';

import { bottomSpacer, confirmButton, Header, marginSide, screenContainerBase, spacer15 } from '../components';
import { MdlQstn, useQstnState } from '../models';

export default function Screen({ params, history, johnsHopkins }) {
  // if (!answer3) { history.push('/question/3'); }
  const { arrQstn, totalPageNum } = useQstnState();
  const { page, nextPage, imgName } =
    arrQstn.find((o: MdlQstn) => `${o.page}` === params.page);

  return (
    <div className={`${screenContainerBase} ${marginSide}`} style={{ wordBreak: 'keep-all' }}>
      <Header progress={page / totalPageNum} history={history} />
      <img src={`/images/${johnsHopkins ? 'johnsHopkins' : 'harvardMedicalSchool'}.png`} className="w-32" />
      <div className="m-10" />
      <div className="flex flex-col items-center justify-center">
        <img src={`/images/${imgName}.png`} className="w-96" />
      </div>
      {spacer15}
      <button
        className={`${confirmButton} bg-yellowBrain`}
        onClick={(e) => {
          e.preventDefault();
          history.push(nextPage);
        }}
      >
      확 인
      </button>

      <div className={bottomSpacer} />
    </div>
  );
}
