import {
  bottomSpacer, confirmButton, Header, marginSide, questionTitleNarrow, screenContainerBase, spacer15,
} from '../components';
import { getNextPage, MdlQstn, useQstnState } from '../models';

export default function Screen({ params, history }) {
  // if (!answer3) { history.push('/question/3'); }
  const { arrQstn, totalPageNum, qstnAnswer } = useQstnState();
  const curQstnObj: MdlQstn = arrQstn.find((o: MdlQstn) => `${o.page}` === params.page);
  const {
    page, question, subQuestion, imgName, imgClassName,
  } = curQstnObj;
  const nextPage = getNextPage(qstnAnswer, curQstnObj);

  return (
    <div className={`${screenContainerBase} ${marginSide}`} style={{ wordBreak: 'keep-all' }}>
      <Header progress={page / totalPageNum} history={history} />
      <div className="m-12" />
      <div className="flex self-stretch justify-center">
        <img src={`/images/${imgName}`} className={imgClassName} />
      </div>
      <div className="m-5" />
      <div className="flex flex-col items-center justify-center ">
      </div>
      <div className="m-2"></div>
      <p className={questionTitleNarrow} style={{ wordBreak: 'keep-all' }}>{question}</p>
      <p className={questionTitleNarrow} style={{ wordBreak: 'keep-all' }}>{subQuestion}</p>

      {spacer15}

      <button
        className={`${confirmButton} bg-yellowBrain`}
        onClick={(e) => {
          e.preventDefault();
          history.push(nextPage);
        }}
      >
      확 인
      </button>

      <div className={bottomSpacer} />
    </div>
  );
}
