import { useEffect } from 'react';
import ReactGA from 'react-ga';

import {
  bottomFixed, bottomSpacer, MainButton, MainMenu, marginSide, questionTitleNarrow, screenContainerBase, spacer6,
} from '../components';
// import { tagManagerArgs } from '../util';

// tagManagerArgs.LayerName = 'Kakao-Fail';
// TagManager.initialize(tagManagerArgs);

export default function Screen({ history }) {
  useEffect(() => {
    ReactGA.event({
      category: 'Screen',
      action: 'Kakao Pay Fail',
    });
  }, []);
  return (
    <div className={`${screenContainerBase} ${marginSide}`} style={{ wordBreak: 'keep-all' }}>
      <MainMenu history={history} disableBackButton />
      {spacer6}
      <div className="py-16 flex flex-col items-center">
        <p className={questionTitleNarrow} >{'카카오 결제에 실패하였습니다.'}</p>
        <p className="text-lg my-10">확인 버튼을 눌러 다시 시도하시기 바랍니다.</p>
      </div>
      <div className="m-3" />
      <div className={`${bottomFixed} flex flex-col`} >
        <MainButton
          title={'확인'}
          onPress={() => {
            history.replace('/program-pay');
          }}
        />
      </div>

      <div className={bottomSpacer} />
    </div>
  );
}
