import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';
import Lottie from 'react-lottie';

import { getRecommender } from '../api';
import {
  AwardRow, CircleStatus, CompanyInfo, ContactBar, MainButton, MainMenu, marginSide,
  PayButtonRecommend, screenContainerBase, spacer1, spacer3, spacer6, spacer8, spacer10, StoreReviews,
} from '../components';
import WaitSpin from '../images/wait-spinning';
import {
  giftIdx, IReferer, kakaoReady, LocalStorage, Parents, PaymentMethod,
  QstnActionType, useQstnDispatch, useQstnState,
} from '../models';
import { formatPhoneNumber, getNumbers } from '../util';

const lottieOptions = {
  animationData: WaitSpin,
  loop: true,
  autoplay: true,
  rendererSettings: {
    className: 'add-class', // svg에 적용
    preserveAspectRatio: 'xMidYMid slice',
  },
};

let tick = null;

export default function Screen({ match, history }) {
  const isRec = !!match.params?.user;
  const { kakaoPay, authPhone, qstnAnswer } = useQstnState();
  const dispatch = useQstnDispatch();
  const [referer, setRefer] = useState<IReferer>({ phone: '0000' });
  const [touched, setTouch] = useState(false);
  const [isPayState, setPayState] = useState(false);
  const [prodIdx, setProdIdx] = useState(-1);

  const gIdx = giftIdx(qstnAnswer);
  const isParent = gIdx > 0;

  const [isGift, setGift] = useState(match.params.option === 'gift' || isParent);
  // const subject = gIdx === 2 ? '아버지' : '어머니';
  // const parenKind = gIdx === 2 ? Parents.Dad : Parents.Mom;
  const initMode = !isPayState && !isGift;
  const phone010 = authPhone.length > 10 ? '0' + authPhone.substring(3) : authPhone;
  const [phone, setPhone] = useState(getNumbers(phone010));
  const [phone2, setPhone2] = useState('');
  const [showTopButton, setButtonShow] = useState(false);

  const validPhone = phone.length === 11;
  const fullNumber = phone.length > 0 ? '+82' + phone.substring(1) : phone;
  const selfRecomCase = fullNumber === referer?.phone && phone === phone2;
  const activateButton = !selfRecomCase && (!isPayState || (validPhone && phone === phone2 && !touched));

  const initSet = async () => {
    if (!isRec) return;
    const resp = await getRecommender({ link: match.params?.user });
    // const dd = resp.data?.data?;
    if (resp && resp.phone && resp.phone.length > 10) {
      const { phone } = resp;
      setRefer({ phone });
    }
  };

  const referStr = referer.phone.substring(referer.phone.length - 4) || '0000';

  useEffect(() => {
    console.log('Program Pay : params :: ', match.params, isRec);
    initSet();
    const payMethod: PaymentMethod = 'Recommend';
    localStorage.setItem(LocalStorage.PaymentMethod, payMethod);
    ReactGA.event({
      category: 'Screen',
      action: 'Kakao Pay',
    });

    localStorage.setItem(Parents.Key, Parents.No);
    dispatch({
      type: QstnActionType.SetKakao,
      payload: {
        isMobile,
      },
    });
    tick = setInterval(() => {
      setButtonShow(window.pageYOffset > 460);
      // console.log(' scroll ', window.pageYOffset);
    }, 500);
    return () => {
      if (tick) {
        clearInterval(tick);
      }
    };
  }, []);

  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    window.scrollTo(0, 0);
  }, [isPayState]);

  // const remainTime = useMemo(() => {
  //   const min = Math.floor((totalPlayingSec - second) / 60);
  //   const sec = (totalPlayingSec - second) % 60;
  //   return min ? `${min}분 ${sec}초 ` : `${sec}초`;
  // }, [second]);

  const flex1 = (<div className="flex flex-1 m-2" />);

  const renderText = (txt) => (
    <div className="flex flex-row text-base">
      <div className="mr-1">
        <p className="text-gray-800">*</p>
      </div>
      <p className="text-left font-light text-gray-800">{txt}</p>
    </div>
  );

  return (
    <div
      className={`${screenContainerBase} ${marginSide} ${isGift ? 'bg-yellowWhite' : 'bg-grayF8'}`}
      style={{ wordBreak: 'keep-all' }}
    >
      <MainMenu history={history}
        setGift={null}
        hide
        goBack={isPayState ? () => {
          setPayState(false);
          setProdIdx(-1);
        } : null} />
      {spacer1}
      <div className="flex flex-row" >
        {flex1}
        <CircleStatus idx={isPayState ? 1 : 0} isGift={isGift} />
        {flex1}
      </div>

      {/* Kakao Pay Button */}
      <div className="flex flex-col self-stretch items-center">
        <PayButtonRecommend
          recPhone={referStr}
          selectedIdx={prodIdx}
          isPayState={isPayState}
          onClick={(idx) => {
            setPayState(true);
            setProdIdx(idx);
            // console.log('Recommend Pay : prodIdx ', idx);
          }}
        />
      </div>

      {isPayState && (
        <div className="flex flex-col flex-1 py-4">
          <div className="flex my-2">
            <p className="my-2 font-bold text-2xl">사용하실 분의 전화번호를 입력하세요.</p>
          </div>
          {renderText('아래 입력하신 전화번호로 로그인해야 데카르트를 사용할 수 있습니다.')}

          {spacer3}

          <div className="flex flex-col flex-1 justify-center my-4">
            {!touched && (
              <input
                className="self-stretch align-center my-2 p-4 rounded-lg bg-grayE6 text-xl focus:outline-none"
                style={{ flex: 3, caretColor: '#FFD52E' }}
                type="text" name="year" placeholder="전화번호 입력"
                value={formatPhoneNumber(phone)}
                onChange={(e) => {
                  const numOnly = getNumbers(e.target.value);
                  setPhone(numOnly);
                }}
              />
            )}
            <input
              className="flex flex-1 my-2 self-stretch align-center py-5 px-4
              rounded-lg bg-grayE6 text-xl focus:outline-none"
              style={{ flex: 3, caretColor: '#FFD52E' }}
              type="text" name="year" placeholder="전화번호 확인 (다시 입력)"
              value={formatPhoneNumber(phone2)}
              onChange={(e) => {
                const numOnly = getNumbers(e.target.value);
                setPhone2(numOnly);
                dispatch({ type: QstnActionType.SetKakao, payload: { phone } });
              }}
            />

            {selfRecomCase && (<p className="text-lg text-redError">{'본인이 스스로를 추천할 수는 없습니다.'}</p>)}

            {spacer6}

            {touched ? (
              <Lottie
                options={{ ...lottieOptions, animationData: WaitSpin }}
                isClickToPauseDisabled={false}
                style={{ width: '150px', height: '150px', marginBottom: '50px' }} // svg의 부모 div에 적용
                eventListeners={[
                  {
                    eventName: 'complete',
                    callback: () => console.log('the animation completed'),
                  },
                ]}
              />
            ) : (
              <div className={'flex flex-col flex-1 self-stretch'} >
                <p className="text-center font-light text-gray-800">* 결제되더라도 7일 이내 언제든 환불 가능합니다.</p>
                <MainButton
                  isActive={activateButton}
                  activeSty={{
                    bgCol: 'bg-grayRedDark',
                    txtCol: 'font-light text-kakao',
                  }}
                  padding="py-6"
                  margin="my-2"
                  onPress={() => {
                    if (isPayState) {
                      setTouch(true);
                      dispatch({ type: QstnActionType.SetKakao, payload: { phone } });

                      // 24 개월 설날 이벤트
                      const prodIdxTemp = prodIdx === 2 ? 3 : prodIdx;
                      localStorage.setItem('kakaophone', fullNumber);
                      console.log('Kakay Ready', { prodIdxTemp });
                      dispatch({ type: QstnActionType.UploadFirQuest, payload: fullNumber });
                      kakaoReady({
                        recommend: isRec ? { link: match.params?.user, phone: referer.phone } : undefined,
                        kakaoPay,
                        fullNumber,
                        authPhone,
                        prodIdx: prodIdxTemp,
                        parentKind: Parents.No,
                      });
                    } else {
                      setPayState(true);
                    }
                  }}
                >
                  <img src={'/images/icons/kakao.png'} className="mx-2 h-7" />
                  <p className="text-2xl">카카오페이 결제</p>
                </MainButton>
              </div>
            )}
          </div>
        </div>
      )}

      {/* FAQ */}
      {!isPayState && (
        <div>
          <button className="flex flex-col rounded hover:bg-gray-200"
            onClick={(e) => {
              e.preventDefault();
              history.push('/faq');
            }}
          >
            {/* <p className="text-gray-500">환불이나 취소 절차가 쉽나요?</p> */}
            <div className="flex flex-row items-center">
              <p className="text-gray-500 mr-2">• 언제든 취소 가능, 결제 후 7일 내 100% 환불</p>
              <img className="transform rotate-180 object-contain h-4 opacity-50" src={'/images/icons/arrowBack.png'} />
            </div>
          </button>
          <p className="text-gray-500 mr-2">• 12개월마다 결제되는 정기구독 상품입니다.</p>
          <p className="text-gray-500 mr-2">• {referStr}님의 추천으로 1만원 특별할인이 적용되었습니다.</p>
          <p className="text-gray-500 mr-2">• 구독 결제 후 7일간 단순 변심이라도 100% 환불됩니다.</p>
          <p className="text-gray-500 mr-2">&nbsp; (7일후 환불이 어렵습니다.)</p>
        </div>
      )}
      {spacer8}
      {initMode && (
        <div>
          <p className="py-2 font-semibold text-xl text-gray-800">앱스토어 실사용 후기</p>
          <AwardRow />
          <StoreReviews />
          {showTopButton && (
            <div className="fixed flex max-w-xl mx-auto | bottom-5 left-3 right-3 | bg-yellowBrain rounded-lg" >
              <button
                className="flex flex-1 focus:outline-none"
                onClick={(e) => {
                  e.preventDefault();
                  setButtonShow(false);
                  window.scrollTo(0, 0);
                }}
              >
                <p className="my-5 text-2xl text-gray-800 mx-auto">맨 위로</p>
              </button>
            </div>
          )}
        </div>
      )}
      {spacer3}
      {initMode && (
        <div>
          <ContactBar />
          <CompanyInfo bgColor="bg-white" textColor="text-grayHalf" accountInfo />
          {spacer10}
        </div>
      )}
      {initMode ? spacer10 : spacer3}
    </div>
  );
}
