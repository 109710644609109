import { withRouter } from 'react-router-dom';

import { MainMenu, spacer2 } from '.';

function HeaderComponent(props) {
  const { progress, history } = props;
  return (
    <div className={'flex flex-col items-start rounded-md'}>
      <MainMenu history={history} />
      {spacer2}
      {/* <button
        className="text-gray-900 my-2 text-4xl focus:outline-none"
        onClick={(e) => {
          e.preventDefault();
          props.history.goBack();
        }}
      >
        <img src={'/images/icons/arrowBack.png'} className="w-7 h-7" />
      </button> */}

      {progress > 0 && (
        <div className="flex flex-row self-stretch bg-yellow-50 rounded" >
          <div
            className="flex bg-yellow-400 rounded"
            style={{ padding: '3px', display: 'flex', flex: progress * 100 }}
          />
          <div
            className="flex  bg-yellow-50 rounded"
            style={{ padding: '3px', display: 'flex', flex: 100 - progress * 100 }}
          />
        </div>
      )}

      <div className="p-3" />
    </div>
  );
}

export default withRouter(HeaderComponent);
