import { useRef } from 'react';
import styled from 'styled-components';

import { MovieLinks } from '../models';
import { AboluteGrayBG } from './basicStyle';
import { spacer3 } from './SharedCompos';

const R = require('ramda');

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
`;
const RowImages = styled.div`
  display: flex;
  padding: 3px 0;
  margin: 1px 0;
  align-items: center;
  background: #f380;
`;
const IconsDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-self: stretch;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
  background: #9af0;
`;
const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 33%;
  background: #9f90;
`;
const IconButton = styled.button`
  position: relative;
  display: flex;
  margin: 0px 5px;
  align-self: stretch;
  &:focus { outline: none; }
`;
const IconImg = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
`;
// No Link guide
const NoLinkBox = styled.div<{
  active: boolean;
}>`
  display: flex;
  align-items: center;
  height: ${(p) => p.active ? 100 : 0}px;
  width: auto;
  transition: 'all .5s ease';
  background: #CCC;
  overflow: hidden;
  border-radius: 5px;
`;

const container = 'flex flex-col pt-6';
const title = 'text-center font-bold text-2xl text-gray-800';
const subTitle = 'm-1 text-center text-lg text-gray-800';
const arrB = [
  ['바디 스튜디오', '필수 근력운동', '하루 5분 요가', '하루 10분 요가'],
  ['플러스 아카데미', '완전 초보 영어', '기초 영어'], // '고급 영어'],
  ['휴식 안정', '오늘의 생각', '호흡하기', '잠자기'],
  ['두뇌 연구소', '나의 마음', '건강 정보', '두뇌 유형 테스트'],
];

export function ProgramB({ card, setCard }) {
  const row = !!card && card.substring(0, 2);
  const cardLink = card ? MovieLinks[card] : '';
  const fullLink = `https://www.youtube.com/embed/${cardLink}`;
  const arrRef = R.range(1, 5).map(() => useRef());

  return (
    <div className={`${container}`}>
      <p className={title} >토탈케어 프로그램</p>
      {spacer3}
      {R.range(1, 5).map((n) => (
        <ItemContainer key={n} ref={arrRef[n - 1]}>
          <div className="flex flex-row justify-center items-center mx-3" >
            <p className="bg-yellowBrain text-sm rounded-2xl py-1 px-2" >✓집중</p>
            <div className="mx-1" />
            <p className={subTitle} >{arrB[n - 1][0]}</p>
          </div>
          <RowImages>
            <IconsDiv>
              {R.range(1, arrB[n - 1].length).map((m) => {
                const name = `b${n}${m}`;
                const match = card === name;
                return (
                  <ButtonWrapper key={m}>
                    <p className="text-gray-400 my-1 text-sm text-center">{arrB[n - 1][m]}</p>
                    <IconButton
                      // className="w-20 h-20 sm:w-24 sm:h-24 md:w-36 md:h-36"
                      onClick={(e) => {
                        e.preventDefault();
                        setCard(match ? '' : name);
                        if (!match) {
                          setTimeout(() => {
                            // window.scrollTo({ behavior: 'smooth', top: arrRef[n - 1].current.offsetTop });
                            window.scrollTo(0, arrRef[n - 1].current.offsetTop);
                          }, 500);
                        }
                      }}
                    >
                      <IconImg src={`/images/program/iconB/b${n}${m}.png`}/>
                      <AboluteGrayBG match={match} />
                    </IconButton>
                  </ButtonWrapper>
                );
              })}
            </IconsDiv>
          </RowImages>
          {cardLink ? (
            <iframe
              src={fullLink}
              width="auto" height={row === `b${n}` ? '400px' : '0px'} title="Descartes" frameBorder="0" allowFullScreen
              style={{ transition: 'all .5s ease' }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          ) : (
            <NoLinkBox active={row === `b${n}`} >
              <p className="m-auto text-gray-800 font-semibold text-1xl">미리보기 준비중입니다. :) </p>
            </NoLinkBox>
          )}
        </ItemContainer>
      )) }

      {/* <p className="m-10 text-center text-base" > {'* 언제든지 원하는 프로그램을 선택할 수 있습니다'} </p> */}

    </div>

  );
}
