import {
  bottomSpacer, confirmButton, Header, marginSide, questionTitle, screenContainerBase, spacer15,
} from '../components';
import { MdlQstn, useQstnState } from '../models';

export default function Screen({ params, history }) {
  // if (!answer3) { history.push('/question/3'); }
  const { arrQstn, totalPageNum } = useQstnState();
  const { page, nextPage, question } =
    arrQstn.find((o: MdlQstn) => `${o.page}` === params.page);

  return (
    <div className={`${screenContainerBase} ${marginSide}`} style={{ wordBreak: 'keep-all' }}>
      <Header progress={page / totalPageNum} history={history} />
      <div className="flex flex-col items-center justify-center my-2">
        <p className={questionTitle}>{question}</p>
      </div>
      <div className="flex  self-stretch justify-center items-center">
        <img src={'/images/store.png'} className="w-96 mx-5" />
      </div>

      {spacer15}

      <button
        className={`${confirmButton} bg-yellowBrain`}
        onClick={(e) => {
          e.preventDefault();
          history.push(nextPage);
        }}
      >
      확 인
      </button>

      <div className={bottomSpacer} />
    </div>
  );
}
