
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import Lottie from 'react-lottie';

import {
  bottomSpacer, CircleStatus, CompanyInfo, ContactBar, MainButton, MainMenu, marginSide,
  screenContainerBase, spacer1, spacer3, spacer10, spacer15,
} from '../components';
import WaitSpin from '../images/wait-spinning';
import { GiftCase, KakaoApis, LocalStorage, Parents, PaymentMethod, useQstnState } from '../models';
// import { tagManagerArgs } from '../util';

const lottieOptions = {
  animationData: WaitSpin,
  loop: true,
  autoplay: true,
  rendererSettings: {
    className: 'add-class', // svg에 적용
    preserveAspectRatio: 'xMidYMid slice',
  },
};

// tagManagerArgs.LayerName = 'Kakao-Confirm';
// TagManager.initialize(tagManagerArgs);

export default function Screen({ history }) {
  const { kakaoPay } = useQstnState();
  const [payMethod, setPayMethod] = useState<PaymentMethod>('Kakao');
  const [paren, setParen] = useState<Parents>(Parents.No);
  const [isGift, setGift] = useState(false);
  const [phone, setPhone] = useState('');
  const [touched, setTouch] = useState(false);

  const readPhone = () => {
    setPhone(localStorage.getItem('kakaophone'));
  };

  useEffect(() => {
    ReactGA.event({
      category: 'Screen',
      action: 'Kakao Pay Confirm',
    });

    setPayMethod(localStorage.getItem(LocalStorage.PaymentMethod) as PaymentMethod || 'Kakao');
    const parent = localStorage.getItem(Parents.Key) as Parents;
    const giftCase = localStorage.getItem(LocalStorage.Gift) as GiftCase;
    // console.log(' Gift : ', giftCase, ' Parent : ', parent);
    setParen(paren || Parents.No);
    setGift(giftCase ? giftCase === 'Gift' : parent !== Parents.No);

    readPhone();
  }, []);

  const confirmPayment = () => {
    fetch(KakaoApis.Confirm, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ ...kakaoPay, phone, pgToken: history?.location?.search }),
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(response) {
        console.log(' resp ', response);
        // res.status(200).send(myJson.amount); from gcp-server
        if (response.total) {
          ReactGA.event({
            category: 'Pay',
            action: 'Kakao Pay Success',
            value: response.total,
          });
          alert(' 결제에 성공했습니다. !! ');
          history.push([Parents.Dad, Parents.Mom].includes(paren) ? '/parents/final' : '/final');
        }
        return response;
      });
  };

  return (
    <div
      className={`${screenContainerBase} ${marginSide}`}
      style={{ wordBreak: 'keep-all', background: touched ? '#aaa5' : '#0000' }}
    >
      <MainMenu hide={payMethod === 'Recommend'} history={history} disableBackButton />
      {spacer1}
      <CircleStatus idx={1} parent={paren} isGift={isGift} />

      {spacer3}

      <div className="py-4 flex flex-col items-center">
        <p className="font-bold text-3xl" >{'아래 버튼을 눌러 카카오 결제를 승인합니다.'}</p>
      </div>

      {touched && (
        <div className="flex flex-1 items-center">
          <Lottie
            options={{ ...lottieOptions, animationData: WaitSpin }}
            isClickToPauseDisabled={false}
            style={{ width: '150px', height: '150px' }} // svg의 부모 div에 적용
            eventListeners={[
              {
                eventName: 'complete',
                callback: () => console.log('the animation completed'),
              },
            ]}
          />
        </div>
      )}

      {spacer15}
      {spacer10}

      <div className={'flex flex-col my-4 self-stretch justify-center items-center rounded-lg py-2 '} >

        <MainButton
          title={'카카오페이 결제 확인'}
          isActive={!touched}
          // padding=""
          onPress={() => {
            setTouch(true);
            confirmPayment();
          }}
        />
        <ContactBar />
        <CompanyInfo bgColor="" textColor="text-grayHalf" accountInfo />
      </div>
      <div className={bottomSpacer} />
    </div>
  );
}
