import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isMobile } from 'react-device-detect';

import {
  CircleStatus, CompanyInfo, ContactBar, MainMenu, marginSide,
  screenContainerBase, spacer1, spacer3, spacer4, spacer8,
} from '../components';
import { GiftCase, LocalStorage, Parents } from '../models';
import { formatPhoneNumber } from '../util';

const shareData = {
  title: '',
  text: '데카르트 멤버쉽 등록방법 \n1. 데카르트 설치 하기 \n https://braindr.page.link/ELQn \n2. 회원가입 / 로그인 (전화번호 인증)\n3. 두뇌운동 시작!',
  url: 'https://braindr.page.link/ELQn',
};

export default function Screen({ history }) {
  const [phone, setPhone] = useState('');
  const [isGift, setGift] = useState(false);
  const process = 'text-2xl my-2 text-gray-700';

  const bttnTitle = isMobile ? '데카르트 설치하기' : '데카르트 설치 링크 복사하기';

  const readPhone = () => {
    setPhone(localStorage.getItem('kakaophone') || '');
  };

  useEffect(() => {
    readPhone();

    const giftCase = localStorage.getItem(LocalStorage.Gift) as GiftCase;
    setGift(giftCase ? giftCase === 'Gift' : false);
  }, []);

  const phoneStr = phone.length > 5 ? formatPhoneNumber('0' + phone.substring(3), ' ') : phone;

  return (
    <div className={`${screenContainerBase} ${marginSide}`} style={{ wordBreak: 'keep-all' }}>
      {spacer3}
      <MainMenu history={history} disableBackButton />
      {spacer1}
      {/* <div className="max-w-xl h-full flex flex-col mx-auto overflow-x-auto" style={{ wordBreak: 'keep-all' }}> */}
      <CircleStatus idx={2} parent={Parents.No} isGift={isGift} />

      {spacer8}

      <div
        className="flex flex-col px-10 justify-center"
        style={{ height: isMobile ? '70%' : null }}
      >
        <p className="my-4 font-bold text-4xl" >{phoneStr}</p>
        <p className="text-gray-400">번호로 등록되었습니다.</p>
        {spacer4}
        {/* {!isMobile && (
          <p className="text-2xl my-2">* 설치 링크를 문자로 보내드립니다.</p>
        )} */}
        {isMobile && (<p className="text-2xl my-2">데카르트 설치링크를 복사하고 보내주세요.</p>)}
        {/* {isMobile && (<p className="text-2xl my-2">설치 후 등록된 번호로 로그인해주세요.</p>)} */}
      </div>

      {/* {!isMobile && <div className="full-width bg-gray-200 p-1"></div>} */}

      {!isMobile && (
        <div className="flex flex-col p-10">
          <p className="font-bold text-2xl text-redForce">PC로 접속하지 말고 휴대폰으로 링크를 열어서 데카르트를 설치해주세요.</p>
          {spacer4}
          <p className="text-lg text-gray-600">데카르트 멤버십 등록 방법</p>
          <p className={process}>1. 데카르트 설치 링크 복사 <a className="underline">https://braindr.page.link/ELQn</a></p>
          <p className={process}>2. 휴대폰으로 링크 열기</p>
          <p className={process}>3. 회원가입 / 로그인 (전화번호 인증)</p>
        </div>
      )}

      {spacer8}

      <div className={'flex flex-col'}>
        <CopyToClipboard text={isGift ? shareData.text : 'https://braindr.page.link/ELQn'}>
          <button
            className={`flex flex-col my-4 self-stretch justify-center items-center 
            rounded-lg py-2 bg-kakao focus:outline-none`}
            onClick={(e) => {
              e.preventDefault();
              if (isMobile) {
                window.location.href = 'https://braindr.page.link/ELQn';
              } else {
                alert('클립보드에 링크가 복사되었습니다.');
              }
            }}
          >
            <p className="text-2xl my-4">{bttnTitle}</p>
          </button>
        </CopyToClipboard>
      </div>

      <ContactBar />
      <CompanyInfo bgColor="" textColor="text-grayHalf" accountInfo />
      {spacer3}
    </div>
  );
}
