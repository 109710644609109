import { useEffect } from 'react';
import Lottie from 'react-lottie';
import { Link, withRouter } from 'react-router-dom';

import SuccessAnim from '../images/success-check';

const lottieOptions = {
  animationData: SuccessAnim,
  loop: true,
  autoplay: true,
  rendererSettings: {
    className: 'add-class', // svg에 적용
    preserveAspectRatio: 'xMidYMid slice',
  },
};

function Main(props) {
  useEffect(() => {
    setTimeout(() => {
      props.history.push('/question/1');
    }, 2000);
  });

  return (
    <div
      className={`flex flex-col h-full items-center justify-center 
      text-white bg-yellow from-gray-600 via-teal-700 to-gray-800`}
    >
      <p className="text-gray-900 text-xl">두뇌건강의 모든 것</p>
      <p className="text-gray-900 text-3xl bold">{'데 카 르 트'}</p>

      <div className="p-10 m-11" />

      <div className="mt-4 flex justify-center">
        <Link
          className="bg-white hover:bg-gray-300 text-gray-800 px-6 py-3 rounded-lg text-xl"
          to="/question/1"
        >
          건강한 두뇌 만들기
        </Link>

        {/* <iframe src="https://lottiefiles.com/iframe/58422-success-icon-animation"></iframe> */}

      </div>
      <Lottie
        options={lottieOptions}
        // isStopped={isStopped}
        // isPaused={isPaused}
        isClickToPauseDisabled={false}
        style={{ margin: '50px', width: '100px', height: '100px' }} // svg의 부모 div에 적용
        eventListeners={[
          {
            eventName: 'complete',
            callback: () => console.log('the animation completed'),
          },
        ]}
      />
    </div>
  );
}

export default withRouter(Main);
