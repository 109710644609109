export default {
  ip: 0,
  fr: 60,
  v: '5.1.20',
  assets: [{
    id: 'precomp_1',
    layers: [{
      ty: 4,
      nm: 'check-mark',
      ip: 0,
      st: 0,
      ind: 10,
      hix: 1,
      ks: {
        o: {
          a: 1,
          k: [{
            t: 0,
            s: [0],
            e: [100],
            i: {
              x: [0.515],
              y: [0.955],
            },
            o: {
              x: [0.455],
              y: [0.03],
            },
          }, {
            t: 24,
          }],
        },
        or: {
          a: 0,
          k: [0, 0, 0],
        },
        a: {
          a: 0,
          k: [16.5, 12, 0],
        },
        p: {
          s: true,
          x: {
            a: 0,
            k: 67.2080078125,
          },
          y: {
            a: 0,
            k: 64.622,
          },
        },
        rx: {
          a: 0,
          k: 0,
        },
        ry: {
          a: 0,
          k: 0,
        },
        rz: {
          a: 0,
          k: 0,
        },
        s: {
          a: 1,
          k: [{
            t: 0,
            s: [14.6, 14.6],
            e: [96, 96],
            i: {
              x: [0.515, 0.515],
              y: [0.955, 0.955],
            },
            o: {
              x: [0.455, 0.455],
              y: [0.03, 0.03],
            },
          }, {
            t: 24,
          }],
        },
      },
      shapes: [{
        ty: 'gr',
        nm: 'check-mark shape group',
        it: [{
          ty: 'sh',
          ks: {
            a: 0,
            k: {
              c: true,
              v: [
                [18, 25.6129791],
                [15.1715729, 28.4414062],
                [27.0334473, 40.3032806],
                [47.3367279, 20],
                [44.5083008, 17.1715729],
                [27.0334473, 34.6464264],
              ],
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
            },
          },
        }, {
          ty: 'st',
          o: {
            a: 0,
            k: 0,
          },
          w: {
            a: 0,
            k: 0,
          },
          c: {
            a: 0,
            k: [0, 0, 0, 0],
          },
          lc: 3,
          lj: 1,
          ml: 1,
        }, {
          ty: 'fl',
          o: {
            a: 0,
            k: 100,
          },
          r: 1,
          c: {
            a: 0,
            k: [1, 1, 1, 1],
          },
        }, {
          ty: 'tr',
          o: {
            a: 0,
            k: 100,
          },
          a: {
            a: 0,
            k: [0, 0],
          },
          s: {
            a: 0,
            k: [100, 100],
          },
          p: {
            a: 0,
            k: [-15, -17],
          },
          r: {
            a: 0,
            k: 0,
          },
        }],
      }],
      op: 42,
    }, {
      ty: 4,
      nm: 'check-circle',
      ip: 0,
      st: 0,
      ind: 9,
      hix: 10,
      ks: {
        o: {
          a: 0,
          k: 100,
        },
        or: {
          a: 0,
          k: [0, 0, 0],
        },
        a: {
          a: 0,
          k: [26.5, 26.5, 0],
        },
        p: {
          s: true,
          x: {
            a: 0,
            k: 67.48,
          },
          y: {
            a: 0,
            k: 63.6215,
          },
        },
        rx: {
          a: 0,
          k: 0,
        },
        ry: {
          a: 0,
          k: 0,
        },
        rz: {
          a: 0,
          k: 0,
        },
        s: {
          a: 1,
          k: [{
            t: 0,
            s: [0, 0],
            e: [100, 100],
            i: {
              x: [0.65, 0.65],
              y: [1.01, 1.01],
            },
            o: {
              x: [0.23, 0.23],
              y: [1.62, 1.62],
            },
          }, {
            t: 24,
          }],
        },
      },
      shapes: [{
        ty: 'gr',
        nm: 'check-circle shape group',
        it: [{
          ty: 'el',
          p: {
            a: 0,
            k: [31, 30],
          },
          s: {
            a: 0,
            k: [53, 53],
          },
        }, {
          ty: 'st',
          o: {
            a: 0,
            k: 0,
          },
          w: {
            a: 0,
            k: 0,
          },
          c: {
            a: 0,
            k: [0, 0, 0, 0],
          },
          lc: 3,
          lj: 1,
          ml: 1,
        }, {
          ty: 'fl',
          o: {
            a: 0,
            k: 100,
          },
          r: 1,
          c: {
            a: 0,
            k: [0.996078431372549, 0.8431372549019608, 0.23137254901960785, 1],
          },
        }, {
          ty: 'tr',
          o: {
            a: 0,
            k: 100,
          },
          a: {
            a: 0,
            k: [0, 0],
          },
          s: {
            a: 0,
            k: [100, 100],
          },
          p: {
            a: 0,
            k: [-5, -4],
          },
          r: {
            a: 0,
            k: 0,
          },
        }],
      }],
      op: 42,
    }, {
      ty: 4,
      nm: 'sparkles-7',
      ip: 0,
      st: 0,
      ind: 8,
      hix: 3,
      ks: {
        o: {
          a: 1,
          k: [{
            t: 0,
            s: [0],
            e: [100],
            i: {
              x: [0.515],
              y: [0.955],
            },
            o: {
              x: [0.455],
              y: [0.03],
            },
          }, {
            t: 24,
          }],
        },
        or: {
          a: 0,
          k: [0, 0, 0],
        },
        a: {
          a: 0,
          k: [5, 5, 0],
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [{
              t: 0,
              s: [67.18799999999997],
              e: [36.61257896015442],
              i: {
                x: [0.65],
                y: [1.01],
              },
              o: {
                x: [0.23],
                y: [1.62],
              },
            }, {
              t: 42,
            }],
          },
          y: {
            a: 1,
            k: [{
              t: 0,
              s: [67.79149999999998],
              e: [25.164413837697136],
              i: {
                x: [0.65],
                y: [1.01],
              },
              o: {
                x: [0.23],
                y: [1.62],
              },
            }, {
              t: 42,
            }],
          },
        },
        rx: {
          a: 0,
          k: 0,
        },
        ry: {
          a: 0,
          k: 0,
        },
        rz: {
          a: 1,
          k: [{
            t: 0,
            s: [0],
            e: [-84.05290854569176],
            i: {
              x: [0.65],
              y: [1.01],
            },
            o: {
              x: [0.23],
              y: [1.62],
            },
          }, {
            t: 42,
          }],
        },
        s: {
          a: 1,
          k: [{
            t: 0,
            s: [117, 117],
            e: [79.9, 79.9],
            i: {
              x: [0.65, 0.65],
              y: [1.01, 1.01],
            },
            o: {
              x: [0.23, 0.23],
              y: [1.62, 1.62],
            },
          }, {
            t: 42,
          }],
        },
      },
      shapes: [{
        ty: 'gr',
        nm: 'sparkles-7 shape group',
        it: [{
          ty: 'sh',
          ks: {
            a: 0,
            k: {
              c: true,
              v: [
                [40.9496385, 16.0000907],
                [39.9661291, 16.419252],
                [36.4147663, 19.9778517],
                [36.4147663, 21.9882313],
                [39.9661291, 25.5465463],
                [41.9724881, 25.5465463],
                [45.5238511, 21.9882313],
                [45.5238511, 19.9778517],
                [41.9724881, 16.419252],
                [40.9496385, 16.0000907],
              ],
              i: [
                [0, 0],
                [0.2608819999999952, -0.26267719999999883],
                [0, 0],
                [-0.5530216999999951, -0.5555740999999976],
                [0, 0],
                [-0.5544639000000018, 0.5541249000000015],
                [0, 0],
                [0.5530217999999962, 0.5555742000000023],
                [0, 0],
                [0.3834356999999997, -0.0043338000000012755],
              ],
              o: [
                [-0.36979209999999796, 0.0061093999999997095],
                [0, 0],
                [-0.5530216999999951, 0.5555742000000023],
                [0, 0],
                [0.5544638999999947, 0.5541249000000015],
                [0, 0],
                [0.5530217999999962, -0.5555740999999976],
                [0, 0],
                [-0.27051540000000074, -0.27232139999999916],
                [0, 0],
              ],
            },
          },
        }, {
          ty: 'sh',
          ks: {
            a: 0,
            k: {
              c: true,
              v: [
                [40.9710977, 19.4365299],
                [42.5125251, 20.9810482],
                [40.9710977, 22.5292683],
                [39.4260938, 20.9810482],
                [40.9710977, 19.4365299],
              ],
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
            },
          },
        }, {
          ty: 'st',
          o: {
            a: 0,
            k: 0,
          },
          w: {
            a: 0,
            k: 0,
          },
          c: {
            a: 0,
            k: [0, 0, 0, 0],
          },
          lc: 3,
          lj: 1,
          ml: 1,
        }, {
          ty: 'fl',
          o: {
            a: 0,
            k: 100,
          },
          r: 2,
          c: {
            a: 0,
            k: [0.792156862745098, 0.7568627450980392, 0.6039215686274509, 1],
          },
        }, {
          ty: 'tr',
          o: {
            a: 0,
            k: 100,
          },
          a: {
            a: 0,
            k: [0, 0],
          },
          s: {
            a: 0,
            k: [100, 100],
          },
          p: {
            a: 0,
            k: [-36, -16],
          },
          r: {
            a: 0,
            k: 0,
          },
        }],
      }],
      op: 42,
    }, {
      ty: 4,
      nm: 'sparkles-6',
      ip: 0,
      st: 0,
      ind: 7,
      hix: 4,
      ks: {
        o: {
          a: 1,
          k: [{
            t: 0,
            s: [0],
            e: [100],
            i: {
              x: [0.515],
              y: [0.955],
            },
            o: {
              x: [0.455],
              y: [0.03],
            },
          }, {
            t: 24,
          }],
        },
        or: {
          a: 0,
          k: [0, 0, 0],
        },
        a: {
          a: 0,
          k: [6, 6.5, 0],
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [{
              t: 0,
              s: [67.18799999999997],
              e: [99.17026711099871],
              i: {
                x: [0.65],
                y: [1.01],
              },
              o: {
                x: [0.23],
                y: [1.62],
              },
            }, {
              t: 42,
            }],
          },
          y: {
            a: 1,
            k: [{
              t: 0,
              s: [67.79150000000001],
              e: [104.04103087693385],
              i: {
                x: [0.65],
                y: [1.01],
              },
              o: {
                x: [0.23],
                y: [1.62],
              },
            }, {
              t: 42,
            }],
          },
        },
        rx: {
          a: 0,
          k: 0,
        },
        ry: {
          a: 0,
          k: 0,
        },
        rz: {
          a: 1,
          k: [{
            t: 0,
            s: [0],
            e: [-516.7506354284895],
            i: {
              x: [0.65],
              y: [1.01],
            },
            o: {
              x: [0.23],
              y: [1.62],
            },
          }, {
            t: 42,
          }],
        },
        s: {
          a: 1,
          k: [{
            t: 0,
            s: [117, 117],
            e: [64, 64],
            i: {
              x: [0.65, 0.65],
              y: [1.01, 1.01],
            },
            o: {
              x: [0.23, 0.23],
              y: [1.62, 1.62],
            },
          }, {
            t: 42,
          }],
        },
      },
      shapes: [{
        ty: 'gr',
        nm: 'sparkles-6 shape group',
        it: [{
          ty: 'sh',
          ks: {
            a: 0,
            k: {
              c: true,
              v: [
                [11.9020922, 24.0001107],
                [10.7293271, 24.5120512],
                [6.4945794, 28.8583278],
                [6.4945794, 31.3136941],
                [10.7293271, 35.659623],
                [13.1217678, 35.659623],
                [17.3565158, 31.3136941],
                [17.3565158, 28.8583278],
                [13.1217678, 24.5120512],
                [11.9020922, 24.0001107],
              ],
              i: [
                [0, 0],
                [0.31108329999999995, -0.32081939999999776],
                [0, 0],
                [-0.6594392000000004, -0.6785475000000005],
                [0, 0],
                [-0.6611589000000002, 0.6767775],
                [0, 0],
                [0.6594392000000013, 0.678547499999997],
                [0, 0],
                [0.45721979999999895, -0.0052930000000017685],
              ],
              o: [
                [-0.44095079999999953, 0.0074617999999979645],
                [0, 0],
                [-0.6594392000000004, 0.678547499999997],
                [0, 0],
                [0.6611588999999984, 0.6767775],
                [0, 0],
                [0.6594392000000013, -0.6785475000000005],
                [0, 0],
                [-0.3225705000000012, -0.33259829999999724],
                [0, 0],
              ],
            },
          },
        }, {
          ty: 'sh',
          ks: {
            a: 0,
            k: {
              c: true,
              v: [
                [11.9276808, 28.1971874],
                [13.7657234, 30.0835765],
                [11.9276808, 31.9744868],
                [10.0853735, 30.0835765],
                [11.9276808, 28.1971874],
              ],
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
            },
          },
        }, {
          ty: 'st',
          o: {
            a: 0,
            k: 0,
          },
          w: {
            a: 0,
            k: 0,
          },
          c: {
            a: 0,
            k: [0, 0, 0, 0],
          },
          lc: 3,
          lj: 1,
          ml: 1,
        }, {
          ty: 'fl',
          o: {
            a: 0,
            k: 100,
          },
          r: 2,
          c: {
            a: 0,
            k: [0.792156862745098, 0.7568627450980392, 0.6039215686274509, 1],
          },
        }, {
          ty: 'tr',
          o: {
            a: 0,
            k: 100,
          },
          a: {
            a: 0,
            k: [0, 0],
          },
          s: {
            a: 0,
            k: [100, 100],
          },
          p: {
            a: 0,
            k: [-6, -24],
          },
          r: {
            a: 0,
            k: 0,
          },
        }],
      }],
      op: 42,
    }, {
      ty: 4,
      nm: 'sparkles-5',
      ip: 0,
      st: 0,
      ind: 6,
      hix: 5,
      ks: {
        o: {
          a: 1,
          k: [{
            t: 0,
            s: [0],
            e: [100],
            i: {
              x: [0.515],
              y: [0.955],
            },
            o: {
              x: [0.455],
              y: [0.03],
            },
          }, {
            t: 24,
          }],
        },
        or: {
          a: 0,
          k: [0, 0, 0],
        },
        a: {
          a: 0,
          k: [5.5, 5.5, 0],
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [{
              t: 0,
              s: [67.18799999999997],
              e: [113.00048828125],
              i: {
                x: [0.65],
                y: [1.01],
              },
              o: {
                x: [0.23],
                y: [1.62],
              },
            }, {
              t: 42,
            }],
          },
          y: {
            a: 1,
            k: [{
              t: 0,
              s: [67.79150000000001],
              e: [57.709001953125],
              i: {
                x: [0.65],
                y: [1.01],
              },
              o: {
                x: [0.23],
                y: [1.62],
              },
            }, {
              t: 42,
            }],
          },
        },
        rx: {
          a: 0,
          k: 0,
        },
        ry: {
          a: 0,
          k: 0,
        },
        rz: {
          a: 0,
          k: 0,
        },
        s: {
          a: 1,
          k: [{
            t: 0,
            s: [117, 117],
            e: [57.4, 57.4],
            i: {
              x: [0.65, 0.65],
              y: [1.01, 1.01],
            },
            o: {
              x: [0.23, 0.23],
              y: [1.62, 1.62],
            },
          }, {
            t: 42,
          }],
        },
      },
      shapes: [{
        ty: 'gr',
        nm: 'sparkles-5 shape group',
        it: [{
          ty: 'sh',
          ks: {
            a: 0,
            k: {
              c: true,
              v: [
                [19.0790408, 6],
                [14, 11.2146038],
                [19.0790408, 16.4290338],
                [24.1580816, 11.2146038],
                [19.0790408, 6],
              ],
              i: [
                [0, 0],
                [0, -2.8595036400000016],
                [-2.7850236000000024, 0],
                [0, 2.8591559999999987],
                [2.785023599999999, 0],
              ],
              o: [
                [-2.7850236000000024, 0],
                [0, 2.8591559999999987],
                [2.785023599999999, 0],
                [0, -2.8595036400000016],
                [0, 0],
              ],
            },
          },
        }, {
          ty: 'sh',
          ks: {
            a: 0,
            k: {
              c: true,
              v: [
                [19.0790408, 9.47622868],
                [20.7720538, 11.2146038],
                [19.0790408, 12.9526312],
                [17.3860278, 11.2146038],
                [19.0790408, 9.47622868],
              ],
              i: [
                [0, 0],
                [0, -0.9806397000000011],
                [0.9550780000000003, 0],
                [0, 0.9806396999999993],
                [-0.9550780000000003, 0],
              ],
              o: [
                [0.9550780000000003, 0],
                [0, 0.9806396999999993],
                [-0.9550780000000003, 0],
                [0, -0.9806397000000011],
                [0, 0],
              ],
            },
          },
        }, {
          ty: 'st',
          o: {
            a: 0,
            k: 0,
          },
          w: {
            a: 0,
            k: 0,
          },
          c: {
            a: 0,
            k: [0, 0, 0, 0],
          },
          lc: 3,
          lj: 1,
          ml: 1,
        }, {
          ty: 'fl',
          o: {
            a: 0,
            k: 100,
          },
          r: 2,
          c: {
            a: 0,
            k: [0.792156862745098, 0.7568627450980392, 0.6039215686274509, 1],
          },
        }, {
          ty: 'tr',
          o: {
            a: 0,
            k: 100,
          },
          a: {
            a: 0,
            k: [0, 0],
          },
          s: {
            a: 0,
            k: [100, 100],
          },
          p: {
            a: 0,
            k: [-14, -6],
          },
          r: {
            a: 0,
            k: 0,
          },
        }],
      }],
      op: 42,
    }, {
      ty: 4,
      nm: 'sparkles-4',
      ip: 0,
      st: 0,
      ind: 5,
      hix: 6,
      ks: {
        o: {
          a: 1,
          k: [{
            t: 0,
            s: [0],
            e: [100],
            i: {
              x: [0.515],
              y: [0.955],
            },
            o: {
              x: [0.455],
              y: [0.03],
            },
          }, {
            t: 24,
          }],
        },
        or: {
          a: 0,
          k: [0, 0, 0],
        },
        a: {
          a: 0,
          k: [5.5, 5.5, 0],
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [{
              t: 0,
              s: [67.18799999999997],
              e: [17.159],
              i: {
                x: [0.65],
                y: [1.01],
              },
              o: {
                x: [0.23],
                y: [1.62],
              },
            }, {
              t: 42,
            }],
          },
          y: {
            a: 1,
            k: [{
              t: 0,
              s: [67.79150000000001],
              e: [56.846],
              i: {
                x: [0.65],
                y: [1.01],
              },
              o: {
                x: [0.23],
                y: [1.62],
              },
            }, {
              t: 42,
            }],
          },
        },
        rx: {
          a: 0,
          k: 0,
        },
        ry: {
          a: 0,
          k: 0,
        },
        rz: {
          a: 0,
          k: 0,
        },
        s: {
          a: 1,
          k: [{
            t: 0,
            s: [117, 117],
            e: [39.5, 39.5],
            i: {
              x: [0.65, 0.65],
              y: [1.01, 1.01],
            },
            o: {
              x: [0.23, 0.23],
              y: [1.62, 1.62],
            },
          }, {
            t: 42,
          }],
        },
      },
      shapes: [{
        ty: 'gr',
        nm: 'sparkles-4 shape group',
        it: [{
          ty: 'sh',
          ks: {
            a: 0,
            k: {
              c: true,
              v: [
                [27.0790408, 13],
                [22, 18.2146038],
                [27.0790408, 23.4290338],
                [32.1580816, 18.2146038],
                [27.0790408, 13],
              ],
              i: [
                [0, 0],
                [0, -2.8595035999999983],
                [-2.7850236000000024, 0],
                [0, 2.8591560000000023],
                [2.785023599999999, 0],
              ],
              o: [
                [-2.7850236000000024, 0],
                [0, 2.8591560000000023],
                [2.785023599999999, 0],
                [0, -2.8595035999999983],
                [0, 0],
              ],
            },
          },
        }, {
          ty: 'sh',
          ks: {
            a: 0,
            k: {
              c: true,
              v: [
                [27.0790408, 16.4762287],
                [28.7720538, 18.2146038],
                [27.0790408, 19.9526312],
                [25.3860278, 18.2146038],
                [27.0790408, 16.4762287],
              ],
              i: [
                [0, 0],
                [0, -0.9806396999999976],
                [0.9550780000000003, 0],
                [0, 0.9806397000000011],
                [-0.9550780000000003, 0],
              ],
              o: [
                [0.9550780000000003, 0],
                [0, 0.9806397000000011],
                [-0.9550780000000003, 0],
                [0, -0.9806396999999976],
                [0, 0],
              ],
            },
          },
        }, {
          ty: 'st',
          o: {
            a: 0,
            k: 0,
          },
          w: {
            a: 0,
            k: 0,
          },
          c: {
            a: 0,
            k: [0, 0, 0, 0],
          },
          lc: 3,
          lj: 1,
          ml: 1,
        }, {
          ty: 'fl',
          o: {
            a: 0,
            k: 100,
          },
          r: 2,
          c: {
            a: 0,
            k: [0.792156862745098, 0.7568627450980392, 0.6039215686274509, 1],
          },
        }, {
          ty: 'tr',
          o: {
            a: 0,
            k: 100,
          },
          a: {
            a: 0,
            k: [0, 0],
          },
          s: {
            a: 0,
            k: [100, 100],
          },
          p: {
            a: 0,
            k: [-22, -13],
          },
          r: {
            a: 0,
            k: 0,
          },
        }],
      }],
      op: 42,
    }, {
      ty: 4,
      nm: 'sparkles-3',
      ip: 0,
      st: 0,
      ind: 4,
      hix: 7,
      ks: {
        o: {
          a: 1,
          k: [{
            t: 0,
            s: [0],
            e: [100],
            i: {
              x: [0.515],
              y: [0.955],
            },
            o: {
              x: [0.455],
              y: [0.03],
            },
          }, {
            t: 24,
          }],
        },
        or: {
          a: 0,
          k: [0, 0, 0],
        },
        a: {
          a: 0,
          k: [6, 6, 0],
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [{
              t: 0,
              s: [67.18799999999997],
              e: [67.18799999999997],
              i: {
                x: [0.515],
                y: [0.955],
              },
              o: {
                x: [0.455],
                y: [0.03],
              },
            }, {
              t: 24,
              s: [67.18799999999997],
              e: [30.21184837966107],
              i: {
                x: [0.65],
                y: [1.01],
              },
              o: {
                x: [0.23],
                y: [1.62],
              },
            }, {
              t: 42,
            }],
          },
          y: {
            a: 1,
            k: [{
              t: 0,
              s: [67.79150000000001],
              e: [67.79150000000001],
              i: {
                x: [0.515],
                y: [0.955],
              },
              o: {
                x: [0.455],
                y: [0.03],
              },
            }, {
              t: 24,
              s: [67.79150000000001],
              e: [91.65618836257104],
              i: {
                x: [0.65],
                y: [1.01],
              },
              o: {
                x: [0.23],
                y: [1.62],
              },
            }, {
              t: 42,
            }],
          },
        },
        rx: {
          a: 0,
          k: 0,
        },
        ry: {
          a: 0,
          k: 0,
        },
        rz: {
          a: 1,
          k: [{
            t: 0,
            s: [0],
            e: [-382.793102926903],
            i: {
              x: [0.65],
              y: [1.01],
            },
            o: {
              x: [0.23],
              y: [1.62],
            },
          }, {
            t: 42,
          }],
        },
        s: {
          a: 1,
          k: [{
            t: 0,
            s: [117, 117],
            e: [61.6, 62.1],
            i: {
              x: [0.65, 0.65],
              y: [1.01, 1.01],
            },
            o: {
              x: [0.23, 0.23],
              y: [1.62, 1.62],
            },
          }, {
            t: 42,
          }],
        },
      },
      shapes: [{
        ty: 'gr',
        nm: 'sparkles-3 shape group',
        it: [{
          ty: 'sh',
          ks: {
            a: 0,
            k: {
              c: true,
              v: [
                [27.1820633, 24.0000004],
                [26.1024778, 24.4903764],
                [25.7680091, 25.6242639],
                [25.771292, 25.64133],
                [26.1581565, 28.178913],
                [23.6101894, 27.7937886],
                [23.5935177, 27.7937886],
                [22.017544, 28.9849731],
                [23.1499568, 30.6007311],
                [23.1666284, 30.6007311],
                [23.2133177, 30.6007311],
                [25.707924, 31.0091791],
                [24.607363, 33.1981875],
                [24.5506679, 33.3048505],
                [24.5439593, 33.3219166],
                [25.1703749, 35.2298051],
                [27.0885934, 34.6141028],
                [27.095302, 34.5970367],
                [28.2792385, 32.3184314],
                [29.9867771, 33.9958843],
                [30.113499, 34.1224577],
                [30.1267736, 34.1366794],
                [32.1116608, 34.103824],
                [32.1444698, 32.1268647],
                [32.1311952, 32.112643],
                [30.3202693, 30.289133],
                [32.4146709, 29.2095616],
                [32.6114396, 29.1100095],
                [32.6114396, 29.1100095],
                [32.6247142, 29.1100095],
                [33.2020743, 27.2210442],
                [31.3273846, 26.5788263],
                [31.31411, 26.5788263],
                [31.3108271, 26.5788263],
                [29.0096538, 27.7314974],
                [28.5994433, 25.2434061],
                [28.5927346, 25.1964744],
                [28.5894517, 25.1794083],
                [27.1820662, 24.0001426],
              ],
              i: [
                [0, 0],
                [0.27138360000000006, -0.3117266999999977],
                [-0.058965700000001675, -0.4083943999999988],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0.11915249999999844, -0.7540207000000017],
                [-0.7517145999999997, -0.1485365999999999],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [-0.7005520999999995, -0.3557460999999975],
                [-0.3601826000000017, 0.6962165000000056],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [-0.5444561000000014, 0.5423294999999939],
                [0.5261629999999968, 0.5599553999999998],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0.3494458999999992, 0.6817940999999976],
                [0.6965846000000049, -0.3234522999999996],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0.6947289999999988, -0.0003607999999992728],
              ],
              o: [
                [-0.4142507999999978, -0.0002936000000026695],
                [-0.2713834999999989, 0.31172670000000124],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [-0.7611813000000005, -0.08862889999999979],
                [-0.11915250000000199, 0.7540206000000005],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [-0.35415740000000184, 0.6992761999999999],
                [0.7005520000000018, 0.3557460999999975],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0.5622490000000013, 0.5240104000000017],
                [0.5444561999999991, -0.5423293999999999],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0.6715985000000018, -0.3722019000000003],
                [-0.3494458999999992, -0.681794],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [-0.11853729999999985, -0.6818066999999992],
                [0, 0],
              ],
            },
          },
        }, {
          ty: 'st',
          o: {
            a: 0,
            k: 0,
          },
          w: {
            a: 0,
            k: 0,
          },
          c: {
            a: 0,
            k: [0, 0, 0, 0],
          },
          lc: 3,
          lj: 1,
          ml: 1,
        }, {
          ty: 'fl',
          o: {
            a: 0,
            k: 100,
          },
          r: 2,
          c: {
            a: 0,
            k: [0.996078431372549, 0.8431372549019608, 0.23137254901960785, 1],
          },
        }, {
          ty: 'tr',
          o: {
            a: 0,
            k: 100,
          },
          a: {
            a: 0,
            k: [0, 0],
          },
          s: {
            a: 0,
            k: [100, 100],
          },
          p: {
            a: 0,
            k: [-22, -24],
          },
          r: {
            a: 0,
            k: 0,
          },
        }],
      }],
      op: 42,
    }, {
      ty: 4,
      nm: 'sparkles-1',
      ip: 0,
      st: 0,
      ind: 3,
      hix: 9,
      ks: {
        o: {
          a: 1,
          k: [{
            t: 0,
            s: [0],
            e: [100],
            i: {
              x: [0.515],
              y: [0.955],
            },
            o: {
              x: [0.455],
              y: [0.03],
            },
          }, {
            t: 24,
          }],
        },
        or: {
          a: 0,
          k: [0, 0, 0],
        },
        a: {
          a: 0,
          k: [6, 6, 0],
        },
        p: {
          s: true,
          x: {
            a: 0,
            k: 0,
          },
          y: {
            a: 0,
            k: 0,
          },
        },
        rx: {
          a: 0,
          k: 0,
        },
        ry: {
          a: 0,
          k: 0,
        },
        rz: {
          a: 0,
          k: -128.3425461093044,
        },
        s: {
          a: 0,
          k: [0, 100],
        },
      },
      shapes: [{
        ty: 'gr',
        nm: 'sparkles-1 shape group',
        it: [{
          ty: 'sh',
          ks: {
            a: 0,
            k: {
              c: true,
              v: [
                [5.18206331, 10.0000004],
                [4.10247785, 10.4903764],
                [3.7680091, 11.6242639],
                [3.77129205, 11.64133],
                [4.15815646, 14.178913],
                [1.61018942, 13.7937886],
                [1.59351774, 13.7937886],
                [0.0175440154, 14.9849731],
                [1.14995675, 16.6007311],
                [1.16662844, 16.6007311],
                [1.21331771, 16.6007311],
                [3.70792395, 17.0091791],
                [2.60736305, 19.1981875],
                [2.55066791, 19.3048505],
                [2.54395927, 19.3219166],
                [3.17037487, 21.2298051],
                [5.0885934, 20.6141028],
                [5.09530204, 20.5970367],
                [6.27923849, 18.3184314],
                [7.98677712, 19.9958843],
                [8.11349905, 20.1224577],
                [8.12677359, 20.1366794],
                [10.1116608, 20.103824],
                [10.1444698, 18.1268647],
                [10.1311952, 18.112643],
                [8.32026931, 16.289133],
                [10.4146709, 15.2095616],
                [10.6114396, 15.1100095],
                [10.6114396, 15.1100095],
                [10.6247142, 15.1100095],
                [11.2020743, 13.2210442],
                [9.32738457, 12.5788263],
                [9.31411003, 12.5788263],
                [9.31082708, 12.5788263],
                [7.00965376, 13.7314974],
                [6.59944329, 11.2434061],
                [6.59273465, 11.1964744],
                [6.58945169, 11.1794083],
                [5.18206616, 10.0001426],
              ],
              i: [
                [0, 0],
                [0.2713835800000002, -0.31172670000000124],
                [-0.05896568000000002, -0.4083944000000006],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0.1191524706, -0.7540206999999999],
                [-0.7517145510000001, -0.1485365999999999],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [-0.7005520199999999, -0.35574610000000106],
                [-0.3601825700000001, 0.6962164999999985],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [-0.5444561399999994, 0.542329500000001],
                [0.5261630000000004, 0.5599553999999998],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0.349445900000001, 0.6817941000000012],
                [0.6965846300000003, -0.3234522999999996],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0.6947290800000001, -0.00036077999999939436],
              ],
              o: [
                [-0.4142508100000004, -0.00029361999999899524],
                [-0.27138357999999974, 0.31172669999999947],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [-0.761181344, -0.08862889999999979],
                [-0.1191524714, 0.7540206000000005],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [-0.35415734000000043, 0.6992761999999999],
                [0.7005520199999999, 0.35574610000000106],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0.5622489599999998, 0.5240104000000017],
                [0.5444562000000008, -0.5423293999999999],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0.6715985, -0.3722019000000003],
                [-0.3494458999999992, -0.681794],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [-0.11853725000000015, -0.681806700000001],
                [0, 0],
              ],
            },
          },
        }, {
          ty: 'st',
          o: {
            a: 0,
            k: 0,
          },
          w: {
            a: 0,
            k: 0,
          },
          c: {
            a: 0,
            k: [0, 0, 0, 0],
          },
          lc: 3,
          lj: 1,
          ml: 1,
        }, {
          ty: 'fl',
          o: {
            a: 0,
            k: 100,
          },
          r: 2,
          c: {
            a: 0,
            k: [0.996078431372549, 0.8431372549019608, 0.23137254901960785, 1],
          },
        }, {
          ty: 'tr',
          o: {
            a: 0,
            k: 100,
          },
          a: {
            a: 0,
            k: [0, 0],
          },
          s: {
            a: 0,
            k: [100, 100],
          },
          p: {
            a: 0,
            k: [0, -10],
          },
          r: {
            a: 0,
            k: 0,
          },
        }],
      }],
      op: 42,
    }, {
      ty: 4,
      nm: 'sparkles-2',
      ip: 0,
      st: 0,
      ind: 2,
      hix: 8,
      ks: {
        o: {
          a: 1,
          k: [{
            t: 0,
            s: [0],
            e: [100],
            i: {
              x: [0.515],
              y: [0.955],
            },
            o: {
              x: [0.455],
              y: [0.03],
            },
          }, {
            t: 24,
          }],
        },
        or: {
          a: 0,
          k: [0, 0, 0],
        },
        a: {
          a: 0,
          k: [6, 6, 0],
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [{
              t: 0,
              s: [67.18799999999997],
              e: [67.18799999999997],
              i: {
                x: [0.65],
                y: [1.01],
              },
              o: {
                x: [0.23],
                y: [1.62],
              },
            }, {
              t: 24,
              s: [67.18799999999997],
              e: [96.7589111328125],
              i: {
                x: [0.65],
                y: [1.01],
              },
              o: {
                x: [0.23],
                y: [1.62],
              },
            }, {
              t: 42,
            }],
          },
          y: {
            a: 1,
            k: [{
              t: 0,
              s: [67.79150000000001],
              e: [67.79150000000001],
              i: {
                x: [0.65],
                y: [1.01],
              },
              o: {
                x: [0.23],
                y: [1.62],
              },
            }, {
              t: 24,
              s: [67.79150000000001],
              e: [20.89849853515625],
              i: {
                x: [0.65],
                y: [1.01],
              },
              o: {
                x: [0.23],
                y: [1.62],
              },
            }, {
              t: 42,
            }],
          },
        },
        rx: {
          a: 0,
          k: 0,
        },
        ry: {
          a: 0,
          k: 0,
        },
        rz: {
          a: 1,
          k: [{
            t: 0,
            s: [0],
            e: [0],
            i: {
              x: [0.65],
              y: [1.01],
            },
            o: {
              x: [0.23],
              y: [1.62],
            },
          }, {
            t: 24,
            s: [0],
            e: [611.8616294202061],
            i: {
              x: [0.65],
              y: [1.01],
            },
            o: {
              x: [0.23],
              y: [1.62],
            },
          }, {
            t: 42,
          }],
        },
        s: {
          a: 1,
          k: [{
            t: 0,
            s: [0, 0],
            e: [100, 100],
            i: {
              x: [0.65, 0.65],
              y: [1.01, 1.01],
            },
            o: {
              x: [0.23, 0.23],
              y: [1.62, 1.62],
            },
          }, {
            t: 23,
            s: [100, 100],
            e: [100, 100],
            i: {
              x: [0.65, 0.65],
              y: [1.01, 1.01],
            },
            o: {
              x: [0.23, 0.23],
              y: [1.62, 1.62],
            },
          }, {
            t: 42,
          }],
        },
      },
      shapes: [{
        ty: 'gr',
        nm: 'sparkles-2 shape group',
        it: [{
          ty: 'sh',
          ks: {
            a: 0,
            k: {
              c: true,
              v: [
                [29.1820633, 3.60685727e-7],
                [28.1024778, 0.490376402],
                [27.7680091, 1.62426394],
                [27.771292, 1.64133002],
                [28.1581565, 4.17891299],
                [25.6101894, 3.79378859],
                [25.5935177, 3.79378859],
                [24.017544, 4.98497307],
                [25.1499568, 6.60073109],
                [25.1666284, 6.60073109],
                [25.2133177, 6.60073109],
                [27.707924, 7.00917913],
                [26.607363, 9.19818754],
                [26.5506679, 9.3048505],
                [26.5439593, 9.32191657],
                [27.1703749, 11.2298051],
                [29.0885934, 10.6141028],
                [29.095302, 10.5970367],
                [30.2792385, 8.31843143],
                [31.9867771, 9.99588427],
                [32.113499, 10.1224577],
                [32.1267736, 10.1366794],
                [34.1116608, 10.103824],
                [34.1444698, 8.12686475],
                [34.1311952, 8.11264302],
                [32.3202693, 6.28913302],
                [34.4146709, 5.20956163],
                [34.6114396, 5.11000954],
                [34.6114396, 5.11000954],
                [34.6247142, 5.11000954],
                [35.2020743, 3.22104421],
                [33.3273846, 2.57882634],
                [33.31411, 2.57882634],
                [33.3108271, 2.57882634],
                [31.0096538, 3.73149742],
                [30.5994433, 1.24340606],
                [30.5927346, 1.19647436],
                [30.5894517, 1.17940828],
                [29.1820662, 0.000142577969],
              ],
              i: [
                [0, 0],
                [0.27138360000000006, -0.311726712],
                [-0.058965700000001675, -0.40839440000000016],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0.11915249999999844, -0.7540206399999994],
                [-0.7517145999999997, -0.1485365500000002],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [-0.7005520999999995, -0.3557460999999993],
                [-0.3601826000000017, 0.6962165000000002],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [-0.544456100000005, 0.542329500000001],
                [0.5261629999999968, 0.559955350000001],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0.3494458999999992, 0.6817940499999997],
                [0.6965846000000013, -0.32345235000000017],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0.6947289999999988, -0.000360758155],
              ],
              o: [
                [-0.4142507999999978, -0.000293583593727],
                [-0.2713834999999989, 0.311726712],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [-0.7611813000000005, -0.08862885000000009],
                [-0.11915250000000199, 0.7540206400000002],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [-0.35415740000000184, 0.6992762299999988],
                [0.7005520000000018, 0.3557460999999993],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0.5622490000000013, 0.5240103999999999],
                [0.5444561999999991, -0.5423294199999997],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0.6715985000000018, -0.3722019300000001],
                [-0.3494458999999992, -0.6817940600000001],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
                [-0.11853729999999985, -0.681806651],
                [0, 0],
              ],
            },
          },
        }, {
          ty: 'st',
          o: {
            a: 0,
            k: 0,
          },
          w: {
            a: 0,
            k: 0,
          },
          c: {
            a: 0,
            k: [0, 0, 0, 0],
          },
          lc: 3,
          lj: 1,
          ml: 1,
        }, {
          ty: 'fl',
          o: {
            a: 0,
            k: 100,
          },
          r: 2,
          c: {
            a: 0,
            k: [0.996078431372549, 0.8431372549019608, 0.23137254901960785, 1],
          },
        }, {
          ty: 'tr',
          o: {
            a: 0,
            k: 100,
          },
          a: {
            a: 0,
            k: [0, 0],
          },
          s: {
            a: 0,
            k: [100, 100],
          },
          p: {
            a: 0,
            k: [-24, 0],
          },
          r: {
            a: 0,
            k: 0,
          },
        }],
      }],
      op: 42,
    }, {
      ty: 4,
      nm: 'ring-01',
      ip: 0,
      st: 0,
      ind: 1,
      hix: 2,
      ks: {
        o: {
          a: 1,
          k: [{
            t: 0,
            s: [0],
            e: [0],
            i: {
              x: [1],
              y: [1],
            },
            o: {
              x: [0],
              y: [0],
            },
          }, {
            t: 8,
            s: [0],
            e: [100],
            i: {
              x: [0.515],
              y: [0.955],
            },
            o: {
              x: [0.455],
              y: [0.03],
            },
          }, {
            t: 10,
            s: [100],
            e: [0],
            i: {
              x: [0.515],
              y: [0.955],
            },
            o: {
              x: [0.455],
              y: [0.03],
            },
          }, {
            t: 24,
          }],
        },
        or: {
          a: 0,
          k: [0, 0, 0],
        },
        a: {
          a: 0,
          k: [26.5, 26.5, 0],
        },
        p: {
          s: true,
          x: {
            a: 0,
            k: 67.48,
          },
          y: {
            a: 0,
            k: 64,
          },
        },
        rx: {
          a: 0,
          k: 0,
        },
        ry: {
          a: 0,
          k: 0,
        },
        rz: {
          a: 0,
          k: 0,
        },
        s: {
          a: 1,
          k: [{
            t: 0,
            s: [100, 100],
            e: [100, 100],
            i: {
              x: [0.515, 0.515],
              y: [0.955, 0.955],
            },
            o: {
              x: [0.455, 0.455],
              y: [0.03, 0.03],
            },
          }, {
            t: 10,
            s: [100, 100],
            e: [171.70000000000002, 171.70000000000002],
            i: {
              x: [0.305, 0.305],
              y: [1.01, 0.99],
            },
            o: {
              x: [0.015, 0],
              y: [1, 1],
            },
          }, {
            t: 24,
          }],
        },
      },
      shapes: [{
        ty: 'gr',
        nm: 'ring-01 shape group',
        it: [{
          ty: 'el',
          p: {
            a: 0,
            k: [31, 30],
          },
          s: {
            a: 0,
            k: [51, 51],
          },
        }, {
          ty: 'st',
          o: {
            a: 0,
            k: 100,
          },
          w: {
            a: 1,
            k: [{
              t: 0,
              s: [4],
              e: [2],
              i: {
                x: [0.515],
                y: [0.885],
              },
              o: {
                x: [0.23],
                y: [1.62],
              },
            }, {
              t: 16,
            }],
          },
          c: {
            a: 0,
            k: [0.996078431372549, 0.8431372549019608, 0.23137254901960785, 1],
          },
          lc: 3,
          lj: 1,
          ml: 1,
        }, {
          ty: 'fl',
          o: {
            a: 0,
            k: 0,
          },
          c: {
            a: 0,
            k: [0, 0, 0, 0],
          },
          r: 1,
        }, {
          ty: 'tr',
          o: {
            a: 0,
            k: 100,
          },
          a: {
            a: 0,
            k: [0, 0],
          },
          s: {
            a: 0,
            k: [100, 100],
          },
          p: {
            a: 0,
            k: [-5, -4],
          },
          r: {
            a: 0,
            k: 0,
          },
        }],
      }],
      op: 42,
    }],
  }],
  layers: [{
    ty: 0,
    nm: 'instance:precomp_1',
    refId: 'precomp_1',
    ind: 1,
    ip: 0,
    st: 0,
    hix: 1,
    ks: {
      o: {
        a: 1,
        k: [{
          t: 0,
          s: [0],
          e: [100],
          i: {
            x: [0.65],
            y: [1.01],
          },
          o: {
            x: [0.23],
            y: [1.62],
          },
        }, {
          t: 24,
        }],
      },
      or: {
        a: 0,
        k: [0, 0, 0],
      },
      a: {
        a: 0,
        k: [67.5, 64, 0],
      },
      p: {
        s: true,
        x: {
          a: 0,
          k: 76.42950000000002,
        },
        y: {
          a: 0,
          k: 75.091,
        },
      },
      rx: {
        a: 0,
        k: 0,
      },
      ry: {
        a: 0,
        k: 0,
      },
      rz: {
        a: 0,
        k: 0,
      },
      s: {
        a: 1,
        k: [{
          t: 0,
          s: [111.9, 111.9],
          e: [100, 100],
          i: {
            x: [0.65, 0.65],
            y: [1.01, 1.01],
          },
          o: {
            x: [0.23, 0.23],
            y: [1.62, 1.62],
          },
        }, {
          t: 24,
        }],
      },
    },
    w: 135,
    h: 128,
    op: 42,
  }],
  op: 42,
  w: 153,
  h: 151,
};
