import axios, { AxiosError } from 'axios';

export const endPoint = {
  // v1: 'http://127.0.0.1:8101/',
  // v2: 'http://127.0.0.1:8101/v2',
  v1: 'https://brain-fa4e2.el.r.appspot.com/',
  v2: 'https://brain-fa4e2.el.r.appspot.com/v2',
};

export const apiAgentV1 = axios.create({
  baseURL: endPoint.v1,
  // withCredentials: true,
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const apiAgentV2 = axios.create({
  baseURL: endPoint.v2,
  // withCredentials: true,
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const showApiError = (error: AxiosError, msg?: string) => {
  console.error('API request error ', error);
  alert(
    msg || '현재 서비스에 문제가 발생했습니다. 잠시 후에 다시 시도해 주세요!',
  );
};
