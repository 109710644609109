import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';
import Lottie from 'react-lottie';
import styled from 'styled-components';

import {
  AwardRow, CircleStatus, CompanyInfo, ContactBar, MainButton, MainMenu, marginSide,
  PayButtonGift, screenContainerBase, spacer1, spacer3, spacer4, spacer8,
  spacer10, StoreReviews,
} from '../components';
import WaitSpin from '../images/wait-spinning';
import {
  giftIdx, IReferer, kakaoReady, LocalStorage, Parents, PaymentMethod, QstnActionType, useQstnDispatch, useQstnState,
} from '../models';
import { formatPhoneNumber, getNumbers } from '../util';

const lottieOptions = {
  animationData: WaitSpin,
  loop: true,
  autoplay: true,
  rendererSettings: {
    className: 'add-class', // svg에 적용
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const ImgGift = styled.img`
  width: 43px;
  height: 40px;
  margin: 20px 10px;
`;

const ImgGiftBig = styled.img`
  width: ${0.3 * 256}px;
  height: ${0.3 * 240}px;
  margin: 20px 0 10px;
`;

let tick = null;

export default function Screen({ match, history }) {
  // const isRec = !!match.params?.user;
  const { kakaoPay, authPhone, qstnAnswer } = useQstnState();
  const dispatch = useQstnDispatch();
  // const [referer, setRefer] = useState<IReferer>({ phone: '0000' });
  const [touched, setTouch] = useState(false);
  const [isPayState, setPayState] = useState(false);
  const [prodIdx, setProdIdx] = useState(-1);

  const gIdx = giftIdx(qstnAnswer);
  const isParent = gIdx > 0;

  const [isGift, setGift] = useState(match.params.option === 'gift' || isParent);
  // const subject = gIdx === 2 ? '아버지' : '어머니';
  // const parenKind = gIdx === 2 ? Parents.Dad : Parents.Mom;
  const initMode = !isPayState && !isGift;
  const phone010 = authPhone.length > 10 ? '0' + authPhone.substring(3) : authPhone;
  const [phone, setPhone] = useState(getNumbers(phone010));
  const [phone2, setPhone2] = useState('');
  const [showTopButton, setButtonShow] = useState(false);

  const validPhone = phone.length === 11;
  const activateButton = !isPayState || (validPhone && phone === phone2 && !touched);
  const fullNumber = phone.length > 0 ? '+82' + phone.substring(1) : phone;

  useEffect(() => {
    console.log('Program Pay : params :: ', match.params);
    const payMethod: PaymentMethod = 'Kakao';
    localStorage.setItem(LocalStorage.PaymentMethod, payMethod);

    ReactGA.event({
      category: 'Screen',
      action: 'Kakao Pay',
    });

    dispatch({
      type: QstnActionType.SetKakao,
      payload: {
        isMobile,
      },
    });
    tick = setInterval(() => {
      setButtonShow(window.pageYOffset > 460);
      // console.log(' scroll ', window.pageYOffset);
    }, 500);
    return () => {
      if (tick) {
        clearInterval(tick);
      }
    };
  }, []);

  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    window.scrollTo(0, 0);
  }, [isPayState]);

  // const remainTime = useMemo(() => {
  //   const min = Math.floor((totalPlayingSec - second) / 60);
  //   const sec = (totalPlayingSec - second) % 60;
  //   return min ? `${min}분 ${sec}초 ` : `${sec}초`;
  // }, [second]);

  const flex1 = (<div className="flex flex-1 m-2" />);

  const renderText = (txt) => (
    <div className="flex flex-row text-base">
      <div className="mr-1">
        <p className="text-gray-800">*</p>
      </div>
      <p className="text-left font-light text-gray-800">{txt}</p>
    </div>
  );

  return (
    <div
      className={`${screenContainerBase} ${marginSide} ${isGift ? 'bg-yellowWhite' : 'bg-grayF8'}`}
      style={{ wordBreak: 'keep-all' }}
    >
      <MainMenu history={history}
        setGift={setGift}
        goBack={isPayState ? () => {
          setPayState(false);
          setProdIdx(-1);
        } : (
          isGift ? () => setGift(false) : null
        )} />
      {spacer1}
      <div className="flex flex-row" >
        {/* {isPayState ? (<button
          className="flex flex-1 text-gray-900 m-2 text-4xl focus:outline-none py-1"
          onClick={(e) => {
            e.preventDefault();
            setPayState(false);
            setProdIdx(-1);
          }}
        >
          <img src={'/images/icons/arrowBack.png'} className="w-6 object-contain" />
        </button>) : flex1
        } */}
        {flex1}
        <CircleStatus idx={isPayState ? 1 : 0} isGift={isGift} />
        {flex1}
      </div>
      {isGift && !isPayState && (
        <div style={{ margin: 'auto', alignItems: 'center' }} className="bg-r ed-300">
          <ImgGiftBig src={'/images/icons/giftBig.png'} />
          <p className="text-gray-800 mb-3 text-center font-semibold text-2xl">{'선물하기'}</p>
        </div>
      )}
      {/* Kakao Pay Button */}
      <div className="flex flex-col self-stretch items-center">

        <PayButtonGift
          title="정기 구독하기"
          selectedIdx={prodIdx}
          isGift={isGift}
          onClick={(idx) => {
            setPayState(true);
            setProdIdx(idx);
            // console.log(' Pay : ', idx);
          }}
        />
      </div>

      {isPayState && (
        <div className="flex flex-col flex-1 py-4">
          <div className="flex my-2">
            <p className="my-2 font-bold text-2xl">사용하실 분의 전화번호를 입력하세요.</p>
          </div>
          {renderText('아래 입력하신 전화번호로 로그인해야 데카르트를 사용할 수 있습니다.')}
          {spacer4}
          <div className="flex flex-col flex-1 justify-center my-4">
            {!touched && (
              <input
                className="self-stretch align-center my-2 p-4 rounded-lg bg-grayE6 text-xl focus:outline-none"
                style={{ flex: 3, caretColor: '#FFD52E' }}
                type="text" name="year" placeholder="전화번호 입력"
                value={formatPhoneNumber(phone)}
                onChange={(e) => {
                  const numOnly = getNumbers(e.target.value);
                  setPhone(numOnly);
                }}
              />
            )}
            <input
              className="flex flex-1 my-2 self-stretch align-center py-5 px-4
              rounded-lg bg-grayE6 text-xl focus:outline-none"
              style={{ flex: 3, caretColor: '#FFD52E' }}
              type="text" name="year" placeholder="전화번호 확인 (다시 입력)"
              value={formatPhoneNumber(phone2)}
              onChange={(e) => {
                const numOnly = getNumbers(e.target.value);
                setPhone2(numOnly);
                dispatch({ type: QstnActionType.SetKakao, payload: { phone } });
              }}
            />
            {spacer10}

            {touched ? (
              <Lottie
                options={{ ...lottieOptions, animationData: WaitSpin }}
                isClickToPauseDisabled={false}
                style={{ width: '150px', height: '150px', marginBottom: '50px' }} // svg의 부모 div에 적용
                eventListeners={[
                  {
                    eventName: 'complete',
                    callback: () => console.log('the animation completed'),
                  },
                ]}
              />
            ) : (
              <div className={'flex flex-col flex-1 self-stretch'} >
                <p className="text-center font-light text-gray-800">* 결제되더라도 7일 이내 언제든 환불 가능합니다.</p>
                <MainButton
                  isActive={activateButton}
                  activeSty={{
                    bgCol: 'bg-grayRedDark',
                    txtCol: 'font-light text-kakao',
                  }}
                  padding="py-6"
                  margin="my-2"
                  onPress={() => {
                    localStorage.setItem(LocalStorage.Gift, isGift ? 'Gift' : 'Self');
                    if (isPayState) {
                      setTouch(true);
                      dispatch({ type: QstnActionType.SetKakao, payload: { phone } });

                      // 24 개월 설날 이벤트
                      const prodIdxTemp = prodIdx === 2 ? 3 : prodIdx;
                      localStorage.setItem('kakaophone', fullNumber);
                      console.log('Kakay Ready', { prodIdxTemp });
                      dispatch({ type: QstnActionType.UploadFirQuest, payload: fullNumber });
                      kakaoReady({
                        kakaoPay,
                        fullNumber,
                        authPhone,
                        prodIdx: prodIdxTemp,
                        parentKind: Parents.No,
                        recommend: undefined,
                      });
                    } else {
                      setPayState(true);
                    }
                  }}
                >
                  <img src={'/images/icons/kakao.png'} className="mx-2 h-7" />
                  <p className="text-2xl">카카오페이 결제</p>
                </MainButton>
              </div>
            )}
          </div>
        </div>
      )}

      {/* FAQ */}
      {!isPayState && (
        <div>
          <button className="flex flex-col py-1 rounded hover:bg-gray-200"
            onClick={(e) => {
              e.preventDefault();
              history.push('/faq');
            }}
          >
            {/* <p className="text-gray-500">환불이나 취소 절차가 쉽나요?</p> */}
            <div className="flex flex-row items-center">
              <p className="text-gray-500 mr-2">• 언제든 취소 가능, 결제 후 7일 내 100% 환불</p>
              <img className="transform rotate-180 object-contain h-4 opacity-50" src={'/images/icons/arrowBack.png'} />
            </div>
          </button>
          {isGift && (
            <p
              style={{ paddingLeft: '11px', textIndent: '-11px' }}
              className="text-gray-500 mr-2">• 선물용 정기구독권입니다. 타인만 사용 가능하며 결제자가 직접 이용할 수 없습니다.
            </p>
          )}
        </div>
      )}

      {initMode && (
        <div>
          {spacer8}

          <p className="text-gray-800 font-semibold text-xl">{'정기구독 선물하기'}</p>
          <div className="flex flex-col self-stretch">
            <button
              style={{ position: 'relative' }}
              className={`flex flex-row justify-center items-center rounded-xl mt-3 py-4 px-1 focus:outline-none 
                shadow-xl border-gray-DF border self-stretch bg-white`}
              onClick={(e) => {
                e.preventDefault();
                setGift(true);
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 200);
              }}
            >
              <ImgGift src={'/images/icons/iconGift.png'} />
              <p className="text-gray-800 font-semibold text-2xl">{'데카르트 선물하기'}</p>
            </button>
          </div>
          {spacer8}

          <p className="py-2 font-semibold text-xl text-gray-800">앱스토어 실사용 후기</p>
          <AwardRow />
          <StoreReviews />
          {showTopButton && (
            <div className="fixed flex max-w-xl mx-auto | bottom-5 left-3 right-3 | bg-yellowBrain rounded-lg" >
              <button
                className="flex flex-1 focus:outline-none"
                onClick={(e) => {
                  e.preventDefault();
                  setButtonShow(false);
                  window.scrollTo(0, 0);
                }}
              >
                <p className="my-5 text-2xl text-gray-800 mx-auto">맨 위로</p>
              </button>
            </div>
          )}
        </div>
      )}
      {spacer3}
      {initMode && (
        <div>
          <ContactBar />
          <CompanyInfo bgColor="bg-white" textColor="text-grayHalf" accountInfo />
          {spacer10}
        </div>
      )}
      {initMode ? spacer10 : spacer3}
    </div>
  );
}
