import { List } from 'immutable';

export enum KakaoApis {
  Prepare = 'https://brain-fa4e2.el.r.appspot.com/v1/kakao/ready/',
  Confirm = 'https://brain-fa4e2.el.r.appspot.com/v1/kakao/confirm/'
  // Prepare = 'https://us-central1-brain-fa4e2.cloudfunctions.net/kakaoready',
  // Confirm = 'https://us-central1-brain-fa4e2.cloudfunctions.net/kakaoconfirm'
}

type FaqID = number;

export enum ProdID {
  OneMonth, ThreeMonth, Year, NewYearEvent, Recommend,
}
export const arrProdName = ['1개월 사용권', '3개월 사용권', '12개월 사용권', '24개월 설날이벤트', '추천인 12개월 상품'];
export const arrPrice = [9900, 23500, 83000, 83000, 73000];
export const arrTerm = [1, 3, 12, 24, 12];

// Local Storage Keys
export const LocalStorage = {
  PaymentMethod: 'PaymentMethod',
  Gift: 'Gift',
  QuestID: 'QuestID',
} as const;
export type PaymentMethod = 'Recommend' | 'Kakao';
export type GiftCase = 'Gift' | 'Self';

export enum QstnActionType {
  Answer = 'Answer',
  UploadFirQuest = 'UploadFirQuest',
  SetUserId = 'SetUserId',
  SetAuthPhone = 'SetAuthPhone',
  SetFaqIdx = 'SetFaqIdx',
  SetKakao = 'SetKakao',
}

export interface QstnAnswerSet {
  qstnIdx: number;
  answer: string[] | string;
}

// Kakao Payment
export interface KakaoPayment {
  isMobile: boolean;
  phone: string;
  price?: number; // no need for clinet
}

export interface QstnAct {
  type: QstnActionType;
  payload: string | QstnAnswerSet | FaqID | KakaoPayment;
  callback?: () => void;
}

export interface QstnType {
  qStr: string;
  isOnlyAnswer?: boolean;
  showIdx?: number[],
  nextPage?: string;
}

export interface MdlQstn {
  page: number;
  nextPage: string | string[];
  question: string;
  giftOptions?: Array<string>,
  subQuestion?: string;
  options: QstnType[];
  imgName?: string;
  imgClassName?: string;
  isConfirmBttn: boolean;
}

// Context
export interface QstnCnx {
  version: string;
  name: string;
  totalPageNum: number;
  qstnAnswer: List<QstnAnswerSet>;
  userid: string;
  authPhone: string,
  arrQstn: MdlQstn[];
  faqIdx: number;
  kakaoPay: KakaoPayment;
}

export interface MdlFaq {
  question: string;
  answer: string;
}

export enum FirCol {
  Quest = 'Quest',
}

export enum Parents {
  Key = 'parents',
  No = 'No',
  Mom = 'Mom',
  Dad = 'Dad',
}
