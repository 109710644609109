import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';

import { bottomSpacer, mainContainer, marginSide, questionTitle, screenContainerBase } from '../components';
import CheckAnim from '../images/check';
import SuccessAnim from '../images/success-check';
import { useInterval } from '../util';

const lottieOptions = {
  animationData: SuccessAnim,
  loop: false,
  autoplay: true,
  rendererSettings: {
    className: 'add-class', // svg에 적용
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const chkSize = '20px';

export default function Screen({ history }) {
  const [stage, setStage] = useState(0);

  useInterval(() => {
    setStage(stage + 1);
  }, 300);

  useEffect(() => {
    if (stage > 15) {
      history.push('/program-intro');
    }
  }, [stage]);

  const msgCompo = (active, title) => {
    return (
      <div className="flex flex-row self-stretch m-1 items-center p-3">
        <div style={{ flex: 8 }} />
        {active ? (
          <Lottie
            options={{ ...lottieOptions, animationData: CheckAnim }}
            isClickToPauseDisabled={false}
            style={{ width: chkSize, height: chkSize }} // svg의 부모 div에 적용
            eventListeners={[
              {
                eventName: 'complete',
                callback: () => console.log('the animation completed'),
              },
            ]}
          />

        ) : (
          <div style={{ width: chkSize, height: chkSize }} />
        )}
        <p className={`mx-5 ${active ? 'text-gray-800' : 'text-gray-400'} w-32`} >
          {title}
        </p>
        <div style={{ flex: 6 }} />
      </div>
    );
  };

  return (
    <div className={`${screenContainerBase} ${marginSide}`} style={{ wordBreak: 'keep-all' }}>
      <div className="m-3" />
      <div className={mainContainer}>
        {stage < 9 && (
          <>
            <p className={`${questionTitle} m-1`}>{'맞춤 두뇌 프로그램'}</p>
            <p className={`${questionTitle}`}>{'생성 중...'}</p>
            <div className="m-3" />
            {msgCompo(stage > 0, '두뇌 능력 향상 ')}
            {msgCompo(stage > 1, '스트레스 감소')}
            {msgCompo(stage > 2, '신체 능력 증진')}
            {msgCompo(stage > 3, '수면 개선')}
            {msgCompo(stage > 4, '건강 정보 제공')}
            {msgCompo(stage > 5, '영어 학습')}
          </>
        )}

        {stage > 9 && (
          <>
            <p className={`${questionTitle} m-1`}>{'맞춤 두뇌 프로그램'}</p>
            <p className={`${questionTitle}`}>{'생성 완료...'}</p>
            <div className="m-3" />

            <Lottie
              options={lottieOptions}
              // isStopped={isStopped}
              // isPaused={isPaused}
              isClickToPauseDisabled={false}
              style={{ flex: 1, marginTop: '150px', width: '250px', height: '250px' }} // svg의 부모 div에 적용
              eventListeners={[
                {
                  eventName: 'complete',
                  callback: () => console.log('the animation completed'),
                },
              ]}
            />
          </>
        )}
      </div>
      <div className="m-3" />
      <div className={bottomSpacer} />

    </div>
  );
}
