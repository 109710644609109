import {
  bottomSpacer, confirmButton, Header, marginSide, questionTitleNarrow, screenContainerBase, spacer15,
} from '../components';
import { MdlQstn, useQstnState } from '../models';
import Question43 from './Question43';
import Question46 from './Question46';

const q4contents = {
  Yes: {
    title: '멋지네요!',
    subTitle: '이미 하고 계시지만, 좀 더 재미있고 건강하게 관리하기 위한 효과적인 방법과 전략을 함께 알아가 볼 거예요.',
    imgName: '/images/qstn4iconYes.png',
  },
  No: {
    title: '괜찮아요.',
    subTitle: '현실적이고 작은 목표를 통해 두뇌 건강을 지킬 수 있게 도와드릴게요.',
    imgName: '/images/qstn4iconNo.png',
  },
  NoInfo: {
    title: '같이 시작해 봐요.',
    subTitle: '어떤 활동들이 효과가 있고 어떻게 두뇌 운동을 할 수 있는지 저희가 알려드릴게요!',
    imgName: '/images/qstn4iconNoInfo.png',
  },
};

export default function Screen({ params, history, answer3, nextPage }) {
  const { arrQstn, totalPageNum } = useQstnState();
  const { page } =
  arrQstn.find((o: MdlQstn) => `${o.page}` === params.page);
  // 4.3, 4.6
  if (page > 4) {
    if (page === 4.3) {
      return <Question43
        nextPage={nextPage} history={history}
      />;
    }
    return <Question46
      nextPage={nextPage} history={history}
    />;
  }
  if (!answer3) {
    history.push('/question/3');
  }
  let content = q4contents.NoInfo;
  if (answer3?.includes('예')) { content = q4contents.Yes; }
  if (answer3?.includes('아니요')) { content = q4contents.No; }

  return (
    <div className={`${screenContainerBase} ${marginSide}`} style={{ wordBreak: 'keep-all' }}>
      <Header progress={page / totalPageNum} history={history} />
      <div className="p-10"></div>
      <div className="flex flex-col items-center justify-center">
        <img src={content.imgName} className="w-40 h-40 m-8" />
      </div>
      <p className={questionTitleNarrow} >{content.title}</p>
      <p className={questionTitleNarrow} >{content.subTitle}</p>

      {spacer15}

      <button
        className={`${confirmButton} bg-yellowBrain`}
        onClick={(e) => {
          e.preventDefault();
          history.push(nextPage);
        }}
      >
      확 인
      </button>

      <div className={bottomSpacer} />
    </div>
  );
}
