import { useEffect, useState } from 'react';

import firebase from '../app/firebase';
import {
  bottomSpacer, debugBG, grayText, Header, MainButton, mainContainer, marginSide,
  screenContainerBase,
} from '../components';
import { QstnActionType, useQstnDispatch, useQstnState } from '../models';
import { formatPhoneNumber, getNumbers } from '../util';

enum AthStt { send = 0, sent, code, success, codeError }

const bttnTitle = ['인증번호 전송', '재전송', '재전송'];
// const mainTitle = ['동의하고 결과보기', '동의하고 결과보기', '인증 완료하고 결과 보기', '인증 완료하고 결과 보기', '인증 완료하고 결과 보기'];
const imgSrc = { 남성: '/images/icons/profileFemale.png', 여성: '/images/icons/profileMale.png' };

export default function Screen({ history }) {
  const dispatch = useQstnDispatch();
  const { qstnAnswer } = useQstnState();
  // const [ts, setTS] = useState(moment().toString());
  const [phone, setPhone] = useState('');
  const [codeIn, setCode] = useState('');
  const [authState, setAuthState] = useState(AthStt.send);
  const [appVerifier, setVerifier] = useState(null);

  const ansGender = qstnAnswer.get(5)?.answer || '남성';

  // State management
  const { send, sent, code, success, codeError } = AthStt;
  const sttChk = (arrState: AthStt[]) => arrState.includes(authState);
  const isValidPhone = phone.length === 11 && authState !== sent;
  const bttnSty = 'px-1 justify-center items-center rounded-lg text-xl focus:outline-none';
  const bttnActiveCol = 'bg-yellowBrain';
  const bttnDarkGray = 'bg-grayA8';
  const bttnInactiveCol = 'bg-gray-100';
  const isPhoneActiveCol = isValidPhone && sttChk([send]);

  const phoneButtonSty = `flex ${bttnSty} py-5
    ${sttChk([sent, code, codeError]) ? bttnInactiveCol : (isPhoneActiveCol ? bttnActiveCol : bttnDarkGray)} 
    ${sttChk([sent, code, codeError]) ? 'text-gray-900' : (isPhoneActiveCol ? 'text-gray-900' : 'text-white')}`;

  const isValidCode = codeIn.length === 6;
  const isValidMain = isValidCode && sttChk([success, code, codeError]);

  const showCodeCompo = sttChk([code, codeError]);
  // const mainButtonSty = `${bttnSty}
  //   ${isValidMain && !sttChk([codeError]) ? bttnActiveCol : bttnDarkGray}
  //   ${isValidMain && !sttChk([codeError]) ? 'text-gray-900' : 'text-white'} `;
  const fullNumber = phone.length > 1 ? '+82' + phone.substring(1) : phone;
  console.log('Cur State : ', qstnAnswer.toArray());

  useEffect(() => {
    window.appVerifier = new firebase.auth.RecaptchaVerifier(
      // 'recaptcha-container',
      'sign-in-button',
      {
        size: 'invisible',
        callback: () => { console.log(' Captcha Callback'); },
      },
    );
    setVerifier(window.appVerifier);
  }, []);

  const sendSmsCode = () => {
    firebase.auth().settings.appVerificationDisabledForTesting = true;
    setAuthState(AthStt.sent);
    setTimeout(() => {
      setAuthState(AthStt.code);
    }, 3000);
    console.log('Send SMS : ', fullNumber);
    firebase.auth().signInWithPhoneNumber(fullNumber, appVerifier)
      .then((confirmationResult) => {
        // dispatch({ type: QstnActionType.UploadFirQuest, payload: fullNumber });
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      }).catch((error) => {
        // Error; SMS not sent
        alert('오류가 발생했습니다. ' + error?.message);
        console.log(' Err', error.message, error);
      });
  };

  const sendVerificationCode = () => {
    window.confirmationResult.confirm(codeIn).then((result) => {
      // User signed in successfully.
      setAuthState(AthStt.success);
      dispatch({ type: QstnActionType.SetUserId, payload: result.user.uid });
      dispatch({ type: QstnActionType.SetAuthPhone, payload: fullNumber });
      // dispatch({
      //   type: QstnActionType.UploadFirQuest,
      //   payload: fullNumber,
      //   callback: () => history.push('/auth-done'),
      // });
      // history.push('/auth-done');
    }).catch((error) => {
      setCode('');
      setAuthState(AthStt.codeError);
      // User couldn't sign in (bad verification code?)
      // ...
      console.log(' Err', error);
    });
  };

  return (
    <div className={`${screenContainerBase} ${marginSide}`} style={{ wordBreak: 'keep-all' }}>
      <Header progress={0} history={history} />
      <div className={mainContainer}>
        <div className="flex flex-row self-stretch items-center">
          <img src={imgSrc[ansGender]} className="w-14 h-14 sm:w-16 sm:h-16 md:w-20 md:h-20 " />
          <p className="flex mt-1 mx-2 md:mx-5 text-gray-800 text-lg sm:text-2xl">
            {'로그인/회원가입 후 나에게 필요한 두뇌 운동을 확인하세요.'}
          </p>

        </div>
        <div className="m-1 sm:m-5" />
        <p className={`${grayText} text-base sm:text-lg`}>
          {'* 번호는 어디에도 공유되지 않고 안전하게 보관됩니다.'}
        </p>
      </div>
      {/*  ***** ***** *****     Phone Input / Button Area     ***** ***** *****  */}
      <div className={`flex flex-row items-stretch ${debugBG}`}>
        <input
          className="w-36 align-center border-gray-700 border py-5 px-2 rounded-lg text-xl focus:outline-none"
          style={{ flex: 3, caretColor: '#FFD52E' }}
          type="text" name="year" placeholder="휴대폰 번호"
          value={formatPhoneNumber(phone)}
          onChange={(e) => {
            const numOnly = getNumbers(e.target.value);
            setPhone(numOnly);
          }}
        />
        <div className="p-1" />
        {authState !== AthStt.success && (
          <button
            id="sign-in-button"
            disabled={!isValidPhone}
            className={phoneButtonSty}
            style={{ flex: 2 }}
            onClick={(e) => {
              e.preventDefault();
              sendSmsCode();
            }}
          >
            {bttnTitle[authState] || bttnTitle[2]}
          </button>
        )}
      </div>

      {/*  ***** ***** *****     Re captcha container    ***** ***** *****  */}
      <div id="recaptcha-container" />

      <div className="m-5" />

      {/*  ***** ***** *****     Code Input     ***** ***** *****  */}
      {showCodeCompo && (
        <>
          <input
            className={`'flex align-center border-gray-700 border py-5 px-4 rounded-lg focus:outline-none'
              text-xl focus:outline-none ${sttChk([codeError]) ? ' border-redError' : ''} `}
            type="text" name="code" placeholder="인증 번호"
            value={codeIn}
            onChange={(e) => {
              setCode(getNumbers(e.target.value));
            }}
          />

          {authState === AthStt.codeError ? (
            <div className="my-3 mx-1 text-left text-lg text-redError">{'전화번호 인증에 실패 했습니다.'}</div>
          ) : (null)}
        </>
      )}

      <div className="p-10" />

      {sttChk([sent, code, codeError]) && (
        <>
          <p className="my-3 text-center text-lg text-gray-400  items-center">
            <a className="underline" href="https://descartesapp.com/pages/%EC%84%9C%EB%B9%84%EC%8A%A4%EC%95%BD%EA%B4%80" target="blank">이용 약관</a>
            {' 및 '}
            <a className="underline" href="https://descartesapp.com/pages/%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4%EC%B2%98%EB%A6%AC%EB%B0%A9%EC%B9%A8" target="blank"> 개인정보 취급방침</a>
          </p>

          <MainButton
            isActive={isValidMain}
            activeSty={{
              bgCol: 'bg-yellowBrain',
              txtCol: 'text-gray-800',
            }}
            padding="py-5"
            margin="my-3"
            onPress={() => {
              switch (authState) {
              case AthStt.success:
                history.push('/auth-done');
                return;
              case AthStt.code:
              case AthStt.codeError:
                sendVerificationCode();
              }
            }}
          >
            <p className="text-2xl">{'인증 완료하고 결과 보기'}</p>
          </MainButton>

          {/* <button
            disabled={!isValidMain}
            className={`${mainButtonSty} my-3 py-5 self-stretch`}
            onClick={(e) => {
              e.preventDefault();
              switch (authState) {
              case AthStt.success:
                history.push('/auth-done');
                return;
              case AthStt.code:
              case AthStt.codeError:
                sendVerificationCode();
              }
            }}
          >
            {'mainTitle[authState]'}
          </button> */}
        </>
      )}
      <div className={bottomSpacer} />

      {/* <button className={'my-3 py-5 self-stretch'}
        onClick={(e) => {
          dispatch({ type: QstnActionType.SetAuthPhone, payload: fullNumber });
          history.push('/auth-done');
        } }
      >
        {'Auth-Done'}
      </button> */}
    </div>
  );
}
