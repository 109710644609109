import styled from 'styled-components';

export const AnimatedImgArrow = styled.img<{
  match: boolean;
}>`
  transition: all .3s ease;
  margin: auto;
  width: 59px;
  height: 35px;
  opacity: ${(p) => p.match ? 1 : 0};
  transform: rotate(${(p) => p.match ? 0 : 180}deg);
`;

export const SpaceFlex1 = styled.div<{
  flex?: number;
}>`
  display: flex;
  flex: ${(p) => p.flex || 1};
`;

export function AboluteGrayBG({ match }) {
  return (
    <div
      className="absolute flex top-0 bottom-0 right-0 left-0 rounded"
      style={{ transition: 'all .3s ease', background: match ? '#FFF6' : '#0000' }}
    >
      <AnimatedImgArrow match={match} src={'/images/program/arrowDown.png'}
        style={{
          transition: 'all .3s ease',
          width: '59px',
          height: '35px',
          opacity: match ? 1 : 0,
          transform: `rotate(${match ? 0 : 180}deg)`,
        }} />
    </div>
  );
}
