import { Drawer } from '@material-ui/core';
import { Fragment, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import { GiftCase, LocalStorage } from '../models';
import { spacer2 } from '.';

// width: 450px;
const Wrap = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: row;
  max-width: 100vw;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;
const Logo = styled.img`
  width: 25px;
  height: 25px;
  margin: 5px;
`;
const Hamberger = styled.img`
  width: 20px;
  height: 20px;
  margin: 5px;
`;
const DropDown = styled.div`
  position: absolute;
  top: 45px;
  right: 0px;
  padding: 10px 0;
  background: #fff;
  border: 1px solid #AAA;
  border-radius: 5px;
  z-index: 100;
`;

export function MainMenu(props) {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const { hide, history, disableBackButton, goBack, setGift } = props;

  const navigate = (rte) => {
    setOpen(false);
    if (rte === 'X') return;

    if (rte.includes('program-pay')) {
      const gc: GiftCase = rte === '/program-pay/gift' ? 'Gift' : 'Self';
      localStorage.setItem(LocalStorage.Gift, gc);
      console.log(' set gift case ', gc);
    } else {
      localStorage.removeItem(LocalStorage.Gift);
    }

    if (setGift && rte.includes('program-pay')) {
      setGift(rte === '/program-pay/gift');
    } else if (rte === '/') {
      history.replace(rte);
    } else if (rte === 'chat') {
      window.location.href = 'http://pf.kakao.com/_cRwfK/chat';
    } else {
      history.push(rte);
    }
  };

  const ButtonEach = ({ title, rte }) => {
    return (
      <button
        className="flex flex-row text-gray-900 mx-4 my-2 text-xl focus:outline-none"
        onClick={(e) => {
          e.preventDefault();
          navigate(rte);
        }}
      >
        <div className="text-left">{title}</div>
        <div className="px-3 py-3" />
      </button>
    );
  };

  // console.log('MainMenu render OPEN ', open, ref.current);

  return (
    <Wrap >
      <button
        className="text-gray-900 my-2 text-4xl focus:outline-none"
        style={{ opacity: disableBackButton ? 0 : 1 }}
        onClick={(e) => {
          e.preventDefault();
          setOpen(false);
          if (goBack) {
            goBack();
          } else if (props.history) {
            props.history.goBack();
          }
        }}
      >
        <img src={'/images/icons/arrowBack.png'} className="w-6 h-6" />
      </button>
      <div className="flex-1" />

      <button
        ref={ref}
        className="text-gray-900 text-4xl focus:outline-none"
        onClick={(e) => {
          e.preventDefault();
          console.log(' open ');
          setOpen(false);
          history.push('/');
        }}
      >
        <Logo className="rounded" src={'/images/logo.png'} />
      </button>

      <div className="flex-1" />
      <button
        ref={ref}
        className="text-gray-900 my-2 text-4xl focus:outline-none"
        style={{ opacity: hide ? 0 : 1 }}
        disabled={hide}
        onClick={(e) => {
          e.preventDefault();
          console.log(' open ');
          setOpen(!open);
        }}
      >
        <Hamberger src={'/images/hamburger.png'} />
      </button>

      {isMobile ? (
        <Fragment>
          <Drawer
            anchor={'right'}
            open={open}
            onClose={() => setOpen(false)}
          >
            {spacer2}
            <ButtonEach title="홈" rte={'/'}/>
            <ButtonEach title="미리보기" rte={'/program'} />
            <ButtonEach title="정기 구독하기" rte={'/program-pay'} />
            <ButtonEach title="정기 구독 선물하기" rte={'/program-pay/gift'}/>
            <ButtonEach title="문의하기" rte={'chat'}/>
          </Drawer>
        </Fragment>
      ) : (
        open && (
          <DropDown>
            <ButtonEach title="홈" rte={'/'}/>
            <ButtonEach title="미리보기" rte={'/program'} />
            <ButtonEach title="정기 구독하기" rte={'/program-pay'} />
            <ButtonEach title="정기 구독 선물하기" rte={'/program-pay/gift'}/>
            <ButtonEach title="문의하기" rte={'chat'}/>
          </DropDown>
        )
      )}

    </Wrap>

  );
}
