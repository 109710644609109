import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';

import firebase from 'firebase/app';

const config = {
  apiKey: 'AIzaSyCAV5slqnLEov-t0oT-XzjSSPwNB3PnDkA',
  authDomain: 'brain-fa4e2.firebaseapp.com',
  databaseURL: 'https://brain-fa4e2.firebaseio.com',
  projectId: 'brain-fa4e2',
  storageBucket: 'brain-fa4e2.appspot.com',
  messagingSenderId: '232312643973',
  appId: '1:232312643973:web:53a0daf2ab990d71f124ec',
  measurementId: 'G-7585828TN9',
};

firebase.initializeApp(config);

export default firebase;

// console.log(' Firebase Loading ', config);

declare global {
  interface Window {
    appVerifier: firebase.auth.ApplicationVerifier;
    confirmationResult: firebase.auth.ConfirmationResult;
  }
}
