import moment from 'moment';

import {
  bottomSpacer, Header, marginSide, QuestionBox, questionTitle, screenContainerBase, spacer6,
} from '../components';
import {
  applyGift, getNextPage, giftIdx, MdlQstn, QstnActionType, QstnType, useQstnDispatch, useQstnState,
} from '../models';
import { getNumbers } from '../util';

export default function Screen({ params, history }) {
  const dispatch = useQstnDispatch();
  const { arrQstn, qstnAnswer, totalPageNum } = useQstnState();
  const curQstnObj: MdlQstn = arrQstn.find((o: MdlQstn) => `${o.page}` === params.page);
  const { page, question, giftOptions } = curQstnObj;
  const gIdx = giftIdx(qstnAnswer);
  const nextPage = getNextPage(qstnAnswer, curQstnObj);
  const arrAnswer = qstnAnswer.get(page)?.answer || [];
  const ansGender = gIdx > 0 ? (
    gIdx === 2 ? '남성' : '여성'
  ) : qstnAnswer.get(5)?.answer || '남성';
  const ansYear = gIdx > 0 ? qstnAnswer.get(6.1)?.answer || '60대' : qstnAnswer.get(6)?.answer || 1960;
  const ageStage = gIdx > 0 ? getNumbers(ansYear) : 10 * Math.floor((moment().year() - parseInt(ansYear)) / 10) || 60;

  console.log(' age stage ', ageStage, ansYear);

  const options = [
    { qStr: `${ageStage}대 ${ansGender} 평균 보다 쉽게` },
    { qStr: `${ageStage}대 ${ansGender} 평균 ` },
    { qStr: `${ageStage}대 ${ansGender} 평균 보다 어렵게` },
  ];
  const answers = ['쉬운', '보통', '도전적'];

  return (
    <div className={`${screenContainerBase} ${marginSide}`} style={{ wordBreak: 'keep-all' }}>
      <Header progress={page / totalPageNum} history={history} />
      <div className="flex justify-center">
        <p className={questionTitle}>{applyGift(qstnAnswer, question, giftOptions)}</p>
      </div>

      {spacer6}

      {options.map((q: QstnType, idx) => (
        <QuestionBox
          key={q.qStr}
          content={q.qStr}
          selected={arrAnswer.includes(q.qStr) && q.qStr.includes(arrAnswer)}
          onClick={() => {
            dispatch({
              type: QstnActionType.Answer,
              payload: {
                qstnIdx: page,
                answer: answers[idx],
              },
            });
            setTimeout(() => {
              history.push(nextPage);
            }, 500);
          }}
        />
      ))}
      <div className="m-3" />

      <div className={bottomSpacer} />
    </div>
  );
}
