import { withRouter } from 'react-router-dom';

const inactive = {
  bgCol: 'bg-gray-400',
  txtCol: 'text-gray-600',
};
const active = {
  bgCol: 'bg-yellowBrain',
  txtCol: 'text-gray-800',
};

function Component(props) {
  const { padding = 'py-5', margin = ' ', onPress, title, activeSty, inactiveSty, isActive = true, children } = props;
  const bttnSty = isActive ? (activeSty || active) : (inactiveSty || inactive);
  return (
    <button
      disabled={!isActive}
      className={`flex flex-1 self-stretch items-center justify-center focus:outline-none
        ${bttnSty.bgCol} ${bttnSty.txtCol} ${padding} ${margin} text-center rounded-xl`}
      onClick={(e) => {
        e.preventDefault();
        onPress();
      }}
    >
      {title && <p className="text-2xl focus:outline-none" >{title}</p>}
      {children}
    </button>
  );
}

export default withRouter(Component);
