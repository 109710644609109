// import { useEffect, useState } from 'react';

import { Parents } from '../models';

const titleDefault = ['선택', '결제', '등록'];
const titleParen = ['선택', '결제', '선물 전달'];

export function CircleStatus(props) {
  // const [paren, setParen] = useState<Parents>(Parents.No);
  const { idx = 1, isGift, parent } = props;
  const isActive = (num) => num === idx;
  const title = isGift ? titleParen : titleDefault;
  // const title = isGift ? titleParen : (parent === Parents.No ? titleDefault : titleParen);

  const Circle = (props) => (
    <div
      className={`flex justify-center items-center mx-1 
        ${isActive(props.num - 1) ? 'bg-gray-800' : 'bg-gray-300'}`}
      style={{ width: '30px', height: '30px', borderRadius: '17px' }}
    >
      <p className="flex text-white font-semibold">{`${props.num}`}</p>
    </div>
  );

  const Title = (props) => (
    <div
      className={'flex flex-1 self-stretch justify-items-center'}
      style={{ width: '35px', height: '35px', borderRadius: '17px' }}
    >
      <p className={'mx-auto text-sm ' + (isActive(props.num) ? 'text-gray-900' : 'text-gray-300')}>
        {title[props.num]}
      </p>
    </div>
  );

  const Bar = <div className="flex my-auto mx-2 bg-gray-400" style={{ width: '30px', height: '1px' }} />;
  const Void = <div className="flex my-auto mx-2" style={{ width: '30px' }} />;

  return (
    <div className="flex flex-col mx-auto pt-2 my-1">
      <div className="flex flex-row justify-between">
        <Circle num={1} />
        {Bar}
        <Circle num={2} />
        {Bar}
        <Circle num={3} />
      </div>
      <div className="flex flex-row pt-2 text-center">
        <Title num={0} />
        {Void}
        <Title num={1} />
        {Void}
        <Title num={2} />
      </div>
    </div>
  );
}
