import ReactGA from 'react-ga';

import { KakaoApis } from './typesModel';

export const kakaoReady = ({
  kakaoPay, fullNumber, authPhone, prodIdx, parentKind, recommend,
}) => {
  const body = {
    ...kakaoPay,
    phone: fullNumber,
    authPhone,
    prodIdx,
    parentKind,
    recommend,
  };
  console.log('Kakao Ready : ', body, prodIdx);
  fetch(KakaoApis.Prepare, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body),
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(myJson) {
      ReactGA.event({
        category: 'Pay',
        action: 'Kakao Pay Start button clicked',
        value: prodIdx,
      });

      const resp = myJson;
      if (resp?.redirectUrl) {
        window.location.href = resp?.redirectUrl;
      }
    });
};
