import Header from './Header';
import QuestionBox from './QuestionBox';

const isDebug = false;

export const screenContainerBase = 'flex flex-col max-w-xl py-1 mx-auto overflow-x-auto justify-center';
export const screenContainer = `${screenContainerBase} h-full`;

const bottomSpacer = 'flex flex-1 full-width ';

export const mainContainer = 'm-3 flex flex-col items-center justify-center';
export const marginSide = 'px-3 md:px-6';

// Texts
export const questionTitle = 'text-gray-800 text-2xl leading-9';
export const questionTitleNarrow = `${questionTitle} mx-7`;
export const grayText = 'leading-7 text-grayHalf text-left';

export const bottomFixed = 'fixed zIndex-10 bottom-5 left-3 right-3 flex max-w-lg mx-auto';

// Debug
export const debugBG = isDebug ? ' bg-indigo-200 ' : ' ';

export const confirmButton =
'fixed bottom-8 self-center p-3 px-12 rounded-xl text-gray-800 text-2xl focus:outline-none';

// export const confirmButton = 'self-center bg-yellowBrain m-8 p-3 px-8
// rounded  text-gray-800 text-2xl focus:outline-none';

export { bottomSpacer, Header, QuestionBox };
export { default as AwardRow } from './AwardRow';
export * from './basicStyle';
export * from './CircleStatus';
export { default as CompanyInfo } from './CompanyInfo';
export { default as ContactBar } from './ContactBar';
export { default as MainButton } from './MainButton';
export * from './MainMenu';
export { default as PayButton } from './PayButton';
export { default as PayButtonGift } from './PayButtonGift';
export { default as PayButtonRecommend } from './PayButtonRecommend';
export * from './SharedCompos';
export { default as StoreReviews } from './StoreReviews';

// Programs
export * from './ProgramA';
export * from './ProgramB';
