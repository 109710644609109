import moment from 'moment';
import { useState } from 'react';

import {
  bottomFixed, MainMenu, marginSide, ProgramA, ProgramB,
  screenContainerBase, spacer1, spacer3, spacer4, spacer10,
} from '../components';
import { giftIdx, pickFromOpt, useQstnState } from '../models';
import { arrWeekDays, getDayOfWeek, getNumbers } from '../util';

const container = 'flex flex-col pt-6';
const mainTitle = 'my-3 font-bold text-lg text-gray-900';
const title = 'text-center font-bold text-2xl text-gray-800';
const subTitle = 'm-1 text-center text-lg text-gray-800';

const arrE = {
  brain: ['3명중 1명 뇌질환 발병', '65세이상 성인 3명중 1명 뇌질환 발병'],
  pie: ['여성의  뇌질환 1.5배 더 심각', '남자 38.5%   여자 61.5%'],
  money: ['치매 간병 비용 3,200만원', '치매환자 가족 비용부담 연평균 3,200만원'],
  eye: ['가족 고통 1위', '보건복지부 설문 결과 응답자 54.8%가 치매를 가장 두려운 질병으로 선택. 암 14.8%선택'],
};
const imgSrc = { 남성: '/images/icons/profileFemale.png', 여성: '/images/icons/profileMale.png' };

const todayStr = moment().format('YYYY년 M월 DD일');

export default function Screen({ history }) {
  const { qstnAnswer } = useQstnState();
  const [card, setCard] = useState('a11');
  const gIdx = giftIdx(qstnAnswer);
  const subj = pickFromOpt(qstnAnswer, ['회원님', '어머니', '아버지']);

  const answered = !!qstnAnswer.get(20);

  const ansGender = gIdx > 0 ? (
    gIdx === 2 ? '남성' : '여성'
  ) : qstnAnswer.get(5)?.answer || '남성';
  const ansDifficulty = qstnAnswer.get(20)?.answer || '보통';
  const ansYear = gIdx > 0 ? qstnAnswer.get(6.1)?.answer || '60대' : qstnAnswer.get(6)?.answer || 1960;
  const ageStage = gIdx > 0 ? getNumbers(ansYear) : 10 * Math.floor((moment().year() - parseInt(ansYear)) / 10) || 60;

  const subject = ['회원님의', '어머니를 위한', '아버지를 위한'][gIdx];

  const arrTop = [
    { title: '성별', text: ansGender },
    { title: '나이', text: `${ageStage}대` },
    { title: '난이도', text: ansDifficulty },
  ];
  const arrTopText = [
    '65세 이후 30%가 뇌질환을 겪습니다.',
    '뇌는 30대 이후 노화가 시작됩니다.',
    '뇌질환은 일단 시작되면 다시 회복이 어렵습니다.',
    '건강한 뇌를 유지하는 비결은 예방과 관리를 빨리 시작하는것 입니다.',
  ];

  return (
    <div className={`${screenContainerBase} ${marginSide}`} style={{ wordBreak: 'keep-all' }}>
      <MainMenu history={history} disableBackButton />
      <div className="m-3" />
      <div className="flex flex-row">
        <img src={imgSrc[ansGender]} className="w-14 h-14" />
        <p className="mx-4 my-1 self-end text-lg font-bold">{`${subject} 멤버십 프로그램이`}<br/>생성되었습니다.</p>

      </div>
      {answered && (
        <div className="flex flex-row my-2 px-6 py-3 bg-yellowBrain">
          {arrTop.map((oj, idx) => (
            <div key={oj.title}
              className={`flex flex-col flex-1 text-center border-gray-600 ${idx < 2 ? 'border-r' : ''}`}>
              <p className="my-1 text-base">{oj.title}</p>
              <p className="text-xl font-bold">{oj.text}</p>
            </div>
          ))}
        </div>
      )}
      {/* <p className="text-center text-base text-gray-400">ㆍ 난이도는 언제든지 바꿀 수 있습니다.</p> */}
      {/*  *****      Title    ***** ****  */}
      <div className="m-1" />
      <p className={mainTitle}>두뇌건강 관리 고민해볼 시기입니다.</p>
      <div className="border-black border-b"></div>

      <div className="m-1" />
      {arrTopText.map((txt) => (
        <div key={txt} className="flex flex-row">
          <div className="items-center mx-1">
            <p className="my-1 text-base text-gray-500">•</p>
          </div>
          <p className="my-1 text-base text-gray-500">{txt}</p>
        </div>
      ))}

      {/*  *****      Title    ***** ****  */}
      <div className="m-1" />
      <p className={mainTitle}>{`${subj}의 뇌는 상위 몇%일까요?`}</p>
      <div className="border-black border-b"></div>
      <div className="flex flex-row">
        <div className="items-center mx-1">
          <p className="my-1 text-base text-gray-500">•</p>
        </div>
        <p className="my-1 text-base text-gray-500">{'사고력, 기억력, 집중력, 계산력, 언어력을 진단하고 관리해 드립니다.'}</p>
      </div>
      {spacer1}
      {/*  ***** ***** *****     Part A    ***** ***** *****  */}
      <ProgramA card={card} setCard={setCard} />

      {/*  ***** ***** *****     Part B    ***** ***** *****  */}
      <ProgramB card={card} setCard={setCard} />
      <div className={container}>
      </div>

      {/*  ***** ***** *****     Part C    ***** ***** *****  */}
      <div className={`${container} bg-gray-50`}>
        <p className={title} >인지능력 분석 프로그램</p>
        {spacer3}
        <img className="flex self-center mb-3 object-cover" src={'/images/program/introCard.gif'} />
        {/* {R.range(2, 6).map((n) => (
            <img key={n} className="flex self-center mb-3 object-cover" src={`/images/program/introCa${n}.png`} />
          ))} */}
        {spacer3}
        <p className={title} >매일 매일 맞춤 두뇌 관리</p>
        {spacer3}
        <div className="flex flex-col mt-6 mx-1 bg-white rounded-xl p-3" >
          <p className="text-center text-2xl text-gray-800" >오늘 목표</p>
          <p className="m-1 text-center text-xl text-gray-800 font-bold" >{todayStr}</p>
          <div className="flex flex-row my-5 px-5 items-center justify-between text-xl">
            {arrWeekDays.map((dstr) => (
              dstr === getDayOfWeek() ? (
                <div key={dstr} className="py-2 border-b-2 border-yellowBrain">
                  <div key={dstr} className="px-3 py-1 bg-black text-white rounded-xl">오늘</div>
                </div>
              ) : (
                <div key={dstr} className="p-2 text-gray-400">{dstr}</div>
              )
            ))}
          </div>
          <img className="object-contain h-54" src={'/images/program/introCb1.png'} />
          <p className={`${subTitle} mt-6`}>
            {'매일 매일 새로운 맞춤 콘텐츠를 드립니다.'}<br/>
            {'나의 맞춤 콘텐츠를 확인해보세요.'}
          </p>
        </div>
      </div>

      {/*  ***** ***** *****     Part D    ***** ***** *****  */}
      <div className={container}>
        {/* <p className={subTitle} >고객 후기</p> */}
        <p className={title} >유익하고, 쉽고, 재미있다!</p>
        {/* <p className="my-3 text-center text-base" > 이미 많은 고객님이 데카르트를 사용하고 계십니다.</p> */}
        <img className="my-3" src={'/images/program/introD1.png'} />
        {spacer4}
      </div>

      {/*  ***** ***** *****     Part E    ***** ***** *****  */}
      <div className={`${container} bg-gray-50`}>
        {/* <p className={subTitle} >점점 다가오는 치매의 공포</p> */}
        <p className={title} >환자, 가족 고통이</p>
        <p className={title} >가장 큰 질병 <span className="text-redError">1위 치매</span></p>
        {/* <p className="my-3 text-center text-base" > 이미 많은 고객님이 데카르트를 사용하고 계십니다.</p> */}

        {['brain', 'pie', 'money', 'eye'].map((nm) => (
          <div key={nm} className="flex flex-col self-stretch" >
            <img className="flex self-center mx-3 my-6 w-20" src={`/images/program/introE${nm}.png`} />
            <p className="my-2 text-center text-2xl" >{arrE[nm][0]}</p>
            <p className="my-1 text-center text-xl text-gray-400" >{arrE[nm][1]}</p>
          </div>
        ))}
        {/* <p className="my-3 text-center text-base" >{arrE.brain[0]}</p> */}
      </div>
      {spacer10}
      {spacer3}
      <div className={bottomFixed} >
        <button
          className={`flex flex-1 self-stretch items-center justify-center focus:outline-none
        bg-grayRedDark text-yellowBrain py-5 text-center rounded-xl`}
          onClick={(e) => {
            e.preventDefault();
            // if (gIdx > 0) { history.push('/parents/pay'); } else { }
            history.push('/program-pay');
          }}
        >
          <p className="text-2xl focus:outline-none" >
            {gIdx > 0 ? '데카르트 선물하기' : '데카르트 시작하기'}
          </p>
        </button>
      </div>

    </div>

  );
}
