import {
  bottomSpacer, confirmButton, Header, marginSide, questionTitleNarrow, screenContainerBase, spacer4, spacer15,
} from '../components';
import { useQstnState } from '../models';

export default function Screen({ history, nextPage }) {
  const { totalPageNum } = useQstnState();

  return (
    <div className={`${screenContainerBase} ${marginSide}`} style={{ wordBreak: 'keep-all' }}>
      <Header progress={4.3 / totalPageNum} history={history} />
      <img src={'/images/snu.png'} style={{ width: '156px', height: '48px' }} />
      <div className="flex flex-col items-center justify-center">
        <img src={'/images/qstn4pieChart.png'} className="w-72 h-72 m-8" />
      </div>
      <p className={questionTitleNarrow} >{'알고 계셨나요?'}</p>
      <p className={questionTitleNarrow} >{'한국인은 65세 이후 약 30%가 뇌 질환을 겪게 됩니다.'}</p>

      {spacer4}

      <p className="mx-7 text-gray-600 text-xl">서울대학교 치매 역학 조사 보고서</p>
      {spacer15}
      <button
        className={`${confirmButton} bg-yellowBrain`}
        onClick={(e) => {
          e.preventDefault();
          history.push(nextPage);
        }}
      >
      확 인
      </button>

      <div className={bottomSpacer} />
    </div>
  );
}
