// import { withRouter } from 'react-router-dom';

import { bottomSpacer, Header, questionTitle, screenContainer } from '../components';
import { useQstnState } from '../models';

export default function Screen({ params, history }) {
  const { arrQstn, totalPageNum } = useQstnState();
  console.log(' Q3 answer : ', params, arrQstn, totalPageNum);
  const boxRowFlex1 = 'flex flex-1 bg-blue-50 p-4, m-1';

  return (
    <div className={screenContainer}>
      <Header progress={3 / 28} history={history} />
      <div className="flex flex-col justify-center">
        <p className={`${questionTitle} text-center mx-5`}>{'Play ground'}</p>
        <p className="m-5" />
      </div>
      <div className="flex flex-row flex-1 bg-blue-400 p-4">
        <div
          // className={`${boxRowFlex1} `}
          style={{ width: '250px' }} >Chapter 1</div>
        <div className={boxRowFlex1}>Chapter 1</div>

      </div>
      <button
        className={
          `self-center 
            m-8 p-3 px-8 rounded  text-gray-800 text-2xl focus:outline-none`
        }

        onClick={(e) => {
          e.preventDefault();
        }}
      >
      확 인
      </button>

      <div className={bottomSpacer} />
    </div>
  );
}
