// import { withRouter } from 'react-router-dom';

import { useState } from 'react';

import { bottomSpacer, confirmButton, Header, marginSide, questionTitle, screenContainerBase } from '../components';
import { MdlQstn, QstnActionType, useQstnDispatch, useQstnState } from '../models';

export default function Screen({ params, history }) {
  const { arrQstn, totalPageNum } = useQstnState();
  const dispatch = useQstnDispatch();
  const { page, nextPage } =
    arrQstn.find((o: MdlQstn) => `${o.page}` === params.page);
  const [year, setYear] = useState('');

  const yearInt = parseInt(year);
  const isValid = yearInt < 2010 && yearInt > 1910;

  // console.log(' Q3 answer : ', qstnAnswer.Q3, content);

  return (
    <div className={`${screenContainerBase} ${marginSide}`} style={{ wordBreak: 'keep-all' }}>
      <Header progress={page / totalPageNum} history={history} />
      <div className="flex flex-col justify-center">
        <p className={`${questionTitle} text-center mx-5`}>{'몇 년생 이신가요?'}</p>
        <p className="m-5" />
        <p className={'text-center mx-5 text-gray-400 text-xl'}>{'출생년도 4자리를 입력해주세요'}</p>
      </div>
      <div className="flex flex-row full-width bg-gray-100 my-4 mx-6 p-6 rounded-lg">
        <input
          className="w-1/2 mx-3 text-right bg-transparent text-4xl placeholder-gray-300 focus:outline-none"
          type="text" name="year" placeholder="1960"
          value={year}
          onChange={(e) => {
            const numOnly = e.target.value.replace(/[^0-9]/g, '');
            setYear(numOnly);
          }}
        />
        <p className="text-2xl py-2" >년 생</p>
      </div>
      <button
        className={`${confirmButton} ${isValid ? 'bg-yellowBrain' : 'bg-gray-200'}`}
        disabled={!isValid}
        onClick={(e) => {
          e.preventDefault();
          dispatch({
            type: QstnActionType.Answer,
            payload: {
              qstnIdx: page,
              answer: year,
            },
          });
          history.push(nextPage);
        }}
      >
      확 인
      </button>

      <div className={bottomSpacer} />
    </div>
  );
}
