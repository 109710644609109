export default {
  v: '5.1.8',
  fr: 29.9700012207031,
  ip: 0,
  op: 60.0000024438501,
  w: 100,
  h: 100,
  nm: 'Spinner',
  ddd: 0,
  assets: [],
  layers: [{
    ddd: 0,
    ind: 1,
    ty: 4,
    nm: 'spinner Outlines',
    sr: 1,
    ks: {
      o: {
        a: 0,
        k: 100,
        ix: 11,
      },
      r: {
        a: 0,
        k: 0,
        ix: 10,
      },
      p: {
        a: 0,
        k: [50, 50, 0],
        ix: 2,
      },
      a: {
        a: 0,
        k: [160, 284, 0],
        ix: 1,
      },
      s: {
        a: 0,
        k: [100, 100, 100],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [
              [0, 19.812],
              [19.882, 0],
              [0, -19.882],
              [-19.882, 0],
            ],
            o: [
              [0, -19.882],
              [-19.882, 0],
              [0, 19.882],
              [19.882, 0],
            ],
            v: [
              [36, 0],
              [0, -36],
              [-36, 0],
              [0, 36],
            ],
            c: true,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'tm',
        s: {
          a: 1,
          k: [{
            i: {
              x: [0.667],
              y: [1],
            },
            o: {
              x: [0.167],
              y: [0.167],
            },
            n: ['0p667_1_0p167_0p167'],
            t: 0,
            s: [100],
            e: [0],
          }, {
            i: {
              x: [0.833],
              y: [1],
            },
            o: {
              x: [0.167],
              y: [0],
            },
            n: ['0p833_1_0p167_0'],
            t: 29,
            s: [0],
            e: [0],
          }, {
            t: 51.0000020772726,
          }],
          ix: 1,
        },
        e: {
          a: 1,
          k: [{
            i: {
              x: [0.833],
              y: [0.833],
            },
            o: {
              x: [0.333],
              y: [0],
            },
            n: ['0p833_0p833_0p333_0'],
            t: 20,
            s: [100],
            e: [1],
          }, {
            t: 60.0000024438501,
          }],
          ix: 2,
        },
        o: {
          a: 0,
          k: 0,
          ix: 3,
        },
        m: 1,
        ix: 2,
        nm: 'Trim Paths 1',
        mn: 'ADBE Vector Filter - Trim',
        hd: false,
      }, {
        ty: 'st',
        c: {
          a: 0,
          k: [1, 0.8352941176470589, 0.1803921568627451, 1],
          ix: 3,
        },
        o: {
          a: 0,
          k: 100,
          ix: 4,
        },
        w: {
          a: 0,
          k: 10,
          ix: 5,
        },
        lc: 2,
        lj: 1,
        ml: 10,
        nm: 'Stroke 1',
        mn: 'ADBE Vector Graphic - Stroke',
        hd: false,
      }, {
        ty: 'tr',
        p: {
          a: 0,
          k: [160, 284],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100],
          ix: 3,
        },
        r: {
          a: 1,
          k: [{
            i: {
              x: [0.667],
              y: [1],
            },
            o: {
              x: [0.167],
              y: [0.167],
            },
            n: ['0p667_1_0p167_0p167'],
            t: 0,
            s: [-90],
            e: [270],
          }, {
            t: 59.0000024031193,
          }],
          ix: 6,
        },
        o: {
          a: 0,
          k: 100,
          ix: 7,
        },
        sk: {
          a: 0,
          k: 0,
          ix: 4,
        },
        sa: {
          a: 0,
          k: 0,
          ix: 5,
        },
        nm: 'Transform',
      }],
      nm: 'Group 1',
      np: 3,
      cix: 2,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 0,
    op: 60.0000024438501,
    st: 0,
    bm: 0,
  }],
  markers: [],
};
