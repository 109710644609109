import {
  bottomSpacer, confirmButton, Header, marginSide, questionTitleNarrow, screenContainerBase, spacer15,
} from '../components';
import { useQstnState } from '../models';

export default function Screen({ history, nextPage }) {
  const { totalPageNum } = useQstnState();

  return (
    <div className={`${screenContainerBase} ${marginSide}`} style={{ wordBreak: 'keep-all' }}>
      <Header progress={4.6 / totalPageNum} history={history} />
      <div className="flex flex-col items-center justify-center">
        <img src={'/images/qstn4brainNet.png'} className="w-80 h-72 m-8" />
      </div>
      <p className={questionTitleNarrow} >{'알고 계셨나요?'}</p>
      <p className={questionTitleNarrow} >{'인지력 저하는 일단 시작되면 되돌리기 어렵습니다. 미리 예방하고 관리하는 것이 최선입니다.'}</p>
      {spacer15}
      <button
        className={`${confirmButton} bg-yellowBrain`}
        onClick={(e) => {
          e.preventDefault();
          history.push(nextPage);
        }}
      >
      확 인
      </button>

      <div className={bottomSpacer} />
    </div>
  );
}
