import styled from 'styled-components';

const ImgDiscount = styled.img`
  width: 6rem;
  height: 3.4rem;
  margin: -25px auto 0px;
  object-fit: contain;
  z-index: 15;
`;
const ImgStripe = styled.img`
  width: 72px;
  height: 72px;
  z-index: 5;
  position: absolute;
`;
const ImgStripeTL = styled(ImgStripe)`
  top: 0;
  left: 0;
`;
const ImgStripeBR = styled(ImgStripe)`
  bottom: 0;
  right: 0;
`;

const arrProp = [
  ['1개월', '1개월마다 결제 ', '월 9,900원', 0, -0],
  ['3개월', '23,500원을 3개월마다 결제 ', '월 7,830원', 1, 20],
  ['12개월', '83,000원을 12개월마다 결제 ', '월 6,920원', 2, 30],
  // ['24개월', '83,000원을 24개월마다 결제 ', '월 3,460원', 2, 65],
];

export default function Component(props) {
  const { selectedIdx, title, isGift, onClick } = props;
  const selected = selectedIdx >= 0;
  const theArray = selected ? [arrProp[selectedIdx]] : arrProp; // .slice(0, 2);
  const opacity = selected ? 0.5 : 1.0;
  const bttnBgColor = isGift ? (selected ? 'bg-yellowWhiteHalf' : 'bg-grayF8') : 'bg-white';
  const fontSize = '1.3rem';

  return (
    <div className="flex flex-col self-stretch">
      {(!selected && !isGift) && <p className="text-gray-800 font-semibold text-xl">{title}</p>}
      {theArray.map((pr, idx) => (
        <button
          key={pr[0]}
          style={{ position: 'relative' }}
          className={`flex flex-row items-center rounded-xl my-2 py-2 px-1 focus:outline-none 
          ${!selected && 'shadow-xl'} ${bttnBgColor} border-gray-DF border `}
          onClick={(e) => {
            e.preventDefault();
            if (selected) {
              return;
            }
            // setPayState(true);
            onClick(idx);
          }}
        >
          {isGift && <ImgStripeTL src={`/images/icons/stripe${selected ? 'Light' : ''}TL.png`} />}
          {isGift && <ImgStripeBR src={`/images/icons/stripe${selected ? 'Light' : ''}BR.png`} />}
          <div
            className={'flex flex-1 font-semibold text-gray-600 justify-center flex-col self-stretch bg-red- 500'}
            style={{ flex: 5 }}
          >
            {pr[4] > 0 && (
              <ImgDiscount src={`/images/icons/discount${pr[4]}.png`} />
            )}
            <p style={{ fontSize, opacity }}>{pr[0]}</p>
          </div>
          <div className="flex flex-col items-start " style={{ flex: 10, opacity }}>
            <p style={{ fontSize: '17px' }} className="text-gray-400 mt-2">{pr[1]}</p>
            <div style={{ height: '1px' }} />
            <p style={{ fontSize }} className={'text-gray-900 font-semibold my-1'}>{pr[2]}</p>
          </div>
        </button>
      ))}

    </div>
  );
}

/* 설날 이벤트 버튼

const NewYearBox = styled.button` position: relative; `;
  // 512 396
const ImgNewYearGift = styled.img`
  width: 225px; height: 180px; bottom: 30px; right: 25px; z-index: 0; position: absolute;
`;

      {!selected && (
        <NewYearBox
          className={`flex flex-col rounded-xl my-2 focus:outline-none
            shadow-xl bg-yellowBrain border-gray-DF border`}
          onClick={(e) => {
            e.preventDefault();
            onClick(2);
          }}
        >
          {isGift && <ImgStripeTL src={'/images/icons/stripeLightTL.png'} />}
          <div
            style={{ display: 'flex', flexDirection: 'row', background: '#f390', alignSelf: 'stretch' }}
            className="py-2"
          >
            <div
              className={'flex justify-center flex-col self-stretch'}
              style={{ flex: 5 }}
            >
              <ImgDiscount src={'/images/icons/discount65.png'} />
            </div>
            <div className="flex " style={{ flex: 10, opacity }}></div>
          </div>

          <div style={{ position: 'relative', alignSelf: 'stretch', flex: 3, padding: '0 20px', textAlign: 'left' }} >
            <ImgNewYearGift src="/images/newyear/gift.png" />
            {isGift && <ImgStripeBR src={'/images/icons/stripeLightBR.png'} />}
            <p style={{
              fontSize: '1.8rem',
              lineHeight: '2.3rem',
              fontWeight: 600,
            }} className="text-gray-800">
              {'2022'}<br/>{'설날 프로모션'}
            </p>
            <div style={{ padding: '11px', background: '#89a0' }}/>
            <p
              style={{
                fontSize: '1.7rem',
                textDecorationLine: 'line-through',
                lineHeight: '2rem',
                fontWeight: 300,
                position: 'relative',
                zIndex: 100,
              }}
              className="text-gray-500">
              {'12개월 '}<a style={{ zIndex: 100, fontWeight: 400 }}>{' 월 6,920원'}</a>
            </p>
            <p
              style={{
                fontSize: '1.7rem',
                lineHeight: '2rem',
                fontWeight: 300,
                position: 'relative',
                zIndex: 100,
              }}
              className="text-gray-800">
              {'24개월'}<a style={{ fontWeight: 500 }}>{' 월 3,460원'}</a>
            </p>
            <div style={{ padding: '10px', background: '#89a0' }}/>
            <p style={{
              position: 'relative',
              zIndex: 100,
              fontSize: '1.1rem',
              lineHeight: '1.3rem',
              fontWeight: 400,
            }} className="text-gray-600">
              {'83,000원으로'}<br/>{'24개월마다 결제'}
            </p>
            <div style={{ padding: '25px', background: '#89a0' }}></div>
          </div>
          <div
            className="rounded-b-xl "
            style={{ alignSelf: 'stretch', margin: 0, padding: '10px', background: '#eFc51E' }}>
            <p style={{ fontSize: '1.3rem', fontWeight: 400 }} className="text-gray-600">
              {'곧 종료'}
            </p>
          </div>
        </NewYearBox>
      )}
*/
