interface Review {
  author: string;
  rate: number;
  image: string;
  text: string;
}
const imgSrc = (nm) => `/images/profileIcons/${nm}.png`;
const arrItems: Review[] = [
  // ={'/images/icons/arrowBack.png'} />
  {
    author: '이정민',
    rate: 5,
    image: imgSrc('prof1'),
    text: '유익한 프로그램 고마워요. 아들이 설치해줘서 시작했어요. 처음에는 좀 어려웠는데 하다보니까 지금은 할만해요 계속해보고 또 리뷰남길께요 지금까지는 만족해요',
  },
  { author: 'Jung-Ock Ahn', rate: 5, image: imgSrc('prof2'), text: '두뇌개발에 많은 도움을 받아요' },
  {
    author: '김정순',
    rate: 5,
    image: imgSrc('prof3'),
    text: '처음에는 어리버리했는데 거듭할수록 회전이 빠른걸보니 뇌회전이 잘되는것같아 마음이 좋습니다.지인들께 강추하겠습니다.감사합니다',
  },
  {
    author: '김은혜',
    rate: 5,
    image: imgSrc('prof4'),
    text: '부모님들이 여러방면으로 치매예방이 되게끔 프로그램이 알맞게 짜여져 있는것 같아 매우 유익한 앱인것 같습니다. 간호사인 저도 너무 재미있게 사용해보았어요. 널리 홍보해야겠네요 ^^',
  },
  {
    author: '조성준',
    rate: 5,
    image: imgSrc('prof5'),
    text:
    '소소한 재미도 있고 무엇보다 두뇌에 좋으니 매일 하고 있습니다. 진짜 계속 꾸준히 하면 머리가 좋아질거 같은 느낌에요 ㅎㅎ 영어학습도 지금 수준보다 더 난이도가 있는걸 만들어 주시면 좋을거 같아요',
  },
  {
    author: 'Hyena',
    rate: 5,
    image: imgSrc('prof6'),
    text: '두뇌훈련 구성도 다양하고 영어도 공부 할 수 있고. 운동도 할 수 있다면서 엄마가 넘 좋아하세요~~ 자판기 커피가격도 안 되는 금액으로 엄마에게 효도도하고 넘 만족스럽습니다.^^',
  },
  {
    author: '최인영',
    rate: 5,
    image: imgSrc('prof7'),
    text:
    '개인적으로 앞으로 뇌질환이 걱정이 많았는데 매일 머리를 쓸수있는 프로그램이어서 만족합니다. 게임도 재미있그요 생각보다.' +
    ' 그리고 여자선생님이 해주는 요가수업이 마음에드는데 열심히 하다보니 거의 다해갑니다. 계속해서 추가되겠죠? 감사합니다.',
  },
  { author: '김혜자', rate: 5, image: imgSrc('prof8'), text: '우리딸이엄마두뇌건강걱정해서 이렇게보내준거 같아요 재밌고유익하고 너무어렵지않네요 감사합니다 개발자님7^^' },
  { author: '안영희', rate: 5, image: imgSrc('prof9'), text: '섹션이 다행한점, 평가방법의 디테일이 좋았습니다 열심히 해볼생각.' },
  { author: 'Kyung-A Kim', rate: 5, image: imgSrc('prof9'), text: '정말 두뇌 활동에는 좋은것 같아요,이런 프로그램 만들어 주셔서 감사합니다..' },
  {
    author: 'cozmonia', rate: 4, image: imgSrc('prof10'), text: `두뇌게임 뿐 아니라 짧게 스트레칭, 근력운동 
  등을 따라할 수 있습니다. 이완을 위한 호흡 고르기도 있어 명상 시간을 갖을 수 있습니다. 계산력, 기억력, 언어력 등 다양한 분야의 
  두뇌게임도 지루하지 않아서 짬짬이 찾아서 하게 되네요. 아이들과도 즐길 수 있는 건강한 게임입니다.
  아쉬운 점은 구독형 서비스라 비용부담이 지속적으로 있다는 부분입니다.`,
  },
  // { author: '김사라', rate: 5, image: imgSrc('prof11'), text: '하고싶어하던프로그램이 다 있어요 사용료도괜찮아서 무료체험끝나고 계속하려고합니다' },
  { author: '박젬마', rate: 5, image: imgSrc('prof12'), text: '매일 여러분야의 틀린문제를 풀수 있어 좋아요.' },
  { author: '천사들과함께', rate: 5, image: imgSrc('prof1'), text: '생각보다 재밌어요' },
  {
    author: '원숙', rate: 5, image: imgSrc('prof2'), text: `
  생각을 할 수 있게 되어있어 평상시 안쓰던 뇌를 쓸수 있어서 좋았고 영어 공부도 할수 있어서 좋았습니다 지나간 레벨을 반복할 수 있는데 
  제가 못찾는건지 없는것인지는 모르겠으나 지나간 레벨도 다시 반복할수 있었으면 좋겠습니다
  `,
  },
];

export default function Screen() {
  return (
    <div className="" style={{ overflow: 'visible' }}>
      {arrItems.map((itm, idx) => {
        return (
          <div key={itm.author} className={'flex flex-col self-stretch bg-grayF1 p-4 rounded-xl ' +
          (idx > 0 ? 'mt-4' : '')} >
            <div className="flex flex-row p-3">
              <img className="w-16 h-16" src={itm.image} />
              <div className="text-2xl">
                <p className={'mx-4 my-1 text-lg text-left text-gray-600'} >{itm.author}님</p>
                <img className="mx-4 my-1 h-5" src={itm.rate < 5 ? '/images/star4.png' : '/images/star5.png' } />
              </div>
            </div>
            <p className={'mx-4 text-lg text-left text-gray-800'} >{itm.text}</p>
          </div>
        );
      })}

      <div className={'flex flex-col self-stretch bg-grayF1 p-4 rounded-xl my-4'} >
        <p className={'mx-4 text-2xl text-center text-gray-800'} >• • •</p>
      </div>
    </div>

  );
}
