import { MdlQstn } from './typesModel';

export const subjectKey = 'SUBJ';

export const arrQstn: MdlQstn[] = [
  {
    page: 0.5,
    nextPage: '/question/0.7',
    question: '',
    options: [],
    imgName: 'johnsHopkinsContent',
    isConfirmBttn: true,
  },
  {
    page: 0.7,
    nextPage: '/question/1',
    question: '누구의 두뇌 건강이 더 걱정되시나요?',
    options: [
      { qStr: '나' },
      { qStr: '우리 엄마' },
      { qStr: '우리 아빠' },
    ],
    isConfirmBttn: false,
  },
  {
    page: 1,
    nextPage: ['/question/2', '/question/2.5', '/question/2.5'],
    question: `어떨 때 가장 ${subjectKey} 두뇌건강이 걱정되시나요?`,
    giftOptions: ['', '어머니의', '아버지의'],
    options: [
      { qStr: '깜빡 깜빡 할 때' },
      { qStr: '지인이 치매에 걸렸다는 소식을 듣고' },
      { qStr: '치매 관련 뉴스나 영상을 볼 때' },
      { qStr: '가족이 치매 증상을 보일 때' },
      { qStr: '기타' },
      { qStr: '걱정 되지 않음' },
    ],
    isConfirmBttn: false,
  },
  {
    page: 2,
    nextPage: '/question/3',
    question: '두뇌 건강에 대해서 누구와 이야기해 본 적 있나요? (모두 선택)',
    options: [
      { qStr: '가족' },
      { qStr: '지인' },
      { qStr: '의사' },
      { qStr: '얘기해본 적 없음', isOnlyAnswer: true },
    ],
    isConfirmBttn: true,
  },
  {
    page: 2.5,
    nextPage: '/question/3',
    question: `두뇌 건강에 대해서 ${subjectKey}와 이야기해 본 적 있나요?`,
    giftOptions: ['', '어머니', '아버지'],
    options: [
      { qStr: '예 해봤습니다.' },
      { qStr: '얘기해본 적 없음' },
    ],
    isConfirmBttn: false,
  },
  {
    page: 3,
    nextPage: '/question/4',
    question: `${subjectKey}두뇌 건강을 위해 일상생활에서 필요한 노력을 하고 있나요?`,
    giftOptions: ['', '어머니는 ', '아버지는 '],
    options: [
      { qStr: '예 하고 있습니다.' },
      { qStr: '아니요 하고 있지 않습니다.' },
      { qStr: '두뇌 건강에 대해 잘 알지 못합니다.' },
    ],
    isConfirmBttn: false,
  },
  { // 대답에 따른 3가지 다른 화면
    page: 4,
    nextPage: ['/question/5', '/question/4.3', '/question/4.3'],
    question: '',
    options: [],
    isConfirmBttn: true,
  },
  { // Gift
    page: 4.3,
    nextPage: '/question/4.6',
    question: '',
    options: [],
    isConfirmBttn: true,
  },
  { // Gift
    page: 4.6,
    nextPage: '/question/6.1',
    question: '',
    options: [],
    isConfirmBttn: true,
  },
  {
    page: 5,
    nextPage: '/question/6',
    question: '성별을 알려주세요.',
    options: [
      { qStr: '여성' },
      { qStr: '남성' },
    ],
    isConfirmBttn: false,
  },
  {
    page: 6,
    nextPage: '/question/7',
    question: '몇 년생 이신가요?',
    options: [],
    isConfirmBttn: true,
  },

  {
    page: 6.1,
    question: `${subjectKey}의 연령대는 어떻게 되시나요?`,
    giftOptions: ['', '어머니', '아버지'],
    nextPage: '/question/7',
    options: [
      { qStr: '40대' },
      { qStr: '50대' },
      { qStr: '60대' },
      { qStr: '70대 이상' },
    ],
    isConfirmBttn: false,
  },

  {
    page: 7,
    nextPage: '/question/8',
    question: '알려주신 소중한 정보를 통해 회원님에게 딱 맞는 두뇌 운동 프로그램을 만들어 드립니다.',
    options: [],
    isConfirmBttn: true,
  },
  {
    page: 8,
    nextPage: '/question/9',
    question: `${subjectKey}최근에 우울함을 얼마나 느꼈나요?`,
    giftOptions: ['', '어머니가 ', '아버지가 '],
    options: [
      { qStr: '거의 없음' },
      { qStr: '가끔 우울함' },
      { qStr: '매일 우울함' },
      { qStr: '잘 몰라요', showIdx: [1, 2] },
    ],
    isConfirmBttn: false,
  },
  {
    page: 9,
    nextPage: '/question/10',
    question: `${subjectKey}평소에 분노가 많다고 생각하나요?`,
    giftOptions: ['', '어머니가 ', '아버지가 '],
    options: [
      { qStr: '거의 없음' },
      { qStr: '가끔 분노함' },
      { qStr: '매일 분노함' },
      { qStr: '잘 몰라요', showIdx: [1, 2] },
    ],
    isConfirmBttn: false,
  },
  {
    page: 10,
    nextPage: '/question/11',
    question: `${subjectKey}평소에 수면이 부족하거나 잠이 들지 못해 불편을 느낀 날이 있나요?`,
    giftOptions: ['', '어머니가 ', '아버지가 '],
    options: [
      { qStr: '거의 없음' },
      { qStr: '가끔 수면이 부족함' },
      { qStr: '매일 수면이 부족함' },
      { qStr: '잘 몰라요', showIdx: [1, 2] },
    ],
    isConfirmBttn: false,
  },
  {
    page: 11,
    nextPage: '/question/12',
    question: `${subjectKey}평소에 새로운 사람을 만날 기회가 있나요?`,
    giftOptions: ['', '어머니가 ', '아버지가 '],
    options: [
      { qStr: '거의 없음' },
      { qStr: '가끔 새로운 사람을 만남' },
      { qStr: '매일 새로운 사람을 만남' },
      { qStr: '잘 몰라요', showIdx: [1, 2] },
    ],
    isConfirmBttn: false,
  },
  {
    page: 12,
    nextPage: '/question/13',
    question: '하버드 메디컬스쿨이 밝힌 “치매예방 6가지 비밀”',
    options: [],
    imgName: 'harvardp126secret',
    isConfirmBttn: true,
  },
  {
    page: 13,
    nextPage: '/question/14',
    question: `${subjectKey}현재 새롭게 배우고 있는 것이 있나요? 있다면 모두 선택해주세요.`,
    giftOptions: ['', '어머니가 ', '아버지가 '],
    options: [
      { qStr: '취미 (요리, 악기, 그림 등)' },
      { qStr: '공부 (어학, 상식 등)' },
      { qStr: '운동 (요가, 수영 등)' },
      { qStr: '없음', isOnlyAnswer: true },
      { qStr: '잘 몰라요', showIdx: [1, 2], isOnlyAnswer: true },
    ],
    isConfirmBttn: true,
  },

  {
    page: 14,
    nextPage: '/question/15',
    question: `${subjectKey}평소 땀이 날 정도의 운동을 얼마나 하시나요?`,
    giftOptions: ['', '어머니가 ', '아버지가 '],
    options: [
      { qStr: '안함' },
      { qStr: '가끔 운동함' },
      { qStr: '규칙적으로 운동함' },
      { qStr: '매일 운동함' },
      { qStr: '잘 몰라요', showIdx: [1, 2] },
    ],
    isConfirmBttn: false,
  },
  {
    page: 15,
    nextPage: '/question/16',
    question: '하버드 메디컬스쿨 “운동으로 기억력 향상”',
    imgName: 'harvardExercise',
    options: [],
    isConfirmBttn: true,
  },
  {
    page: 16,
    nextPage: '/question/17',
    question: `${subjectKey}해당하는 질환이 있으시면 모두 선택해주세요`,
    giftOptions: ['', '어머니에게 ', '아버지에게 '],
    options: [
      { qStr: '심혈관(고혈압, 협심증 등)' },
      { qStr: '만성대사 (당뇨, 고지혈증 등)' },
      { qStr: '정신과(우울증, 공황장애 등)' },
      { qStr: '없음', isOnlyAnswer: true },
      { qStr: '잘 몰라요', showIdx: [1, 2], isOnlyAnswer: true },
    ],
    isConfirmBttn: true,
  },
  {
    page: 17,
    nextPage: '/question/18',
    question: `${subjectKey}직계가족 중 치매 환자가 있거나 과거에 있었나요?`,
    giftOptions: ['', '어머니의 ', '아버지의 '],
    options: [
      { qStr: '네' },
      { qStr: '아니오' },
      { qStr: '잘 몰라요', showIdx: [1, 2] },
    ],
    isConfirmBttn: false,
  },
  {
    page: 18,
    nextPage: '/question/19',
    question: '공유해주셔서 고맙습니다.',
    subQuestion: '데카르트는 두뇌에 관련된 모든 정보를 통해 건강해지실 수 있도록 돕습니다.',
    imgName: 'icons/signupYellow.png',
    imgClassName: 'w-28 h-28',
    options: [],
    isConfirmBttn: true,
  },
  {
    page: 19,
    nextPage: '/question/20',
    question: `${subjectKey}어떤 성향에 가까운 사람이라고 생각하시나요?`,
    giftOptions: ['', '어머니는 ', '아버지는 '],
    options: [
      { qStr: '이성적인 사람입니다.' },
      { qStr: '감성적인 사람입니다.' },
      { qStr: '잘 모르겠습니다.' },
    ],
    isConfirmBttn: false,
  },
  {
    page: 20,
    // nextPage: '/question/21',
    nextPage: ['/question/22', '/question/21', '/question/21'],
    question: `${subjectKey}두뇌 운동 강도를 선택해 주세요. (난이도는 언제든지 바꿀 수 있습니다.)`,
    giftOptions: ['', '어머니의 ', '아버지의 '],
    options: [
      { qStr: '?0대 ?? 평균 보다 쉽게' },
      { qStr: '50대 여성 평균' },
      { qStr: '50대 여성 평균 보다 어렵게.' },
    ],
    isConfirmBttn: false,
  },
  {
    page: 21,
    nextPage: '/question/22',
    question: `${subjectKey}평소에 사용하는 어플을 모두 선택해 주세요`,
    giftOptions: ['', '어머니가 ', '아버지가 '],
    options: [
      { qStr: '카카오톡' },
      { qStr: '유튜브' },
      { qStr: '쿠팡 등(쇼핑 앱)' },
      { qStr: '애니팡 등(게임 앱)' },
      { qStr: '없음', isOnlyAnswer: true },
      { qStr: '잘 몰라요', isOnlyAnswer: true },
    ],
    isConfirmBttn: true,
  },
  {
    page: 22,
    nextPage: '/auth-done', //  ['/auth', '/auth-done', '/auth-done'],
    question: '데카르트는 어렵지 않아요.',
    subQuestion: '유튜브나 카카오톡만 할 수 있다면 누구나 인지력을 훈련할 수 있도록 쉽게 설계했습니다.',
    imgName: 'icons/youtubeKakao.png',
    imgClassName: 'w-32 h-32',
    options: [],
    isConfirmBttn: true,
  },
];
